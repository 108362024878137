import {AfterViewInit, Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
  selector: 'app-athesis-modal-confirm-remove',
  templateUrl: './athesis-modal-confirm-remove.component.html',
  styleUrls: ['./athesis-modal-confirm-remove.component.scss']
})
export class AthesisModalConfirmRemoveComponent implements AfterViewInit {
  item: any;
  modalConfig: any;
  template: TemplateRef<any>;
  onConfirm: Function;
  onCancel: Function;

  constructor(private modalRef: BsModalRef) {

  }

  ngAfterViewInit() {
  }

  confirm() {
    this.onConfirm();
    this.modalRef.hide();
  }

  cancel() {
    this.onCancel();
    this.modalRef.hide();
  }

}
