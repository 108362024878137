import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserEntity } from '../../../models';
import { BsModalRef } from 'ngx-bootstrap';
import { AthesisUserService } from '../../../services/athesis-user.service';
import * as icons from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-password-form-modal',
    templateUrl: './password-form-modal.component.html'
})
export class PasswordFormModalComponent implements OnInit {
    item: any;
    modalConfig: any;
    onConfirm: Function;
    onCancel: Function;
    form: FormGroup;
    submitted = false;
    isUserAdmin = false;
    currentUser: UserEntity;

    icons = icons;

    constructor(
      private modalRef: BsModalRef,
      private userService: AthesisUserService,
      private fb: FormBuilder) {

        this.currentUser = this.userService.currentUser;
        this.isUserAdmin = !!this.currentUser.roles.find(role => role.id === 1);
    }

    get isCurrentUser() {
      return !!this.currentUser && !!this.item && this.currentUser.id === this.item.id;
    }

    get f() {
       return this.form.controls;
    }

    ngOnInit() {
      this.form = this.buildForm(this.fb);
    }

    buildForm(fb: FormBuilder): FormGroup {
      return fb.group({
        password: fb.control(null, [Validators.required, Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[\S]{8,}/gm)]),
        passwordConfirm: fb.control(null, [Validators.required, this.confirmPasswordValidator.bind(this)]),
      });
    }

    confirm() {
      this.onConfirm(this.mapUser());
      this.modalRef.hide();
    }

    cancel() {
      this.onCancel();
      this.modalRef.hide();
    }

    submit() {
      this.submitted = true;
      if (this.form.valid) {
        this.confirm();
      }
    }

    confirmPasswordValidator(control: FormControl): {[s: string]: boolean} {
      if (!!this.form && (control.value !== this.form.value.password)) {
        return {confirmPasswordIsInvalid: true};
      }
      return null;
    }

    private mapUser() {
      return {
        id: this.item.id,
        name: this.item.name,
        surname: this.item.surname,
        email: this.item.email,
        rolesId: this.item.roles.map(item => item.id),
        password: this.form.value.password
      };
    }
  }
