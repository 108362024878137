export class RegistrySimpleDto {
    id: string;
    name: string;
    specification: string;
    surname: string;

    get fullName() {
        return `${this.name} ${this.surname}`;
    }
}
