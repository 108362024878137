import { createReducer, on } from '@ngrx/store';
import { TopicEntity } from 'src/app/models';
import { users } from '../actions/athesis-user-actions';
import { topics } from '../actions/athesis-topic-actions';

export interface State {
    topics: TopicEntity[];
}

export const initialState: State = {
    topics: null
};

function mergeState(state, newState) {
    return {
        ...state, ...newState
    };
}

export const topicReducer = createReducer(initialState,
    on(topics, (state, {payload}) => mergeState(state, {topics: payload})),
);
