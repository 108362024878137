import { Component, OnInit } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { FaceNotAssociatedResponse } from '../../models/face-not-associated.model';
import { AthesisFaceNameAssociationService } from '../../../services/athesis-face-name-association.service';
import { Page } from '../../../models/pagination/page.model';
import { ModalsService } from '../../../services/modals/modals.service';
import { AthesisModalNfaConfirmComponent } from '../athesis-modal-nfa-confirm/athesis-modal-nfa-confirm.component';
import { CharacterAssociationModalComponent } from '../character-association/character-association-modal/character-association-modal.component';
import { FullWidthImageModalComponent } from '../../../shared/components/full-width-image-modal/full-width-image-modal.component';
import { AbstractFaceAssociationComponent } from '../abstract-face-association/abstract-face-association.component';
import { ImagesService } from '../../../services/images.service';

export class ClusterShortInfo {
  clusterId: number;
  imagesOccurrences: number;
}

@Component({
  selector: 'app-cluster-detail',
  templateUrl: './cluster-detail.component.html',
  styleUrls: ['./cluster-detail.component.scss']
})
export class ClusterDetailComponent extends AbstractFaceAssociationComponent {

  private clusterId;
  cluster: ClusterShortInfo;
  imageList: Page<FaceNotAssociatedResponse>;
  icons = icons;
  currentPage = 0;
  selectAll = false;

  clusterList: Page<FaceNotAssociatedResponse>;

  get anySelected() {
    return this.imageList && this.imageList.content.some(image => image.selected);
  }

  get loading() {
    return !this.imageList || !this.imageList.content.every(image => image.articles != null);
  }

  constructor(
    router: Router,
    route: ActivatedRoute,
    private imagesService: ImagesService,
    private modalService: ModalsService,
    athesisFaceNameAssociationService: AthesisFaceNameAssociationService) {
      super(athesisFaceNameAssociationService, router, route);

      this.loadClusterInfo();
  }

  private loadClusterInfo() {
    const state = this.router.getCurrentNavigation().extras.state;

    if (state.clusterList) {
      this.clusterList = state.clusterList;
    }

    this.route.paramMap.subscribe(response => {
      this.clusterId = response.get('clusterId');

      this.athesisFaceNameAssociationService.getPagedClusterImages(this.clusterId, this.currentPage).subscribe(res => {
        this.imageList = res;
        this.cluster = { clusterId: this.clusterId, imagesOccurrences: res.totalElements };

        this.imageList.content.map(image => {
          this.imagesService.getImageReferences(image.imageId).subscribe(ref => {
            image.articles = ref;
          });
        });
      });
    });
  }

  assignName(image, index) {
    this.modalService.show(CharacterAssociationModalComponent, null, image, 'modal-xl').observable
      .subscribe((character) => {
        this.athesisFaceNameAssociationService.assignCharacter(image.reference, character).subscribe(res => {
          this.imageList.content.splice(index, 1);
          this.cluster.imagesOccurrences -= 1;
        });
      });
  }

  ignoreFace(image, index) {
    const modalConfig = {
      title: 'Ignora volto',
      message: 'Confermare operazione Ignora volto per l\'immagine selezionata?'
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, image, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.ignoreFace(image.reference).subscribe(res => {
          this.imageList.content.splice(index, 1);
          this.cluster.imagesOccurrences -= 1;
        });
      });
  }

  isNotFace(image, index) {
    const modalConfig = {
      title: 'Rimuovi volto',
      message: 'Confermare operazione Rimuovi volto per l\'immagine selezionata?'
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, image, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.isNotFace(image.reference).subscribe(res => {
          this.imageList.content.splice(index, 1);
          this.cluster.imagesOccurrences -= 1;
        });
      });
  }

  ignoreSelectedFaces() {

    if (this.selectAll) {
      return this.ignoreAllFaces();
    }

    const selectedImages = this.imageList.content.filter(image => image.selected).map(image => image.reference);

    const modalConfig = {
      title: 'Ignora volto',
      message: `Confermare operazione Ignora volto per le ${selectedImages.length} immagini selezionate?`
    };

    if (selectedImages.length === 1) {
      modalConfig.message = `Confermare operazione Ignora volto per l\'immagine selezionata?`;
    }

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
    .subscribe(() => {
      this.athesisFaceNameAssociationService.ignoreImageList(selectedImages).subscribe(res => {
        this.imageList.content = this.imageList.content.filter(image => !image.selected);
        this.cluster.imagesOccurrences -= selectedImages.length;
      });
    });
  }

  private ignoreAllFaces() {
    const modalConfig = {
      title: 'Ignora volto',
      message: `Confermare operazione Ignora volto per tutte le ${this.cluster.imagesOccurrences} immagini?`
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.ignoreAllCluster(this.clusterId).subscribe(res => {
          this.imageList.content = [];
          this.cluster.imagesOccurrences = 0;
        });
      });
  }

  assignSelectedFaces() {

    if (this.selectAll) {
      return this.assignAllFaces();
    }

    const selectedImages = this.imageList.content.filter(image => image.selected).map(image => image.reference);

    this.modalService.show(CharacterAssociationModalComponent, null, null, 'modal-xl').observable
      .subscribe((character) => {
        this.athesisFaceNameAssociationService.assignImageList(selectedImages, character).subscribe(res => {
          this.imageList.content = this.imageList.content.filter(image => !image.selected);
          this.cluster.imagesOccurrences -= selectedImages.length;
        });
      });
  }

  private assignAllFaces() {
    this.modalService.show(CharacterAssociationModalComponent, null, null, 'modal-xl').observable
      .subscribe((character) => {
        this.athesisFaceNameAssociationService.assignAllCluster(this.clusterId, character).subscribe(res => {
          this.imageList.content = [];
          this.cluster.imagesOccurrences = 0;
        });
      });
  }

  deleteSelectedFaces() {
    if (this.selectAll) {
      return this.deleteAllFaces();
    }

    const selectedImages = this.imageList.content.filter(image => image.selected).map(image => image.reference);

    const modalConfig = {
      title: 'Ignora volto',
      message: `Confermare operazione Cancella volto per le ${selectedImages.length} immagini selezionate?`
    };

    if (selectedImages.length === 1) {
      modalConfig.message = `Confermare operazione Cancella volto per l\'immagine selezionata?`;
    }

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.deleteImageList(selectedImages).subscribe(res => {
          this.imageList.content = this.imageList.content.filter(image => !image.selected);
          this.cluster.imagesOccurrences -= selectedImages.length;
        });
      });
  }

  private deleteAllFaces() {
    const modalConfig = {
      title: 'Cancella volto',
      message: `Confermare operazione Cancella volto per tutte le ${this.cluster.imagesOccurrences} immagini?`
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.deleteAllCluster(this.clusterId).subscribe(res => {
          this.imageList.content = [];
          this.cluster.imagesOccurrences = 0;
        });
      });
  }

  onScroll() {

    if (this.currentPage  === this.imageList.totalPages - 1) {
      return;
    }

    this.currentPage += 1;
    this.athesisFaceNameAssociationService.getPagedClusterImages(this.clusterId, this.currentPage).subscribe(res => {

      res.content.map(image => {
        this.imagesService.getImageReferences(image.imageId).subscribe(ref => {
          image.selected = this.selectAll;
          image.articles = ref;
          this.imageList.content.push(image);
        });
      });
    });
  }

  toggleSelectOne(index: number) {
    if (this.selectAll && this.imageList.content[index].selected) {
      this.selectAll = false;
    }
  }


  toggleSelectAll() {
    this.imageList.content.map(image => image.selected = this.selectAll);
  }

  imageFullWidth(image) {
    this.modalService.show(FullWidthImageModalComponent, null, image, 'image-detail').observable
      .subscribe(() => { });
  }

  backToList() {
    this.router.navigate(['../../'], {relativeTo: this.route, queryParams: { activeTab: 'cluster'}});
  }
}
