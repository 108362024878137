import { createReducer, on } from '@ngrx/store';
import { imageSearch, fnaResults } from '../actions/athesis-face-name-association-actions';
import { ImageDto, FaceNameAssociationEntity } from '../../models';

export interface State {
    imageSearchResults: ImageDto[];
    fnaResults: FaceNameAssociationEntity[];
}

export const initialState: State = {
    imageSearchResults: null,
    fnaResults: null
};

function mergeState(state, newState) {
    return {
        ...state, ...newState
    };
}

export const faceNameAssociationReducer = createReducer(initialState,
    on(imageSearch, (state, {payload}) => mergeState(state, {imageSearchResults: payload})),
    on(fnaResults, (state, {payload}) => mergeState(state, {fnaResults: payload})),
);
