import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services/app-guard.service';
import { AdminAppGuardService as AdminAuthGuard } from './services/admin-app-guard.service';
import { AthesisDashboardComponent } from './components/athesis-dashboard/athesis-dashboard.component';
import { AthesisImageSearchComponent } from './components/athesis-image-search/athesis-image-search.component';
import {
  AthesisPolopolySimilarSearchComponent
} from './components/athesis-polopoly-similar-search/athesis-polopoly-similar-search.component';
import { AthesisZipponeSimilarSearchComponent } from './components/athesis-zippone-similar-search/athesis-zippone-similar-search.component';
import {
  AthesisFreeTextSimilarSearchComponent
} from './components/athesis-free-text-similar-search/athesis-free-text-similar-search.component';
import { AthesisImageSimilarSearchComponent } from './components/athesis-image-similar-search/athesis-image-similar-search.component';
import { AthesisContentMonitorComponent } from './components/athesis-content-monitor/athesis-content-monitor.component';
import { AthesisTextGenerationComponent } from './components/athesis-text-generation/athesis-text-generation.component';
import { AthesisLoginComponent } from './components/athesis-login/athesis-login.component';
import { AthesisRootComponent } from './components/athesis-root/athesis-root.component';
import { AthesisDomainsViewerComponent } from './components/athesis-domains-viewer/athesis-domains-viewer.component';
import { AthesisDomainCreatorComponent } from './components/athesis-domain-creator/athesis-domain-creator.component';
import { AthesisDomainViewerComponent } from './components/athesis-domain-viewer/athesis-domain-viewer.component';
import { AthesisLinkCreatorComponent } from './components/athesis-link-creator/athesis-link-creator.component';
import { AthesisFileCreatorComponent } from './components/athesis-file-creator/athesis-file-creator.component';
import { AthesisLinkEditorComponent } from './components/athesis-link-editor/athesis-link-editor.component';
import { AthesisTopicViewerComponent } from './components/athesis-topic-viewer/athesis-topic-viewer.component';
import { AthesisArticleViewerComponent } from './components/athesis-article-viewer/athesis-article-viewer.component';
import { AthesisFileViewerComponent } from './components/athesis-file-viewer/athesis-file-viewer.component';
import { AthesisArticleEditorComponent } from './components/athesis-article-editor/athesis-article-editor.component';
import { AthesisFileEditorComponent } from './components/athesis-file-editor/athesis-file-editor.component';
import { AthesisUsersViewerComponent } from './users/components/athesis-users-viewer/athesis-users-viewer.component';
import { AthesisUserViewerComponent } from './users/components/athesis-user-viewer/athesis-user-viewer.component';
import { AthesisUserAdderComponent } from './users/components/athesis-user-adder/athesis-user-adder.component';
import { AthesisBackofficeErrorLogComponent } from './components/athesis-backoffice-error-log/athesis-backoffice-error-log.component';
import { AthesisConfirmRegistrationComponent } from './components/athesis-confirm-registration/athesis-confirm-registration.component';
import { AthesisPasswordRecoveryComponent } from './components/athesis-password-recovery/athesis-password-recovery.component';
import { AthesisPasswordChangeComponent } from './components/athesis-password-change/athesis-password-change.component';
import { AthesisDiagnosticsComponent } from './components/athesis-diagnostics/athesis-diagnostics.component';
import { AthesisNotificationsPageComponent } from './components/athesis-notifications-page/athesis-notifications-page.component';
import { CommonModule } from '@angular/common';
import { AthesisNotFoundPageComponent } from './components/athesis-not-found-page/athesis-not-found-page.component';
import { TopicFormComponent } from './components/topics/topic-form/topic-form.component';
import { VideoSimilarSearchComponent } from './semantic-search/components/video-similar-search/video-similar-search.component';


const routes: Routes = [
  { path: 'login', component: AthesisLoginComponent },
  { path: 'password-recovery', component: AthesisPasswordRecoveryComponent },
  { path: 'reset-password/:resetToken', component: AthesisPasswordChangeComponent },
  { path: 'confirm-registration/:regToken', component: AthesisConfirmRegistrationComponent },
  {
    path: 'athesis-root', component: AthesisRootComponent, canActivate: [AuthGuard], children: [
      { path: 'notFound', component: AthesisNotFoundPageComponent },
      {
        path: 'dashboard',
        component: AthesisDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_dashboard']
        }
      },
      {
        path: 'image-search',
        component: AthesisImageSearchComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_people_faces_search']
        }
      },
      {
        path: 'name-face-association',
        loadChildren: () => import('./name-face-association/name-face-association.module').then(m => m.NameFaceAssociationModule)
      },
      {
        path: 'polopoly-similar-search',
        component: AthesisPolopolySimilarSearchComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_semantic_search_polopoly_similar']
        }
      },
      {
        path: 'zippone-similar-search',
        component: AthesisZipponeSimilarSearchComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_semantic_search_zipponi_similar']
        }
      },
      {
        path: 'free-text-similar-search',
        component: AthesisFreeTextSimilarSearchComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_semantic_search_keywords']
        }
      },
      {
        path: 'image-similar-search',
        component: AthesisImageSimilarSearchComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_semantic_search_images']
        }
      },
      {
        path: 'video-similar-search',
        component: VideoSimilarSearchComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_semantic_search_videos']
        }
      },
      {
        path: 'content-monitor',
        component: AthesisContentMonitorComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_content_monitor']
        },
        children: [
          { path: 'topic-viewer/:topicId', component: AthesisTopicViewerComponent, canActivate: [AuthGuard] },
          { path: 'domains', component: AthesisDomainsViewerComponent, canActivate: [AuthGuard] },
          { path: 'domain-creation', component: AthesisDomainCreatorComponent, canActivate: [AuthGuard] },
          { path: 'domain-creation/:domainId', component: AthesisDomainCreatorComponent, canActivate: [AuthGuard] },
          { path: 'domain/:id/link-creator', component: AthesisLinkCreatorComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/link-editor/:linkId', component: AthesisLinkEditorComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/topic-viewer/:topicId', component: AthesisTopicViewerComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/topic-creator', component: TopicFormComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/topic-editor/:topicId', component: TopicFormComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/article-viewer/:articleId', component: AthesisArticleViewerComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/file-viewer/:fileId', component: AthesisFileViewerComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/article-editor/:articleId', component: AthesisArticleEditorComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/file-editor/:fileId', component: AthesisFileEditorComponent, canActivate: [AuthGuard] },
          { path: 'domain/:id/file-creator', component: AthesisFileCreatorComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/users', component: AthesisUsersViewerComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/users/add', component: AthesisUserAdderComponent, canActivate: [AuthGuard] },
          { path: 'domain/:domainId/user/:userId', component: AthesisUserViewerComponent, canActivate: [AuthGuard] },
          { path: 'domain/:id', component: AthesisDomainViewerComponent, canActivate: [AuthGuard] }
        ]
      },
      {
        path: 'text-generation',
        component: AthesisTextGenerationComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_text_generation']
        }
      },
      {
        path: 'users',
        loadChildren: () => import('./users/user.module').then(m => m.UserModule)
      },
      { path: 'error-log', component: AthesisBackofficeErrorLogComponent, canActivate: [AuthGuard, AdminAuthGuard] },
      { path: 'diagnostics', component: AthesisDiagnosticsComponent, canActivate: [AuthGuard, AdminAuthGuard] },
      {
        path: 'notifications-page',
        component: AthesisNotificationsPageComponent,
        canActivate: [AuthGuard],
        data: {
          requiredPermissions: ['fe_notifications']
        }
      },
    ]
  },
  { path: '**', redirectTo: 'athesis-root/notFound' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
