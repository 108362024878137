import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { AthesisFaceNameAssociationService } from 'src/app/services/athesis-face-name-association.service';
import { Store } from '@ngrx/store';
import { imageSearch } from 'src/app/redux/actions/athesis-face-name-association-actions';
import { ImageDto } from '../../models';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { Subscription, Observable, Subscriber, of } from 'rxjs';
import { RegistrySimpleDto } from '../../name-face-association/models/registry-simple-dto.model';
import { RegistryService } from '../../services/registry.service';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { mergeMap } from 'rxjs/operators';
import { ImagesService } from '../../services/images.service';
import { DocuwebRegistryResponse, SimpleArticleDto } from '../../models/docuweb-registry-response.model';
import { Router } from '@angular/router';


export enum ViewStates {
  TEXT_SEARCH,
  URL_SEARCH,
  UPLOAD_SEARCH
}

export class SimpleImageDto {
  id: string;
  imageId: string;
  imageUrl: string;
  name: string;
  specification: string;
  link: string;
  title: string;
  articles: SimpleArticleDto[];
}

@Component({
  selector: 'app-athesis-image-search',
  templateUrl: './athesis-image-search.component.html',
  styleUrls: ['./athesis-image-search.component.scss']
})

export class AthesisImageSearchComponent implements OnInit, OnDestroy {
  faceNameAssociationSub: Subscription = null;
  imageSearchResults: SimpleImageDto[];
  file: File = null;
  ViewStates = ViewStates;
  viewState = ViewStates.TEXT_SEARCH;
  loading = false;
  searchPlaceholder = 'Inserisci testo';
  icons = icons;

  asyncSelected: string;
  dataSource: Observable<RegistrySimpleDto[]>;
  typeaheadLoading: boolean;
  selectedCharacter: RegistrySimpleDto;

  currentPage = 0;
  totalPages;


  @ViewChild('textInput', {static: false}) textInput;
  @ViewChild('uploadInput', {static: false}) uploadInput;
  datefrom;
  dateto;
  sort: string = null;

  private scrollY;
  scrollDisabled = false;
  private loadedElements = 0;


  constructor(
    private registryService: RegistryService,
    private imagesService: ImagesService,
    private router: Router,
    private athesisFaceNameAssociationService: AthesisFaceNameAssociationService,
    private store: Store<{ imageSearchResults: [] }>) {

    this.dataSource = new Observable((observer: Subscriber<string>) => {
      observer.next(this.asyncSelected);
    }).pipe(
      mergeMap((token: string) => this.getStatesAsObservable(token))
    );
  }

  get isArticleListLoaded() {
    return this.imageSearchResults && this.imageSearchResults.every(image => (!image || image.articles != null));
  }

  isSearchDisabled() {
    return !((this.textInput && this.textInput.nativeElement.value) || (this.uploadInput && this.uploadInput.nativeElement.files[0]));
  }

  ngOnInit() {

    this.imagesService.scrollBlockSub.subscribe(res => {
      this.scrollDisabled = res;
    });

    if (this.imagesService.imageListSub.value) {
      this.imagesService.scrollBlockSub.next(true);
      this.imagesService.imageListSub.subscribe(res => {
        this.totalPages = res.totalPages;
        this.currentPage = res.currentPage;
        this.imageSearchResults = res.list;
        this.scrollY = res.scrollY;
      });
    }
  }

  ngOnDestroy(): void {
    // Clean store from image search results
    if (this.faceNameAssociationSub) {
      this.faceNameAssociationSub.unsubscribe();
    }
    this.store.dispatch(imageSearch(null));
  }

  imageSearch(text: string) {
    switch (this.viewState) {
      case ViewStates.TEXT_SEARCH:
        return this.imageSearchByText(text);
      case ViewStates.URL_SEARCH:
         return this.imageSearchByUrl(text);
      case ViewStates.UPLOAD_SEARCH:
        return this.imageSearchByUpload();
    }
  }

  private mapDocuwebRegistryResponse(docuwebRegistryResponse: DocuwebRegistryResponse): SimpleImageDto {
    return {
      id: docuwebRegistryResponse.registry.id,
      imageId: docuwebRegistryResponse.imageId,
      name: `${docuwebRegistryResponse.registry.name} ${docuwebRegistryResponse.registry.surname}`,
      specification: docuwebRegistryResponse.registry.specification,
      imageUrl: docuwebRegistryResponse.imageUrl,
      link: docuwebRegistryResponse.link,
      title: docuwebRegistryResponse.title,
      articles: docuwebRegistryResponse.articles
    };
  }

  imageSearchByText(text: string) {
    return this.imagesService.getPagedFacesByText(text, this.currentPage, this.datefrom, this.dateto, this.sort
    );
  }

  imageSearchByUrl(url: string) {
    return of(null);
    // return this.athesisFaceNameAssociationService.searchImageByUrl(url);
  }

  imageSearchByUpload() {
    return of(null);
    // return this.athesisFaceNameAssociationService.searchImageByUpload(this.file);
  }

  onImageSearchSuccessful(response: ImageDto[]) {
    this.store.dispatch(imageSearch(response));
    this.loading = false;
  }

  onImageSearchUnsuccessful() {
    console.error('image search unsuccessful');
    this.imageSearchResults = [];
    this.loading = false;
  }

  onUploadFileChange(file: File) {
    this.file = file;
  }

  changeViewState(state: any) {
    this.viewState = state;

    switch (this.viewState) {
      case ViewStates.TEXT_SEARCH:
        this.searchPlaceholder = 'Inserisci testo';
        break;
      case ViewStates.URL_SEARCH:
        this.searchPlaceholder = 'Inserisci URL';
        break;
      case ViewStates.UPLOAD_SEARCH:
        this.searchPlaceholder = 'Carica file';
        break;
    }
  }

  getStatesAsObservable(token: string): Observable<RegistrySimpleDto[]> {
    return this.registryService.searchRegistry(token);
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.loading = true;
    this.imageSearchResults = null;
    this.imagesService.getPagedFacesByRegistryIds([e.item.id]).subscribe(res => {
      this.getImagesArticle(res);
    });
  }

  search() {
    if (this.textInput && !this.textInput.nativeElement.value) {
      return;
    } else if (this.uploadInput && !this.uploadInput.nativeElement.files[0]) {
      return;
    }

    this.currentPage = 0;
    this.loading = true;
    this.imageSearchResults = null;

    this.imageSearch(this.textInput.nativeElement.value).subscribe(res => {
      this.getImagesArticle(res);
    });
  }

  goToCharacterDetail(registry: SimpleImageDto) {
    this.imagesService.imageListSub.next({
      list: this.imageSearchResults, currentPage: this.currentPage, totalPages: this.totalPages, scrollY: window.scrollY
    });
    this.router.navigate(['athesis-root/name-face-association/registry', registry.id, 'detail']);
  }

  onScroll() {

    if (this.currentPage === this.totalPages - 1) {
      return;
    }

    this.currentPage += 1;
    this.loading = true;

    this.imageSearch(this.textInput.nativeElement.value).subscribe(res => {
      this.getImagesArticle(res);
    });
  }

  private getImagesArticle(res) {
    this.totalPages = res.totalPages;
    if (!this.imageSearchResults) {
      this.imageSearchResults = [];
    }

    if (res.content.length === 0) {
      this.loading = false;
      return;
    }

    res.content.map((image, index) => {
      this.imagesService.getImageReferences(image.imageId).subscribe(ref => {
        image.articles = ref;
        this.imageSearchResults.push(this.mapDocuwebRegistryResponse(image));
        if (index === res.content.length - 1) {
          this.loading = false;
          this.imagesService.imageListSub.next({
            list: this.imageSearchResults, currentPage: this.currentPage, totalPages: this.totalPages, scrollY: window.scrollY
          });
        }
      });
    });
  }

  onItemsLoaded() {
    this.loadedElements += 1;
    if (this.loadedElements === this.imageSearchResults.length && this.scrollDisabled) {
      window.scrollTo(0, this.scrollY);
      this.resetCounterAndScroll();
    }
  }

  private resetCounterAndScroll() {
    this.loadedElements = 0;
    this.imagesService.scrollBlockSub.next(false);
  }
}
