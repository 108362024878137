import { createReducer, on } from '@ngrx/store';
import { login, userData } from '../actions/athesis-auth-actions';
import { UserEntity } from 'src/app/models';

export interface State {
    isAuthenticated: boolean;
    user: UserEntity;
}

export const initialState: State = {
    isAuthenticated: false,
    user: null
};

function mergeState(state, newState) {
    return {
        ...state, ...newState
    };
}

export const authReducer = createReducer(initialState,
    on(login, state => mergeState(state, {isAuthenticated: true})),
    on(userData, (state, {payload}) => mergeState(state, {user: payload})),
);
