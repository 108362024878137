import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-athesis-spinner',
  templateUrl: './athesis-spinner.component.html',
  styleUrls: ['./athesis-spinner.component.scss']
})
export class AthesisSpinnerComponent {

  constructor() { }

}
