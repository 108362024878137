import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { AthesisUserService } from '../../../services/athesis-user.service';
import { UserEntity } from '../../../models';
import { AthesisContentMonitorService } from '../../../services/athesis-content-monitor.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-domain-user-modal',
  templateUrl: './add-domain-user-modal.component.html',
  styleUrls: ['./add-domain-user-modal.component.scss']
})
export class AddDomainUserModalComponent implements OnInit {
  item: { userList: UserEntity[], domainId: number };
  modalConfig: any;
  onConfirm: () => void;
  onCancel: () => void;
  submitted = false;
  domainUserMap: Map<number, UserEntity>;
  domainId: number;
  loading = false;

  icons = icons;

  selectedUsers: UserEntity[] = [];
  userList: UserEntity[];

  constructor(
    private modalRef: BsModalRef,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private route: ActivatedRoute,
    private userService: AthesisUserService) { }

  getUsers() {
    this.userService.getAllActiveUsers().subscribe(users => {
      this.userList = users.filter(user => !this.domainUserMap.has(user.id));
    });
  }

  selectUser(user: UserEntity, index: number) {
    this.loading = true;
    this.athesisContentMonitorService.addUserToDomain(user.id, false, this.item.domainId).subscribe(res => {
      this.selectedUsers.push(user);
      this.userList.splice(index, 1);
      this.loading = false;
    });
  }

  ngOnInit() {
    this.domainUserMap = this.item.userList.reduce((acc, item) => acc.set(item.id, item), new Map<number, UserEntity>());
    this.getUsers();
  }

  confirm() {
    this.onConfirm();
    this.modalRef.hide();
  }

  cancel() {
    if (this.selectedUsers.length > 0) {
      this.onConfirm();
    } else {
      this.onCancel();
    }
    this.modalRef.hide();
  }

  private mapUser(user) {
    return {
      id: user.id,
      name: user.name,
      surname: user.surname,
      email: user.email,
      rolesId: user.roles.map(item => item.id)
    };
  }
}
