import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';
import { AthesisUserService } from '../../services/athesis-user.service';


@Directive({
    selector: '[appPermissionCheck]'
})
export class PermissionCheckDirective {
    private currentUser;
    private permissions = [];
    private condition = false;

    @Input('appPermissionCheck') set requiredPermissions(requiredPerm: string[]) {
      this.permissions = requiredPerm;
      this.updateView();
    }

    @Input() set appPermissionCheckCustomCondition(customCondition: boolean) {
      this.condition = customCondition;
      this.updateView();
    }

    constructor(
      private templateRef: TemplateRef<any>,
      private vcRef: ViewContainerRef,
      private userService: AthesisUserService) {
        this.currentUser = this.userService.currentUser;
      }

    private checkPermission() {
      if (this.currentUser) {
        return this.currentUser.isAdmin() ||
          (this.permissions.length && this.permissions.every(perm => this.currentUser.hasPermission(perm))) ||
          !!this.condition;
      }
    }

    private updateView() {
      this.vcRef.clear();

      if (this.checkPermission()) {
        this.vcRef.createEmbeddedView(this.templateRef);
      }
    }
}
