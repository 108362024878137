import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, TypeaheadMatch } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscriber } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { RegistryService } from '../../../../services/registry.service';
import { RegistrySimpleDto } from '../../../models/registry-simple-dto.model';
import { AthesisFaceNameAssociationService } from '../../../../services/athesis-face-name-association.service';

@Component({
  selector: 'app-character-association-modal',
  templateUrl: './character-association-modal.component.html'
})
export class CharacterAssociationModalComponent implements OnInit, AfterViewInit {
  selectedCharacter: RegistrySimpleDto;
  asyncSelected: string;
  dataSource: Observable<RegistrySimpleDto[]>;
  typeaheadLoading: boolean;
  item: any;
  modalConfig: any;
  template: TemplateRef<any>;
  onConfirm: Function;
  onCancel: Function;
  form: FormGroup;
  isNewCharacter = false;
  submitted = false;


  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder,
    private faceNameAssociationService: AthesisFaceNameAssociationService,
    private registriService: RegistryService) {
    this.dataSource = new Observable((observer: Subscriber<string>) => {
      observer.next(this.asyncSelected);
    }).pipe(
      mergeMap((token: string) => this.getStatesAsObservable(token))
    );
  }

  ngOnInit() {
    this.form = this.buildForm(this.fb);
  }

  buildForm(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(null),
      name: fb.control(null, Validators.required),
      surname: fb.control(null, Validators.required),
      specification: fb.control(null),
    });
  }

  ngAfterViewInit() {

  }

  confirm() {
    this.onConfirm(this.selectedCharacter);
    this.modalRef.hide();
  }

  cancel() {
    this.onCancel();
    this.modalRef.hide();
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.selectedCharacter = this.form.value;
      this.confirm();
    }
  }

  getStatesAsObservable(token: string): Observable<RegistrySimpleDto[]> {
    return this.registriService.searchRegistry(token);
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.selectedCharacter = e.item;
  }
}
