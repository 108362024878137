import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AthesisAuthService} from './athesis-auth.service';
import { API_BASE_URL } from '../constants';
import {GenericSearchResponse, GenericSearchResponseEntity} from '../models';
import {AthesisModalNfaConfirmComponent} from '../name-face-association/components/athesis-modal-nfa-confirm/athesis-modal-nfa-confirm.component';
import {Observable} from 'rxjs';
import {Tree} from '../components/athesis-image-similar-search/tree.model';
import { ImageSearchParams } from '../components/athesis-image-similar-search/athesis-image-similar-search.component';



@Injectable({
  providedIn: 'root'
})

export class AthesisImagesSearchResultsService {
  private readonly apiVersion2 = 'api/v2';
  baseUrl = API_BASE_URL + this.apiVersion2;

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) { }


  searchImages(searchParams: ImageSearchParams, page= 0, size= 10) {
    let params = new HttpParams()
      .set('nodeId', searchParams.nodeId.toString())
      .set('sort', ['date,desc'].toString())
      .set('page', page.toString())
      .set('size', size.toString());
    if (searchParams.dateFrom) {
      params = params.append('dateFrom', searchParams.dateFrom);
    }
    if (searchParams.dateTo) {
      params = params.append('dateTo', searchParams.dateTo);
    }
    const images = this.baseUrl + '/images';
    return this.http.get<GenericSearchResponse>(images, { ...this.athesisAuthService.httpOptions, params });
  }

}
