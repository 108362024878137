import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-athesis-cluster-filter',
  templateUrl: './athesis-cluster-filter.component.html',
  styleUrls: ['./athesis-cluster-filter.component.scss']
})
export class AthesisClusterFilterComponent implements OnInit {
  @Output() submitFilter = new EventEmitter<any>();
  form = new FormGroup({
    min: new FormControl(''),
    max: new FormControl('')
  });
  icons = icons;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.buildForm(this.fb);
    this.form.setValue({min: 2, max: null});
  }
  onSubmit() {
    this.submitFilter.emit(this.form.value);
  }

  buildForm(fb: FormBuilder): FormGroup {
    return fb.group({
      min: fb.control(null),
      max: fb.control(null)
    });
  }

}
