import { Component, OnInit, OnDestroy } from '@angular/core';
import { FileEntity } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { Subscription } from 'rxjs';
import { AthesisFileService } from '../../services/file/athesis-file.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-athesis-file-viewer',
  templateUrl: './athesis-file-viewer.component.html',
  styleUrls: ['./athesis-file-viewer.component.scss']
})
export class AthesisFileViewerComponent implements OnDestroy {
  contentMonitorSub: Subscription = null;
  fileId: number = null;
  domainId: number = null;
  selectedFile: FileEntity = null;

  constructor(
    private route: ActivatedRoute,
    private store: Store<{}>,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private athesisFileService: AthesisFileService,
    private router: Router,
    private athesisContentMonitorHelperService: AthesisContentMonitorHelperService) {

    this.fileId = Number(route.snapshot.params.fileId);
    this.domainId = Number(route.snapshot.params.domainId);

    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.file) {
      this.selectedFile = state.file;
    } else {
      this.getFileDetail();
    }
  }

  getFileDetail() {
    const params = new HttpParams()
      .set('fileId', this.fileId.toString());

    this.athesisFileService.getFiles(params).subscribe(
      response => {
        this.selectedFile = response[0];
      }, error => {
        console.error('Error retriving file info', error);
      });
  }

  onFileDownloadRequest(file: FileEntity) {
    this.athesisContentMonitorService.downloadFile(file.reference).subscribe(
      (res: Blob) => this.onFileDownloadSuccessful(res),
      err => this.onFileDownloadUnsuccessful()
    );
  }

  onFileDownloadSuccessful(response: Blob) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(response);
      return;
    }
    const downloadURL = URL.createObjectURL(response);
    window.open(downloadURL);
  }

  onFileDownloadUnsuccessful() {
    // TODO: Handle unsuccessful file download
    console.log('file download unsuccessful');
  }

  returnToDomainView() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  ngOnDestroy() {
    if (this.contentMonitorSub) {
      this.contentMonitorSub.unsubscribe();
    }
  }
}
