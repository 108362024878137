import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-athesis-modal-delete',
  templateUrl: './athesis-modal-delete.component.html',
  styleUrls: ['./athesis-modal-delete.component.scss']
})

export class AthesisModalDeleteComponent {
  @Output() confirmEvent: EventEmitter<any> = new EventEmitter();
  @Input() modalLabel: string;
  @Input() modalText: string;
  @Input() confirmText: string;

  constructor() { }

  emitConfirmEvent() {
    this.confirmEvent.emit();
  }
}
