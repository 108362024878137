import { Domain } from './domain.model';


export class DomainRequest {

    constructor(domain?: Domain) {
        if (domain) {
            this.name = domain.name;
            this.forget = domain.forget;
            this.threshold = domain.threshold;
        }
    }

    name: string;
    forget: number;
    threshold: number;
}
