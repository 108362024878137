import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AthesisUserService } from 'src/app/services/athesis-user.service';

@Component({
  selector: 'app-athesis-password-recovery',
  templateUrl: './athesis-password-recovery.component.html',
  styleUrls: ['./athesis-password-recovery.component.scss']
})
export class AthesisPasswordRecoveryComponent {

  constructor(private route: ActivatedRoute, private athesisUserService: AthesisUserService, private router: Router) {}

  recoverPassword(email: string) {
    this.athesisUserService.forgotPassword(email).subscribe(
      res => this.onPasswordRecoverySuccessful(),
      err => this.onPasswordRecoveryUnsuccessful()
    );
  }

  onPasswordRecoveryUnsuccessful(): void {
    console.log('passwordRecovery unsuccessful');
    this.router.navigate(['login']);
  }

  onPasswordRecoverySuccessful(): void {
    this.router.navigate(['login']);
  }
}
