import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FaceNotAssociatedResponse } from '../../models/face-not-associated.model';
import { Page } from '../../../models/pagination/page.model';
import { FullWidthImageModalComponent } from '../../../shared/components/full-width-image-modal/full-width-image-modal.component';
import { ModalsService } from '../../../services/modals/modals.service';
import { AthesisFaceNameAssociationService } from '../../../services/athesis-face-name-association.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nfa-card-list',
  templateUrl: './nfa-card-list.component.html'
})
export class NfaCardListComponent implements OnInit, OnDestroy {

  currentPage = 0;
  @Input() cardList: Page<FaceNotAssociatedResponse>;
  @Input() cardType: string;
  scrollDisabled: boolean;
  @Output() scrollList: EventEmitter<any> = new EventEmitter();
  @Output() viewDetail: EventEmitter<any> = new EventEmitter();

  private loadedElements = 0;
  private totalElements = 1;
  private scrollY;

  private scrollBlockSubscription: Subscription;
  private clusterListSubscription: Subscription;
  private registryListSubscription: Subscription;

  constructor(private modalService: ModalsService, private faceNameAssociationService: AthesisFaceNameAssociationService) { }

  ngOnInit() {
    this.scrollBlockSubscription = this.faceNameAssociationService.scrollBlockSub.subscribe(res => {
      this.scrollDisabled = res;
    });
    if (this.cardType === 'cluster') {
      this.clusterListSubscription = this.faceNameAssociationService.clusterListSub.subscribe(res => {
        this.totalElements = res.list.content.length;
        this.scrollY = res.scrollY;
      });
    } else {
      this.registryListSubscription = this.faceNameAssociationService.registryListSub.subscribe(res => {
        this.totalElements = res.list.content.length;
        this.scrollY = res.scrollY;
      });
    }
  }

  onScroll() {
    this.scrollList.emit();
  }

  imageFullWidth(image) {
    this.modalService.show(FullWidthImageModalComponent, null, image, 'image-detail modal-xl').observable
      .subscribe(() => { });
  }

  onItemsLoaded() {
    this.loadedElements += 1;
    if (this.loadedElements === this.totalElements && this.scrollDisabled) {
      window.scrollTo(0, this.scrollY);
      this.resetCounterAndScroll();
    }
  }

  private resetCounterAndScroll() {
    this.loadedElements = 0;
    this.faceNameAssociationService.scrollBlockSub.next(false);
  }

  ngOnDestroy() {
    if (this.scrollBlockSubscription) {
      this.scrollBlockSubscription.unsubscribe();
    }
    if (this.clusterListSubscription) {
      this.clusterListSubscription.unsubscribe();
    }
    if (this.registryListSubscription) {
      this.registryListSubscription.unsubscribe();
    }
  }
}
