import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AthesisAuthService } from './athesis-auth.service';
import { API_NOTIFICATION, API_NOTIFICATION_COUNT, API_READ_NOTIFICATION, API_ALL_NOTIFICATION } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AthesisNotificationService {

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) { }

  notificationCounter(userId: number) {
    return this.http.get(API_NOTIFICATION_COUNT(userId), this.athesisAuthService.httpOptions);
  }

  notReadNotification(userId: number, page = 0, size = 10) {
    return this.http.get(API_NOTIFICATION(userId, page, size), this.athesisAuthService.httpOptions);
  }

  allNotification(userId: number, page?: number) {
    return this.http.get(API_ALL_NOTIFICATION(userId, page), this.athesisAuthService.httpOptions);
  }

  readNotification(notificationId: number, userId: number) {
    console.log(this.athesisAuthService.httpOptions);
    return this.http.put(API_READ_NOTIFICATION(notificationId, userId), {}, this.athesisAuthService.httpOptions);
  }

}
