import { environment } from '@environment';

export const HTTP_HEADERS_BASE = { accept: '*/*', 'Content-Type': 'application/json' };
export const HTTP_HEADERS_BASE_UPLOAD = { accept: '*/*' };
export const HEADER_AUTHORIZATION_VALUE = (str: string) => `Bearer ${str}`;

export const SESSION_STORAGE_ACCESS_TOKEN_KEY = 'accessToken';
export const SESSION_STORAGE_USER_DATA_KEY = 'userData';
export const SESSION_STORAGE_CONFIG = 'config';


export const API_BASE_URL = environment.baseurl;
export const API_VERSION_URL = 'api/v1';
export const API_AUTH_LOGIN = API_BASE_URL + API_VERSION_URL + '/auth/login';
export const API_AUTH_REGISTER = API_BASE_URL + API_VERSION_URL + '/auth/register';
export const API_FACE_NAME_ASSOCIATION_SEARCH_IMG_BY_TEXT = (str: string) =>
  API_BASE_URL + API_VERSION_URL + `/facenameassociation/search-img?text=${str}`;
export const API_FACE_NAME_ASSOCIATION_SEARCH_IMG_BY_URL = (str: string) =>
  API_BASE_URL + API_VERSION_URL + `/facenameassociation/search-imgUrl?description=&tags=&url=${str}`;
export const API_FACE_NAME_ASSOCIATION_SEARCH_IMG_BY_UPLOAD =
  API_BASE_URL + API_VERSION_URL + '/facenameassociation/search-byUpload?description=&tags';
export const API_CONTENT_MONITOR_DOMAINS =
  API_BASE_URL + API_VERSION_URL + '/domains/mydomains';
export const API_CONTENT_MONITOR_CREATE_DOMAIN =
  API_BASE_URL + API_VERSION_URL + '/domains';
export const API_CONTENT_MONITOR_UPDATE_DOMAIN = (domainId: number) =>
  API_BASE_URL + API_VERSION_URL + `/domains/${domainId}`;
export const API_CONTENT_MONITOR_DELETE_DOMAIN = (str: number) =>
  API_BASE_URL + API_VERSION_URL + `/domains/${str}`;
export const API_CONTENT_MONITOR_DOMAIN_BY_ID = (domainId: number) =>
  API_BASE_URL + API_VERSION_URL + `/domains/${domainId}`;
export const API_CONTENT_MONITOR_DOMAIN_BY_ID_MANAGE_USERS = (domainId: number) =>
  API_BASE_URL + API_VERSION_URL + `/domains/${domainId}/users`;
export const API_CONTENT_MONITOR_DOMAIN_BY_ID_USERS = (domainId: number, userId: number) =>
  API_BASE_URL + API_VERSION_URL + `/domains/${domainId}/users/${userId}`;
export const API_CONTENT_MONITOR_DOMAINS_SEARCH =
  API_BASE_URL + API_VERSION_URL + '/domains/search';
export const API_CONTENT_MONITOR_CONTENTS =
  API_BASE_URL + API_VERSION_URL + '/contents';
export const API_CONTENT_MONITOR_CONTENT_BY_ID = (str: number) =>
  API_BASE_URL + API_VERSION_URL + `/contents/${str}`;
export const API_CONTENT_MONITOR_TOPICS =
  API_BASE_URL + API_VERSION_URL + '/topics';
export const API_CONTENT_MONITOR_TOPICS_BY_DOMAIN = (domainId) =>
  API_BASE_URL + API_VERSION_URL + `/topics/domain/${domainId}`;
export const API_CONTENT_MONITOR_TOPICS_BY_ID = (topicId) =>
  API_BASE_URL + API_VERSION_URL + `/topics/${topicId}`;
export const API_CONTENT_MONITOR_UPDATE_TOPIC = (topicId) =>
  API_BASE_URL + API_VERSION_URL + `/topics/${topicId}`;
export const API_CONTENT_MONITOR_DELETE_TOPIC = (str: number) =>
  API_BASE_URL + API_VERSION_URL + `/topics/${str}`;
export const API_CONTENT_MONITOR_ARTICLES =
  API_BASE_URL + API_VERSION_URL + '/articles';
export const API_CONTENT_MONITOR_ARTICLE_BY_ID = (str: number) =>
  API_BASE_URL + API_VERSION_URL + `/articles/${str}`;
export const API_CONTENT_MONITOR_FILES =
  API_BASE_URL + API_VERSION_URL + '/files';
export const API_CONTENT_MONITOR_FILE_BY_REFERENCE = (str: string) =>
  API_BASE_URL + API_VERSION_URL + `/files/${str}`;
export const API_CONTENT_MONITOR_FILE_BY_REFERENCE_DETAILS = (ref: string, name: string, desc: string) =>
  API_BASE_URL + API_VERSION_URL + `/files/${ref}/details?description=${desc}&name=${name}`;
export const API_SEMANTIC_SEARCH_KEYWORDS = (
  anth: boolean,
  img: boolean,
  pol: boolean,
  text: boolean,
  video: boolean,
  zipp: boolean,
  topic: string) =>
  API_BASE_URL + API_VERSION_URL + `/semanticsearch/searchKEYWORDS?anthologyContent=${anth}&imgContent=${img}&polopolyContent=${pol}&textContent=${text}&videoContent=${video}&zippContent=${zipp}&topicType=${topic}`;


export const API_USERS_ALL = API_BASE_URL + API_VERSION_URL + '/users';
export const API_USERS_ALL_ACTIVE = API_BASE_URL + API_VERSION_URL + '/users/active';
export const API_ROLES_ALL = API_BASE_URL + API_VERSION_URL + '/roles';

export const API_FACE_NAME_ASSOCIATION_IMG_LIST = API_BASE_URL + API_VERSION_URL + '/facenameassociation/imgList';
export const API_FACE_NAME_ASSOCIATION_IMG_DETAIL = (str: string) =>
  API_BASE_URL + API_VERSION_URL + `/facenameassociation/imgDetails/${str}`;
export const API_FACE_NAME_ASSOCIATION_IMG_RECOGNITION = (name: string, ref: string) =>
  API_BASE_URL + API_VERSION_URL + `/facenameassociation/recognition?nome=${name}&rifImmagine=${ref}`;
export const API_FACE_NAME_ASSOCIATION_IMG_ALLEGED = (name: string, page: number, size: number) =>
  API_BASE_URL + API_VERSION_URL + `/facenameassociation/imgList/allegated?nome=${name}&page=${page}&size=${size}`;
export const API_FACE_NAME_ASSOCIATION_PEOPLE_AUTOCOMPLETE = (str: string) =>
  API_BASE_URL + API_VERSION_URL + `/facenameassociation/people/search?name=${str}`;

export const API_ADMIN_ERROR_LOG = (page: number, size: number) =>
  API_BASE_URL + API_VERSION_URL + `/errorlog?page=${page}&size=${size}`;

export const API_AUTH_CONFIRM_REGISTRATION = (str: string) =>
  API_BASE_URL + API_VERSION_URL + `/auth/confirm?token=${str}`;
export const API_AUTH_RESET_PASSWORD = (str: string) =>
  API_BASE_URL + API_VERSION_URL + `/auth/resetpassword?token=${str}`;
export const API_AUTH_FORGOT_PASSWORD =
  API_BASE_URL + API_VERSION_URL + `/auth/forgotpassword`;
export const API_NEWSPAPERS =
  API_BASE_URL + API_VERSION_URL + `/newspapers`;
export const API_ALL_TOPICS =
  API_BASE_URL + API_VERSION_URL + '/topics';

export const  API_CHANGE_ENABLED = (id: number) =>
  API_BASE_URL + API_VERSION_URL + '/users/{id}';

/* TEST API DIAGNOSTICS */
export const API_ARCHIVED_DIAGNOSTIC_DATA =
  API_BASE_URL + API_VERSION_URL + '/diagnostics/tables/archived';

export const API_ERRORS_DIAGNOSTIC_DATA =
  API_BASE_URL + API_VERSION_URL + '/diagnostics/tables/errors';

export const API_NOT_ARCHIVED_DIAGNOSTIC_DATA =
  API_BASE_URL + API_VERSION_URL + '/diagnostics/tables/not-archived';

export const API_RECORDED_DIAGNOSTIC_DATA =
  API_BASE_URL + API_VERSION_URL + '/diagnostics/tables/recorded';

export const API_GENERAL_DIAGNOSTIC_COUNTER =
  API_BASE_URL + API_VERSION_URL + '/diagnostics/general/counters';

export const API_SEMANTIC_SEARCH_TOPICS =
  API_BASE_URL + API_VERSION_URL + '/semanticsearch/topics';



/* TEST API NOTIFICATION COUNTER */
export const API_NOTIFICATION_COUNT = (userId: number) =>
  API_BASE_URL + API_VERSION_URL + `/notifications/users/${userId}/notread/count`;

/* TEST API NOTIFICATION */
export const API_NOTIFICATION = (userId: number, page: number, size: number) =>
  API_BASE_URL + API_VERSION_URL + `/notifications/users/${userId}/notread?page=${page}&size=${size}`;
/* `/notifications/users/${userId}/notread?page=0&size=6&sort=sentDate,desc`; */

/* TEST API ALL NOTIFICATION */
export const API_ALL_NOTIFICATION = (userId: number, page: number) =>
  API_BASE_URL + API_VERSION_URL + `/notifications/users/${userId}?size=15&page=${page}`;

/* TEST API READ NOTIFICATION */
export const API_READ_NOTIFICATION = (notificationId: number, userId: number) =>
  API_BASE_URL + API_VERSION_URL + `/notifications/${notificationId}/users/${userId}/read`;

export const API_TOPIC = (str: number) =>
  API_BASE_URL + API_VERSION_URL + `/topics/${str}`;
export const API_TOPIC_APPROVE = (str: number) =>
  API_BASE_URL + API_VERSION_URL + `/topics/${str}/approve`;
export const API_TOPIC_REJECT = (str: number) =>
  API_BASE_URL + API_VERSION_URL + `/topics/${str}/reject`;
export const API_TOPIC_REJECT_REASONS = API_BASE_URL + API_VERSION_URL + `/topics/rejection-reasons/`;
export const API_SEMANTIC_SEARCH_ZIPP_ISSUE_AUTOCOMPLETE = (issue: string, npCode: string) =>
  API_BASE_URL + API_VERSION_URL + `/semanticsearch/searchZIPP/issues?issue=${issue}&zipponeNewspaperCode=${npCode}`;
export const API_SEMANTIC_SEARCH_ZIPP_ISSUE_PAGES = (issue: string, npCode: string) =>
  API_BASE_URL + API_VERSION_URL + `/semanticsearch/searchZIPP/pages?issue=${issue}&zipponeNewspaperCode=${npCode}`;
export const API_SEMANTIC_SEARCH_ZIPP_ARTICLES = (nodeId: string) =>
  API_BASE_URL + API_VERSION_URL + `/semanticsearch/searchZIPP/articles?idNodo=${nodeId}`;
export const API_SEMANTIC_SEARCH_ZIPP_ARTICLE_CONTENTS = (fileId: string) =>
  API_BASE_URL + API_VERSION_URL + `/semanticsearch/searchZIPP/articles/contents?idFile=${fileId}`;
export const API_DELETE_TOPIC = (topicId: number) => `${API_BASE_URL}${API_VERSION_URL}/topics/${topicId}`;

export const API_SEMANTIC_SEARCH_CONFERRING_SYSTEM =  API_BASE_URL + API_VERSION_URL + `/conferring-systems`;

// tslint:disable-next-line: max-line-length
export const API_SEMANTIC_SEARCH_ZIPP = (
  anthologyContent: boolean,
  idFile: string,
  imgContent: boolean,
  polopolyContent: boolean,
  textContent: boolean,
  videoContent: boolean,
  zippContent: boolean) => {
  // tslint:disable-next-line: max-line-length
  return API_BASE_URL + API_VERSION_URL + `/semanticsearch/searchZIPP?anthologyContent=${anthologyContent}&idFile=${idFile}&imgContent=${imgContent}&polopolyContent=${polopolyContent}&textContent=${textContent}&videoContent=${videoContent}&zippContent=${zippContent}`;
};



export const PASSWORD_REGEX = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_-])[\\S]{8,}$';
