import { Component, OnInit } from '@angular/core';
import { AthesisNotificationService } from 'src/app/services/athesis-notification.service';
import { Store } from '@ngrx/store';
import { UserEntity, NotificationEntity } from 'src/app/models';
import { interval, Observable, Subject, of } from 'rxjs';


@Component({
  selector: 'app-athesis-badge-notification',
  templateUrl: './athesis-badge-notification.component.html',
  styleUrls: ['./athesis-badge-notification.component.scss']
})

export class AthesisBadgeNotificationComponent implements OnInit {
  badgeCount;
  content;
  read;
  user: UserEntity;
  size = 6;
  page = 0;
  refreshTimeMilliseconds = 600000;

  private userRetrived$: Observable<any>;

  constructor(private notificationService: AthesisNotificationService, private store: Store<{ user: UserEntity, notification: NotificationEntity }>) {
    store.select('auth').subscribe((auth) => {
      if (auth && auth.user) {
        this.user = auth.user;
        this.userRetrived$ = of(auth.user);
      }
    });
  }

  readNotification(notificationId: number) {
    this.notificationService.readNotification(notificationId, this.user.id).subscribe(readNotificationResponse => {
      this.getNotificationCount();
      this.getUnreadNotification();
      this.read = readNotificationResponse;
    });
  }

  getNotificationCount() {
    this.notificationService.notificationCounter(this.user.id).subscribe(response => {
      this.badgeCount = response;
    });
  }

  getUnreadNotification() {
    this.notificationService.notReadNotification(this.user.id, this.page, this.size).subscribe(notificationResponse => {
      this.content = notificationResponse['content'].slice(0, 6);
    });
  }

  ngOnInit() {
    this.userRetrived$.subscribe(res => {
      this.notificationUpdate();
      interval( this.refreshTimeMilliseconds ).subscribe(val => this.notificationUpdate());
    });
  }

  private notificationUpdate() {

    this.getNotificationCount();
    this.getUnreadNotification();
  }
}
