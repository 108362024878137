import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SelectableTag } from '../../models';
import { TagModel, TagModelClass } from 'ngx-chips/core/accessor';

@Component({
  selector: 'app-tag-search',
  templateUrl: './tag-search.component.html',
  styleUrls: ['./tag-search.component.scss']
})
export class TagSearchComponent implements OnInit, OnChanges {

  @Input() tagList: SelectableTag[];
  @Input() placeholder: string;
  @Output() selectedElement = new EventEmitter<SelectableTag[]>();
  @Input() selectedItems: SelectableTag[];

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tagList) {
      this.checkSelectedItems();
    }
  }

  updateSelectedList() {
    this.selectedElement.emit(this.selectedItems);
  }

  private checkSelectedItems() {
    if (this.selectedItems) {
      const idList = this.tagList.map(item => item.id);
      this.selectedItems = this.selectedItems.filter(item => idList.indexOf(item.id) >= 0);
      this.updateSelectedList();
    }
  }

  startsWithFn(value: string, target: TagModel) {
    const targetValue = (target as TagModelClass).title.toString();

    return targetValue && targetValue
      .toLowerCase()
      .startsWith(value.toLowerCase());
  }
}
