import { Pipe, PipeTransform } from '@angular/core';
import { stringify } from '@angular/compiler/src/util';

@Pipe({
  name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {

  transform(value: string, ...args: number[]): string {
    if(value.length > args[0]){
      return value.substring(0, args[0]) + "[...]";
    }else {
      return value;
    }
  }
}
