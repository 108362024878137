import { TreeviewItem } from 'ngx-treeview';


export class ImageTreeHelper {
    static transformTreeNode(item, callback?): TreeviewItem {
        return new TreeviewItem({
          text: item.italianName,
          value: item,
          collapsed: true,
          checked: !!callback && callback(item),
          children: ImageTreeHelper.createTree(item.children, callback)
        });
      }

      static createTree(treeJson, callback?): TreeviewItem [] {
        return treeJson.map(item => this.transformTreeNode(item, callback));
      }
}
