import { Component, OnInit, ViewChild } from '@angular/core';
import { AthesisAuthService, LoginResponse, LoginRequest } from '../../services/athesis-auth.service';
import { Store } from '@ngrx/store';
import { login, userData } from '../../redux/actions/athesis-auth-actions';
import { Router } from '@angular/router';
import { SESSION_STORAGE_ACCESS_TOKEN_KEY, SESSION_STORAGE_USER_DATA_KEY, SESSION_STORAGE_CONFIG } from 'src/app/constants';
import { AthesisTopicService } from 'src/app/services/athesis-topic.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-athesis-login',
  templateUrl: './athesis-login.component.html',
  styleUrls: ['./athesis-login.component.scss']
})
export class AthesisLoginComponent {
  @ViewChild('loginForm', {static: false}) loginForm: NgForm;
  showAuthError = false;

  constructor(
    private athesisAuthService: AthesisAuthService,
    private athesisTopicService: AthesisTopicService,
    private store: Store<{ isAuthenticated: boolean }>,
    private router: Router
  ) { }

  login(loginRequest: LoginRequest) {
    this.athesisAuthService.attemptLogin(loginRequest).subscribe(
      res => {
        this.onLoginSuccessful(res);
      },
      err => {
        this.onLoginUnsuccessful();
      }
    );
  }

  onLoginSuccessful(response: LoginResponse) {
    sessionStorage.setItem(SESSION_STORAGE_ACCESS_TOKEN_KEY, response.accessToken);
    this.store.dispatch(userData(response.user));
    // TODO: Valutare se mantenerei dati utente nel session storage o se chiamare ogni volta il backend
    sessionStorage.setItem(SESSION_STORAGE_USER_DATA_KEY, JSON.stringify(response.user));

    this.store.dispatch(login());
    this.athesisTopicService.getRejectTopicReasons().subscribe(
      res => { sessionStorage.setItem(SESSION_STORAGE_CONFIG, JSON.stringify({ rejectedTopicReasons: res })); },
      err => { },
    );
    this.router.navigate(['athesis-root', 'dashboard']);
  }

  onLoginUnsuccessful() {
    this.showAuthError = true;
  }

  onSubmit() {
    const loginRequest = this.loginForm.value as LoginRequest;
    this.login(loginRequest);
  }
}
