import {Component, Input, OnInit} from '@angular/core';
import {faTag} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {
  @Input() tags: string[];
  faTag = faTag;
  constructor() { }

  ngOnInit() {
  }

}
