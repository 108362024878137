import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { TopicEntity, Status, RejectReasonsEntity, DomainEntity } from 'src/app/models';
import { Subscription } from 'rxjs';
import { AthesisTopicService } from 'src/app/services/athesis-topic.service';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { TopicDto } from '../../models';
import { AthesisArticleService } from '../../services/article/athesis-article.service';
import { AthesisUserService } from '../../services/athesis-user.service';
import { AuthUser } from '../../users/auth-user.model';

@Component({
  selector: 'app-athesis-topic-viewer',
  templateUrl: './athesis-topic-viewer.component.html',
  styleUrls: ['./athesis-topic-viewer.component.scss']
})
export class AthesisTopicViewerComponent implements OnDestroy {
  contentMonitorSub: Subscription = null;
  topicId: number = null;
  domainId: number = null;
  selectedTopic: TopicEntity = null;
  status = Status;
  icons = icons;
  modalData = {
    action: '',
    selectedTopicId: 0,
    title: '',
    body: '',
    buttonMessage: ''
  };
  rejectReasons: RejectReasonsEntity[];
  selectedReason = { id: 1, deleted: false, message: 'Non pertinente', code: 'IRRELEVANT', index: 1 };
  selectedArticleId: number;
  currentUser: AuthUser;
  selectedDomain: DomainEntity;
  loading = true;

  constructor(
    private route: ActivatedRoute,
    private store: Store<{}>,
    private userService: AthesisUserService,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private athesisTopicService: AthesisTopicService,
    private athesisArticleService: AthesisArticleService,
    private router: Router,
    private athesisContentMonitorHelperService: AthesisContentMonitorHelperService) {

    this.topicId = +route.snapshot.params.topicId;
    this.domainId = +route.snapshot.params.domainId;
    this.rejectReasons = JSON.parse(sessionStorage.getItem('config')).rejectedTopicReasons;

    this.route.params.subscribe(params => {
      this.topicId = +params.topicId;
      this.domainId = +params.domainId;
    });

    this.currentUser = this.userService.currentUser;

    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.topic) {
      this.selectedTopic = state.topic;
      this.selectedDomain = state.domain;
      this.athesisArticleService.getByTopicId(this.selectedTopic.id).subscribe(articles => {
        this.selectedTopic.articles = articles;
        this.loading = false;
      });
    } else {
      this.athesisContentMonitorService.getUserDomain(this.domainId).subscribe(domain => {
        this.selectedDomain = domain;
        this.getTopic();
      });
    }
  }

  getTopic() {
    this.loading = true;
    this.athesisContentMonitorService.getTopicById(this.topicId).subscribe(response => {
      this.selectedTopic = response;
      this.domainId = this.selectedTopic.domain.id;
      this.loading = false;
    });
  }

  returnToDomainView() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  ngOnDestroy() {
    if (this.contentMonitorSub) {
      this.contentMonitorSub.unsubscribe();
    }
  }

  setModalData(topic: TopicEntity, action: any) {
    const body = {
      approve: 'Sei sicuro di voler approvare questo topic?',
      reject: 'Per quale motivo vuoi rifiutare questo topic?',
    };

    const title = {
      approve: 'Approva topic',
      reject: 'Rifiuta topic',
    };

    const button = {
      approve: 'Approva',
      reject: 'Rifiuta',
    };

    this.modalData.action = action;
    this.modalData.selectedTopicId = topic.id;
    this.modalData.body = body[action];
    this.modalData.title = title[action];
    this.modalData.buttonMessage = button[action];
    this.selectedReason = { id: 1, deleted: false, message: 'Non pertinente', code: 'IRRELEVANT', index: 1 };
  }

  approvesOrReject() {
    if (this.modalData.action === 'approve') {
      this.athesisTopicService.approveTopic(this.modalData.selectedTopicId).subscribe(response => {
        this.getTopic();
      });
    } else {
      const selectedReason = {
        reasonOptionId: this.selectedReason.id !== 0 ? this.selectedReason.id : null,
        reasonText: this.selectedReason.message
      };
      this.athesisTopicService.rejectTopic(this.modalData.selectedTopicId, selectedReason).subscribe(response => {
        this.getTopic();
      });
    }
  }

  removeArticle(articleId: number) {
    if (this.selectedTopic.articles.length === 1) {
      console.debug('AthesisTopicViewerComponent.removeArticle', 'Warning, article list must contain at least one item');
      return;
    }

    this.athesisArticleService.deleteArticle(this.selectedTopic.id, articleId).subscribe(response => {
      console.debug('AthesisArticleService.deleteArticle', response);
      this.selectedTopic.articles = this.selectedTopic.articles.filter(article => article.id !== articleId);
    });
  }

  getDtoFromEntity(topic: TopicEntity): TopicDto {
    return {
      articles: topic.articles.map(article => article.url),
      dateCreateTopic: topic.dateCreateTopic,
      domainId: topic.domain.id,
      id: topic.id,
      title: topic.title,
      topicDescription: topic.topicDescription
    };
  }

  userHasDomainWritePermissions() {
    return this.currentUser.isAdmin() ||
    !!this.selectedDomain.userDomainEntities.find(userDomainEntity =>
      userDomainEntity.canWrite && userDomainEntity.user.id === this.currentUser.id);
  }
}
