import { Component, OnDestroy } from '@angular/core';
import { ContentDto, FormMapping, ContentEntity, LinkType } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { Subscription } from 'rxjs';

export const LinkTypeMapping: FormMapping[] = [
  { value: LinkType.WEB_SITE, label: 'Sito Web', enumStringValue: 'WEB_SITE' },
  { value: LinkType.WEB_PAGE, label: 'Pagina Web', enumStringValue: 'WEB_PAGE' }
];

@Component({
  selector: 'app-athesis-link-editor',
  templateUrl: './athesis-link-editor.component.html',
  styleUrls: ['./athesis-link-editor.component.scss']
})
export class AthesisLinkEditorComponent implements OnDestroy {
  contentMonitorSub: Subscription = null;
  linkId: number = null;
  domainId: number = null;
  selectedLink: ContentEntity = null;
  linkTypes = LinkTypeMapping;
  selectedLinkType: FormMapping;


  constructor(
    private route: ActivatedRoute,
    private store: Store<{}>,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private router: Router,
    private athesisContentMonitorHelperService: AthesisContentMonitorHelperService) {

    this.linkId = Number(route.snapshot.params.linkId);
    this.domainId = Number(route.snapshot.params.domainId);


    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.link) {
      this.selectedLink = state.link;
    } else {
      this.getLinkDetail();
    }

    /*
    this.athesisContentMonitorService.getUserDomain(this.domainId).subscribe(
      response => {
        this.selectedLink = response.contents.filter(content => content.id === this.linkId)[0];
        this.selectedLinkType = this.linkTypes.filter(type => type.enumStringValue === this.selectedLink.type.toString())[0];
      }, error => {
        athesisContentMonitorHelperService.getAllDomains();
      });
      */
  }

  getLinkDetail() {
    this.athesisContentMonitorService.getLinkById(this.linkId).subscribe(link => {
      this.selectedLink = link;
    });
  }

  confirmEditLink(linkName: string, linkUrl: string) {
    const linkToEdit: ContentDto = { domainId: this.domainId, name: linkName, url: linkUrl, type: this.selectedLinkType.value };
    this.athesisContentMonitorService.editLink(linkToEdit, this.selectedLink.id).subscribe(
      res => this.onElementEditSuccessful(),
      err => this.onElementEditUnsuccessful()
    );
  }

  onElementEditUnsuccessful(): void {
    console.log('link edit unsuccessful');
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }
  onElementEditSuccessful(): void {
    this.athesisContentMonitorHelperService.getAllDomains();
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  isSelectedCategoryType(category: FormMapping): boolean {
    return category.enumStringValue === this.selectedLink.type.toString();
  }

  returnToDomainView() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  ngOnDestroy() {
    if (this.contentMonitorSub) {
      this.contentMonitorSub.unsubscribe();
    }
  }
}
