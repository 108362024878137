import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Subject, Observable } from 'rxjs';

export class ModalRef {

  constructor(
    private bsModalRef: BsModalRef,
    public observable: Observable<any>) {
  }

  hide() {
    this.bsModalRef.hide();
  }
}

@Injectable({ providedIn: 'root' })
export class ModalsService {

  constructor(private modalService: BsModalService) {
  }

  show(content: string | TemplateRef<any> | any, template: TemplateRef<any>, context: any, styleClass = 'modal-xl', modalConfig = {}) {
    const confirm = new Subject<any>();

    const bsModalRef = this.modalService.show(content, {
      class: styleClass,
      initialState: {
        template,
        modalConfig,
        item: context,
        onConfirm: (item) => {
          confirm.next(item);
          confirm.complete();
        },
        onCancel: () => {
          confirm.complete();
        }
      }
    });

    return new ModalRef(bsModalRef, confirm.asObservable());
  }
}
