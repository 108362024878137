import {Injectable} from '@angular/core';
import {API_BASE_URL} from '../constants';
import {Observable, BehaviorSubject} from 'rxjs';
import {AthesisAuthService} from './athesis-auth.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DocuwebRegistryResponse} from '../models/docuweb-registry-response.model';
import {Page} from '../models/pagination/page.model';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  private readonly apiVersion2 = 'api/v2/images';
  baseUrl = API_BASE_URL + this.apiVersion2;

  imageListSub = new BehaviorSubject(null);
  scrollBlockSub = new BehaviorSubject(false);

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) {
  }

  getPagedFacesByText(searchText: string, pageNumber = 0, dateGreaterThanEq?: any, dateLessThanEq?: any, sort?: string): Observable<Page<DocuwebRegistryResponse>> {

    let params = new HttpParams()
      .set('fulltext', searchText)
      .set('page', pageNumber.toString())
      .set('size', '10');
    if (dateGreaterThanEq) {
      params = params.set('dateGreaterThanEq', dateGreaterThanEq);
    }
    if (dateLessThanEq) {
      params = params.set('dateLessThanEq', dateLessThanEq);
    }
    if (sort) {
      params = params.set('sort', sort);
    }

    return this.getPagedFaces(params);
  }

  getPagedFacesByRegistryIds(registryIds: string[], pageNumber = 0): Observable<Page<DocuwebRegistryResponse>> {

    const params = new HttpParams()
      .set('registryIds', registryIds.toString())
      .set('page', pageNumber.toString())
      .set('size', '10');

    return this.getPagedFaces(params);
  }

  getImageReferences(imageId: string): Observable<DocuwebRegistryResponse[]> {
    return this.http.get<DocuwebRegistryResponse[]>(`${this.baseUrl}/${imageId}/references`, this.athesisAuthService.httpOptions);
  }

  private getPagedFaces(params: HttpParams) {
    return this.http.get<Page<DocuwebRegistryResponse>>(`${this.baseUrl}/faces`, {
      ...this.athesisAuthService.httpOptions,
      params
    });
  }
}
