import { createAction } from '@ngrx/store';
import { ImageDto, FaceNameAssociationEntity } from '../../models';

export const imageSearch = createAction(
    'IMAGE_SEARCH',
    (payload: ImageDto[]) => ({payload})
);

export const fnaResults = createAction(
    'FNA_RESULTS',
    (payload: FaceNameAssociationEntity[]) => ({payload})
);
