import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from './athesis-content-monitor.service';
import { DomainEntity } from '../models';
import { domains } from '../redux/actions/athesis-content-monitor-actions';
import { getMockTopics, getMockArticles } from './athesis-domain-viewer-mocks';
import { AthesisTopicService } from './athesis-topic.service';

@Injectable({
  providedIn: 'root'
})
export class AthesisContentMonitorHelperService {

  constructor(private store: Store<{}>, private athesisContentMonitorService: AthesisContentMonitorService, private athesisTopicService: AthesisTopicService) { }

  getAllDomains() {
    return this.athesisContentMonitorService.getAllUserDomains().subscribe(
      res => {
        this.onAllDomainsRetrievalSuccessful(res);
      },
      err => {
        this.onAllDomainsRetrievalUnsuccessful();
      }
    );
  }

  onAllDomainsRetrievalSuccessful(response: DomainEntity[]) {
    response.forEach(domain => {
      this.athesisTopicService.getAllTopics();
      domain.articles = getMockArticles(domain);
    });
    this.store.dispatch(domains(response));
  }

  onAllDomainsRetrievalUnsuccessful() {
    console.log('domain retrieval unsuccessful');
  }
}
