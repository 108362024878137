import { Injectable } from '@angular/core';
import {
  API_NEWSPAPERS,
  API_SEMANTIC_SEARCH_ZIPP_ISSUE_AUTOCOMPLETE,
  API_SEMANTIC_SEARCH_ZIPP_ISSUE_PAGES,
  API_SEMANTIC_SEARCH_ZIPP_ARTICLES,
  API_SEMANTIC_SEARCH_ZIPP_ARTICLE_CONTENTS,
  API_SEMANTIC_SEARCH_TOPICS,
  API_SEMANTIC_SEARCH_CONFERRING_SYSTEM,
  API_BASE_URL
} from '../constants';
import { AthesisAuthService } from './athesis-auth.service';
import { KeywordSearchEntity, NewspaperEntity, ZippIssuePageEntity, ZippArticleEntity, GenericSearchResponseEntity, SemanticSearchTopic, ConferringSystemEntity } from '../models';
import {HttpClient, HttpParams} from '@angular/common/http';


export const baseUrl = `${API_BASE_URL}api/v2/semanticsearch`;

export interface SearchByKeywordsRequest {
  conferringSystemsCodes: string[];
  responseType: string;
  topicType?: string;
  newspaperCodes: string[];
  keywords: string[];
  from?: string;
  to?: string;
}
export interface SearchSimilarPolopolyRequest {
  conferringSystemsCodes: string[];
  limit?: number;
  newspaperCodes: string[];
  idPolopoly: string;
  responseType: string;
  dateFrom?: string;
  dateTo?: string;
}
export interface SearchSimilarZipponiRequest {
  conferringSystemsCodes: string[];
  newspaperCodes: string[];
  idFile: string;
  responseType: string;
  from?: string;
  to?: string;
}
@Injectable({
  providedIn: 'root'
})
export class AthesisSemanticSearchServiceService {

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) { }

  getKeywordSearchResults(searchByKeywordsRequest: SearchByKeywordsRequest) {

    let params = new HttpParams()
      .set('conferringSystemCodes', searchByKeywordsRequest.conferringSystemsCodes.toString())
      .set('responseType', searchByKeywordsRequest.responseType)
      .set('newspaperCodes', searchByKeywordsRequest.newspaperCodes.toString())
      .set('keywords', searchByKeywordsRequest.keywords.toString());

    if (searchByKeywordsRequest.topicType) {
      params = params.append('topicType', searchByKeywordsRequest.topicType);
    }
    if (searchByKeywordsRequest.from) {
      params = params.append('from', searchByKeywordsRequest.from);
    }
    if (searchByKeywordsRequest.to) {
      params = params.append('to', searchByKeywordsRequest.to);
    }

    return this.http.get<GenericSearchResponseEntity[]>(
      `${baseUrl}/by-keywords`,
      { ...this.athesisAuthService.httpOptions, params}
    );
  }

  getResultsSimilarPolopoly(searchSimilarPolopolyRequest: SearchSimilarPolopolyRequest) {

    let params = new HttpParams()
      .set('idPolopoly', searchSimilarPolopolyRequest.idPolopoly)
      .set('responseType', searchSimilarPolopolyRequest.responseType)
      .set('newspaperCodes', searchSimilarPolopolyRequest.newspaperCodes.toString())
      .set('conferringSystemCodes', searchSimilarPolopolyRequest.conferringSystemsCodes.toString());

    if (searchSimilarPolopolyRequest.limit) {
      params = params.append('limit', searchSimilarPolopolyRequest.limit.toString());
    }
    if (searchSimilarPolopolyRequest.dateFrom) {
      params = params.append('date-from', searchSimilarPolopolyRequest.dateFrom);
    }
    if (searchSimilarPolopolyRequest.dateTo) {
      params = params.append('date-to', searchSimilarPolopolyRequest.dateTo);
    }

    return this.http.get<GenericSearchResponseEntity[]>(
      `${baseUrl}/similar-polopoly`,
      { ...this.athesisAuthService.httpOptions, params });
  }

  getResultsZipp(searchSimilarZipponiRequest: SearchSimilarZipponiRequest) {

    let params = new HttpParams()
    .set('conferringSystemCodes', searchSimilarZipponiRequest.conferringSystemsCodes.toString())
    .set('responseType', searchSimilarZipponiRequest.responseType)
    .set('newspaperCodes', searchSimilarZipponiRequest.newspaperCodes.toString())
    .set('idFile', searchSimilarZipponiRequest.idFile.toString());

    if (searchSimilarZipponiRequest.from) {
      params = params.append('from', searchSimilarZipponiRequest.from);
    }
    if (searchSimilarZipponiRequest.to) {
      params = params.append('to', searchSimilarZipponiRequest.to);
    }

    return this.http.get<KeywordSearchEntity[]>(
      `${baseUrl}/similar-zipponi`,
      { ...this.athesisAuthService.httpOptions, params }
    );
  }


  getNewspapers() {
    return this.http.get<NewspaperEntity[]>(API_NEWSPAPERS, this.athesisAuthService.httpOptions);
  }

  getZippIssuesAutocomplete(issue: string, newspaperCode: string) {
    return this.http.get<string[]>(API_SEMANTIC_SEARCH_ZIPP_ISSUE_AUTOCOMPLETE(issue, newspaperCode), this.athesisAuthService.httpOptions);
  }

  getZippIssuePages(issue: string, newspaperCode: string) {
    return this.http.get<ZippIssuePageEntity[]>(API_SEMANTIC_SEARCH_ZIPP_ISSUE_PAGES(issue, newspaperCode),
      this.athesisAuthService.httpOptions);
  }

  getZippArticles(nodeId: string) {
    return this.http.get<ZippArticleEntity[]>(API_SEMANTIC_SEARCH_ZIPP_ARTICLES(nodeId),
      this.athesisAuthService.httpOptions);
  }

  getZippArticleContents(fileId: string) {
    return this.http.get<ZippArticleEntity[]>(API_SEMANTIC_SEARCH_ZIPP_ARTICLE_CONTENTS(fileId),
      this.athesisAuthService.httpOptions);
  }

  getAllSemanticSearchTopics() {
    return this.http.get<SemanticSearchTopic>(API_SEMANTIC_SEARCH_TOPICS, this.athesisAuthService.httpOptions);
  }

  getConferringSystems() {
    return this.http.get<ConferringSystemEntity[]>(API_SEMANTIC_SEARCH_CONFERRING_SYSTEM, this.athesisAuthService.httpOptions);
  }
}
