import { TopicEntity, ArticleEntity, Status, DomainEntity } from 'src/app/models';

export function getMockTopics(domainToAdd: DomainEntity) {
  const mock: TopicEntity[] = [
    {
      articles: [{ id: 1, deleted: false, name: 'test', url: 'url test' }],
      dateCreateTopic: '2016-10-15T10:07:56.016Z',
      deleted: false,
      domain: domainToAdd,
      id: 1,
      status: Status[Status.APPROVED],
      title: 'Mock Topic 01',
      topicDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum sit amet justo donec enim diam vulputate ut. Dui sapien eget mi proin sed libero enim. Mi quis hendrerit dolor magna eget est lorem ipsum. Commodo odio aenean sed adipiscing. Eget lorem dolor sed viverra ipsum nunc aliquet bibendum enim. Id volutpat lacus laoreet non curabitur gravida arcu ac tortor. Elit duis tristique sollicitudin nibh sit amet commodo nulla facilisi. Risus pretium quam vulputate dignissim. Elementum nisi quis eleifend quam adipiscing vitae. Non odio euismod lacinia at quis risus sed vulputate odio.'
    },
    {
      articles: [{ id: 2, deleted: false, name: 'test', url: 'url test' }],
      dateCreateTopic: '2015-10-15T10:07:56.016Z',
      deleted: false,
      domain: domainToAdd,
      id: 2,
      status: Status[Status.NEW],
      title: 'Mock Topic 02',
      topicDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum sit amet justo donec enim diam vulputate ut. Dui sapien eget mi proin sed libero enim. Mi quis hendrerit dolor magna eget est lorem ipsum. Commodo odio aenean sed adipiscing. Eget lorem dolor sed viverra ipsum nunc aliquet bibendum enim. Id volutpat lacus laoreet non curabitur gravida arcu ac tortor. Elit duis tristique sollicitudin nibh sit amet commodo nulla facilisi. Risus pretium quam vulputate dignissim. Elementum nisi quis eleifend quam adipiscing vitae. Non odio euismod lacinia at quis risus sed vulputate odio.'
    }
  ];

  return mock;
}

export function getMockArticles(domainToAdd: DomainEntity) {
  const mock: ArticleEntity[] = [
    {
      articleEdited: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum sit amet justo donec enim diam vulputate ut. Dui sapien eget mi proin sed libero enim. Mi quis hendrerit dolor magna eget est lorem ipsum. Commodo odio aenean sed adipiscing. Eget lorem dolor sed viverra ipsum nunc aliquet bibendum enim. Id volutpat lacus laoreet non curabitur gravida arcu ac tortor. Elit duis tristique sollicitudin nibh sit amet commodo nulla facilisi. Risus pretium quam vulputate dignissim. Elementum nisi quis eleifend quam adipiscing vitae. Non odio euismod lacinia at quis risus sed vulputate odio.',
      articleOriginal: 'articleOriginal',
      articles: ['http://example.com/', 'http://gitlab.com/'],
      date: '2019-10-15T10:07:56.016Z',
      domain: domainToAdd,
      id: 1,
      title: 'Mock Article 01',
      topic: getMockTopics(domainToAdd)[0],
      deleted: false
    },
    {
      articleEdited: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum sit amet justo donec enim diam vulputate ut. Dui sapien eget mi proin sed libero enim. Mi quis hendrerit dolor magna eget est lorem ipsum. Commodo odio aenean sed adipiscing. Eget lorem dolor sed viverra ipsum nunc aliquet bibendum enim. Id volutpat lacus laoreet non curabitur gravida arcu ac tortor. Elit duis tristique sollicitudin nibh sit amet commodo nulla facilisi. Risus pretium quam vulputate dignissim. Elementum nisi quis eleifend quam adipiscing vitae. Non odio euismod lacinia at quis risus sed vulputate odio.',
      articleOriginal: 'articleOriginal',
      articles: ['http://example.com/02', 'http://gitlab.com/02'],
      date: '2017-10-15T10:07:56.016Z',
      domain: domainToAdd,
      id: 2,
      title: 'Mock Article 02',
      topic: getMockTopics(domainToAdd)[0],
      deleted: false
    }
  ];

  return mock;
}
