import { Component, OnInit, Input } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { ImagesService } from '../../../services/images.service';
import { DocuwebRegistryResponse } from '../../../models/docuweb-registry-response.model';

@Component({
  selector: 'app-image-article-list',
  templateUrl: './image-article-list.component.html',
  styleUrls: ['./image-article-list.component.scss']
})
export class ImageArticleListComponent implements OnInit {

  @Input() imageReference: string;
  @Input() articleList: DocuwebRegistryResponse[];
  icons = icons;
  isCollapsed = true;
  constructor(private imagesService: ImagesService) { }

  ngOnInit() {
    if (!this.articleList) {
      this.imagesService.getImageReferences(this.imageReference).subscribe(res => {
        this.articleList = res;
      });
    }
  }

}
