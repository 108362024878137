import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/app-guard.service';
import { AdminAppGuardService } from '../services/admin-app-guard.service';
import { NgModule } from '@angular/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TagInputModule } from 'ngx-chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AthesisUsersViewerComponent } from './components/athesis-users-viewer/athesis-users-viewer.component';
import { AthesisUserViewerComponent } from './components/athesis-user-viewer/athesis-user-viewer.component';
import { AthesisUserAdderComponent } from './components/athesis-user-adder/athesis-user-adder.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserFormModalComponent } from './components/user-form-modal/user-form-modal.component';
import { ConfirmModalComponent } from '../shared/components/confirm-modal/confirm-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddDomainUserModalComponent } from './components/add-domain-user-modal/add-domain-user-modal.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { PasswordFormModalComponent } from './components/password-form-modal/password-form-modal.component';

/* { path: 'create-user', component: AthesisBackofficeCreateUserComponent, canActivate: [AuthGuard, AdminAuthGuard] },*/
const routes: Routes = [
    { path: '', component: UserListComponent, canActivate: [AdminAppGuardService] },
    {
        path: 'profile',
        component: UserDetailComponent,
        canActivate: [AuthGuardService],
        data: {
            requiredPermissions: ['fe_own_profile']
        }
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        CKEditorModule,
        PaginationModule,
        FontAwesomeModule,
        InfiniteScrollModule,
        TagInputModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        SharedModule
    ],
    declarations: [
        AthesisUsersViewerComponent,
        AthesisUserViewerComponent,
        AthesisUserAdderComponent,
        UserListComponent,
        UserFormModalComponent,
        AddDomainUserModalComponent,
        UserDetailComponent,
        PasswordFormModalComponent
    ],
    exports: [],
    entryComponents: [
        UserFormModalComponent,
        ConfirmModalComponent,
        AddDomainUserModalComponent,
        PasswordFormModalComponent
    ]
})

export class UserModule {
    static forRoot() {
        return {
            ngModule: UserModule
        };
    }
}
