import { Component, OnInit, OnDestroy } from '@angular/core';
import { PersonEntity, ImageInfoEntity, FaceNameAssociationEntity } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { AthesisFaceNameAssociationService } from 'src/app/services/athesis-face-name-association.service';
import { fnaResults } from 'src/app/redux/actions/athesis-face-name-association-actions';
import { Subscription } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as icons from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-athesis-nfa-image-viewer',
  templateUrl: './athesis-nfa-image-viewer.component.html',
  styleUrls: ['./athesis-nfa-image-viewer.component.scss']
})
export class AthesisNfaImageViewerComponent implements OnDestroy {
  faceNameAssociationSub: Subscription = null;
  imageId: string = null;
  personId: number = null;
  selectedImage: FaceNameAssociationEntity = null;
  selectedImageInfo: ImageInfoEntity = null;
  selectedPerson: PersonEntity = null;
  public Editor = ClassicEditor;
  icons = icons;
  ckeditorConfig = {
    removePlugins: ['image', 'mediaEmbed'],
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'blockQuote', 'insertTable', 'undo', 'redo']
  };

  constructor(
    private route: ActivatedRoute,
    private store: Store<{}>,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private router: Router,
    private fnaService: AthesisFaceNameAssociationService) {
    this.imageId = route.snapshot.params.imageId;

    this.faceNameAssociationSub = store.select('faceNameAssociation').subscribe((faceNameAssociation) => {
      if (faceNameAssociation.fnaResults) {
        this.selectedImage = faceNameAssociation.fnaResults.filter(result => result.reference === this.imageId)[0];
        // We assume the first object will have the highest percentage, and that ordering will be handled on the backend side
        this.selectedPerson = this.selectedImage.possibleNames[0];
        // TODO: Use subscribe
        fnaService.getImageInfo(this.selectedImage.reference).subscribe(
          res => this.onSuccessfulImageInfo(res),
          err => this.onUnsuccessfulImageInfo()
        );
      } else {
        fnaService.getNfaResults().subscribe(
          res => this.onSuccessfulNfaResults(res),
          err => this.onUnsuccessfulNfaResults()
        );
      }
    });
  }

  returnToImagesView() {
    this.router.navigate(['athesis-root', 'name-face-association', 'images']);
  }

  onSuccessfulNfaResults(res: FaceNameAssociationEntity[]): void {
    this.store.dispatch(fnaResults(res));
  }

  onUnsuccessfulNfaResults(): void {
    console.log('nfaResults unsuccessful');
  }

  onUnsuccessfulImageInfo(): void {
    console.log('imageInfo unsuccessful');
  }
  onSuccessfulImageInfo(res: ImageInfoEntity): void {
    this.selectedImageInfo = res;
  }

  ngOnDestroy() {
    if (this.faceNameAssociationSub) {
      this.faceNameAssociationSub.unsubscribe();
    }
  }
}
