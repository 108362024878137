import { Component } from '@angular/core';

@Component({
  selector: 'app-athesis-dashboard',
  templateUrl: './athesis-dashboard.component.html',
  styleUrls: ['./athesis-dashboard.component.scss']
})
export class AthesisDashboardComponent {

  constructor() { }
}
