import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GenericSearchResponseEntity } from '../../../models';
import { ModalsService } from '../../../services/modals/modals.service';
import { FullWidthImageModalComponent } from '../../../shared/components/full-width-image-modal/full-width-image-modal.component';
import { AthesisModalReplaceImageComponent } from '../../../components/athesis-image-similar-search/athesis-modal-replace-image/athesis-modal-replace-image.component';
import { ImageClassificationDetailModalComponent } from '../../../components/athesis-image-similar-search/image-classification-detail-modal/image-classification-detail-modal.component';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { VideosService } from '../../../services/videos.service';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { TreeviewItem } from 'ngx-treeview';

@Component({
    selector: 'app-video-result-list',
    templateUrl: '../../../shared/components/athesis-image-result-list/athesis-image-result-list.component.html',
    styleUrls: ['../../../shared/components/athesis-image-result-list/athesis-image-result-list.component.scss']
})
export class VideoResultListComponent {
    @Input() searchResults: GenericSearchResponseEntity[];
    @Input() showActions;
    @Input() categoryTree;
    @Input() classificationTree: TreeviewItem[];
    @Output() update: EventEmitter<any> = new EventEmitter();

    icons = icons;

    constructor(
      private modalService: ModalsService,
      private videoService: VideosService) { }

    imageFullWidth(image) {
      this.modalService.show(FullWidthImageModalComponent, null, image, 'image-detail').observable.subscribe();
    }

    updateImage(imageId) {
      this.modalService.show(AthesisModalReplaceImageComponent, null,
        {
          imageId,
          currentNode: this.categoryTree.value.nodeId,
          classificationTree: this.classificationTree,
          callback: this.videoService.updateVideo.bind(this.videoService),
          treeItemLabel: 'VIDEO_SIMILAR_SEARCH.VIDEOS'
        },
        'modal-xl').observable
        .subscribe(() => {
          this.update.emit(imageId);
        });
    }

    deleteImage(imageId) {
      const modalConfig = {
        title: 'VIDEO_SIMILAR_SEARCH.CLASSIFICATION_DELETE_MODAL.TITLE',
        message: 'VIDEO_SIMILAR_SEARCH.CLASSIFICATION_DELETE_MODAL.MESSAGE'
      };
      this.modalService.show(ConfirmModalComponent, null, null, 'modal-md', modalConfig).observable
        .subscribe(() => {
            this.videoService.deleteVideo(imageId, this.categoryTree.value.nodeId).subscribe(() => {
                this.update.emit(imageId);
            });
        });
    }

    showClassifications(classifications) {
      const modalConfig = {
        title: 'VIDEO_SIMILAR_SEARCH.CLASSIFICATION_SHOW_MODAL.TITLE',
        message: 'VIDEO_SIMILAR_SEARCH.CLASSIFICATION_SHOW_MODAL.MESSAGE',
        classifications
      };
      this.modalService.show(ImageClassificationDetailModalComponent, null, null, 'modal-md', modalConfig);
    }
}
