import { Injectable } from '@angular/core';
import { AthesisAuthService } from './athesis-auth.service';
import { Store } from '@ngrx/store';
import { UserEntity, RoleEntity } from '../models';
import {
  API_USERS_ALL,
  API_USERS_ALL_ACTIVE,
  API_ROLES_ALL,
  API_AUTH_REGISTER,
  API_ADMIN_ERROR_LOG,
  API_AUTH_CONFIRM_REGISTRATION,
  API_AUTH_RESET_PASSWORD,
  API_AUTH_FORGOT_PASSWORD,
  API_CHANGE_ENABLED,
  API_BASE_URL
} from '../constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Page } from '../models/pagination/page.model';
import { Observable, of } from 'rxjs';
import { AuthUser } from '../users/auth-user.model';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AthesisUserService extends MessageService {

  private _currentUser: AuthUser;

  get currentUser() {
    return Object.assign(new AuthUser(), this._currentUser);
  }

  set currentUser(user: AuthUser) {
    this._currentUser = user;
  }

  constructor(
    private http: HttpClient,
    toastr: ToastrService,
    translateService: TranslateService,
    private athesisAuthService: AthesisAuthService,
    private store: Store<{}>) {
      super(toastr, translateService);
      this.store.select('auth').subscribe((auth) => {
        if (auth && auth.user) {
          // this.isUserAdmin = !!auth.user.roles.find(role => role.id === 1);
          this.currentUser = auth.user;
        }
      });
  }

  getPagedUsers(params: HttpParams): Observable<Page<UserEntity>> {
    return this.http.get<Page<UserEntity>>(API_USERS_ALL, {...this.athesisAuthService.httpOptions, params});
  }

  getAllActiveUsers() {
    return this.http.get<UserEntity[]>(API_USERS_ALL_ACTIVE, this.athesisAuthService.httpOptions);
  }

  getAllRoles() {
    return this.http.get<RoleEntity[]>(API_ROLES_ALL, this.athesisAuthService.httpOptions);
  }

  createUser(userName: string, userSurname: string, userEmail: string, roleId: number) {
    const user = { name: userName, surname: userSurname, email: userEmail, rolesId: [roleId] };
    return this.http.post<UserEntity>(API_AUTH_REGISTER, user, this.athesisAuthService.httpOptions);
  }

  getAdminErrorLog(page: number , size: number) {
    return this.http.get<object[]>(API_ADMIN_ERROR_LOG(page, size), this.athesisAuthService.httpOptions);
  }

  confirmRegistation(token: string, pass: string) {
    return this.http.put<UserEntity>(API_AUTH_CONFIRM_REGISTRATION(token), { password: pass }, this.athesisAuthService.httpOptions);
  }

  resetPassword(token: string, pass: string) {
    return this.http.put<UserEntity>(API_AUTH_RESET_PASSWORD(token), { password: pass }, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('USERS.MESSAGES.RESET_PASSWORD.SUCCESS', 'USERS.MESSAGES.RESET_PASSWORD.ERROR'));
  }

  forgotPassword(userEmail: string) {
    return this.http.post<UserEntity>(API_AUTH_FORGOT_PASSWORD, { email: userEmail }, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('USERS.MESSAGES.RESET_PASSWORD_REQUEST.SUCCESS', 'USERS.MESSAGES.RESET_PASSWORD_REQUEST.ERROR'));
  }
  changeEnabled(id: number, userState: boolean) {
    return this.http.post<UserEntity>(API_CHANGE_ENABLED(id), {enabled: userState }, this.athesisAuthService.httpOptions);
  }

  addUser(user): Observable<any> {
    return this.http.post<UserEntity>(API_AUTH_REGISTER, user, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('USERS.MESSAGES.CREATE.SUCCESS', 'USERS.MESSAGES.CREATE.ERROR'));
  }

  editUser(user): Observable<any> {
    return this.http.put<UserEntity>(`${API_BASE_URL}api/v1/users/${user.id}`, user, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('USERS.MESSAGES.UPDATE.SUCCESS'));
  }

  deleteUser(userId: number): Observable<any> {
    return this.http.delete<UserEntity>(`${API_BASE_URL}api/v1/users/${userId}`, this.athesisAuthService.httpOptions)
    .pipe(this.handleMessages('USERS.MESSAGES.DELETE.SUCCESS'));
  }
}
