import { Injectable } from '@angular/core';
import { API_BASE_URL } from '../constants';
import { AthesisAuthService } from './athesis-auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RegistrySimpleDto } from '../name-face-association/models/registry-simple-dto.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RegistryDto } from '../name-face-association/models/registry-dto.model';
import { FaceForGalleryDto } from '../name-face-association/models/face-for-gallery-dto.model';
import { Page } from '../models/pagination/page.model';

@Injectable({
  providedIn: 'root'
})
export class RegistryService {

  baseUrl = `${API_BASE_URL}api/v1/registry`;

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) { }

  searchRegistry(searchText: string): Observable<RegistrySimpleDto[]> {

    const params = new HttpParams()
      .set('fulltext', searchText);

    return this.http.get<RegistrySimpleDto[]>(`${this.baseUrl}`, { ...this.athesisAuthService.httpOptions, params }).pipe(
      map(res => res.map(reg => Object.assign(new RegistrySimpleDto(), reg)))
    );
  }

  getRegistry(registryId: string): Observable<RegistryDto> {
    return this.http.get<RegistryDto>(`${this.baseUrl}/${registryId}`, this.athesisAuthService.httpOptions);
  }

  getRegistryGallery(registryId: string, pageNumber = 0): Observable<FaceForGalleryDto[]> {
    const params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('size', '10');

    return this.http.get<Page<FaceForGalleryDto>>(`${this.baseUrl}/${registryId}/gallery`, { ...this.athesisAuthService.httpOptions, params }).pipe(
      tap(res => res.content = res.content.map(faceForGallery => Object.assign(new FaceForGalleryDto(), faceForGallery))),
      map(res => res.content)
    );
  }

  updateRegistry(registry: RegistryDto): Observable<RegistryDto> {
    return this.http.put<RegistryDto>(`${this.baseUrl}/${registry.id}`, registry, this.athesisAuthService.httpOptions);
  }
}
