import { Component, OnInit } from '@angular/core';
import { AthesisUserService } from 'src/app/services/athesis-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-athesis-password-change',
  templateUrl: './athesis-password-change.component.html',
  styleUrls: ['./athesis-password-change.component.scss']
})
export class AthesisPasswordChangeComponent implements OnInit {
  resetToken: string = null;
  form: FormGroup;
  submitted = false;
  icons = icons;

  constructor(
    private route: ActivatedRoute,
    private athesisUserService: AthesisUserService,
    private router: Router,
    private fb: FormBuilder) {
    this.resetToken = route.snapshot.params.resetToken;
  }

  get f() {
      return this.form.controls;
  }

  ngOnInit() {
    this.form = this.buildForm(this.fb);
  }

  buildForm(fb: FormBuilder): FormGroup {
    return fb.group({
      password: fb.control(null, [Validators.required, Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[\S]{8,}/gm)]),
      passwordConfirm: fb.control(null, [Validators.required, this.confirmPasswordValidator.bind(this)]),
    });
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.athesisUserService.resetPassword(this.resetToken, this.f.password.value).subscribe(
        res => this.onResetSuccessful(),
        err => this.onResetUnsuccessful()
      );
    }
  }

  onResetUnsuccessful(): void {
    console.log('confirmRegistration error');
    this.router.navigate(['login']);
  }

  onResetSuccessful(): void {
    this.router.navigate(['login']);
  }

  confirmPasswordValidator(control: FormControl): {[s: string]: boolean} {
    if (!!this.form && (control.value !== this.form.value.password)) {
      return {confirmPasswordIsInvalid: true};
    }
    return null;
  }
}
