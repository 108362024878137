import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/app-guard.service';
import { NgModule } from '@angular/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TagInputModule } from 'ngx-chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AthesisNfaImagesViewerComponent } from './components/athesis-nfa-images-viewer/athesis-nfa-images-viewer.component';
import { AthesisNfaImageViewerComponent } from './components/athesis-nfa-image-viewer/athesis-nfa-image-viewer.component';
import { AthesisNameFaceAssociationComponent } from './components/athesis-name-face-association/athesis-name-face-association.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AthesisModalNfaConfirmComponent } from './components/athesis-modal-nfa-confirm/athesis-modal-nfa-confirm.component';
import { AthesisModalNfaCustomNameComponent } from './components/athesis-modal-nfa-custom-name/athesis-modal-nfa-custom-name.component';
import { AthesisModalNfaSimilarImagesComponent } from './components/athesis-modal-nfa-similar-images/athesis-modal-nfa-similar-images.component';
import { NfaCardListComponent } from './components/nfa-card-list/nfa-card-list.component';
import { ClusterDetailComponent } from './components/cluster-detail/cluster-detail.component';
import { RegistryDetailComponent } from './components/registry-detail/registry-detail.component';
import { CharacterAssociationModalComponent } from './components/character-association/character-association-modal/character-association-modal.component';
import { FullWidthImageModalComponent } from '../shared/components/full-width-image-modal/full-width-image-modal.component';
import { CharacterDetailComponent } from './components/character-detail/character-detail.component';
import { RegistryFormModalComponent } from './components/registry-form-modal/registry-form-modal.component';
import { AbstractFaceAssociationComponent } from './components/abstract-face-association/abstract-face-association.component';
import { AthesisClusterFilterComponent } from './components/athesis-cluster-filter/athesis-cluster-filter.component';


const routes: Routes = [
    {
        path: '',
        component: AthesisNameFaceAssociationComponent,
        canActivate: [AuthGuardService],
        data: {
            requiredPermissions: ['fe_people_face_association', 'face_name_association_read']
        }
    },
    {
        path: 'cluster/:clusterId',
        component: ClusterDetailComponent,
        canActivate: [AuthGuardService],
        data: {
            requiredPermissions: ['fe_people_face_association', 'face_name_association_read']
        }
    },
    {
        path: 'registry/:registryId',
        component: RegistryDetailComponent,
        canActivate: [AuthGuardService],
        data: {
            requiredPermissions: ['fe_people_face_association', 'face_name_association_read']
        }
    },
    {
        path: 'registry/:registryId/detail',
        component: CharacterDetailComponent,
        canActivate: [AuthGuardService],
        data: {
            requiredPermissions: ['fe_people_face_association', 'face_name_association_read']
        }
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        CKEditorModule,
        PaginationModule,
        FontAwesomeModule,
        InfiniteScrollModule,
        TagInputModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        AthesisNameFaceAssociationComponent,
        AthesisNfaImagesViewerComponent,
        AthesisNfaImageViewerComponent,
        AthesisModalNfaConfirmComponent,
        AthesisModalNfaCustomNameComponent,
        AthesisModalNfaSimilarImagesComponent,
        NfaCardListComponent,
        ClusterDetailComponent,
        RegistryDetailComponent,
        CharacterAssociationModalComponent,
        CharacterDetailComponent,
        RegistryFormModalComponent,
        AbstractFaceAssociationComponent,
        AthesisClusterFilterComponent
    ],
    exports: [],
    entryComponents: [
        FullWidthImageModalComponent,
        AthesisModalNfaConfirmComponent,
        CharacterAssociationModalComponent,
        RegistryFormModalComponent
    ]
})

export class NameFaceAssociationModule {
    static forRoot() {
        return {
            ngModule: NameFaceAssociationModule
        };
    }
}
