import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-date-ramge-search-form',
    templateUrl: './date-range-search-form.component.html'
})
export class DateRangeSearchFormComponent implements OnInit {

    searchForm: FormGroup;

    @Output() search = new EventEmitter();

    ngOnInit() {
        this.searchForm = new FormGroup({
            dateFrom: new FormControl(null),
            dateTo: new FormControl(null),
        });
    }

    get f() {
        return this.searchForm.controls;
    }

    onSubmit() {
        this.search.emit(this.searchForm.value);
    }

    onReset() {
        this.searchForm.reset();
        this.search.emit(this.searchForm.value);
    }

}
