import { createReducer, on, ReducerManager, ActionReducer } from '@ngrx/store';
import { login, userData } from '../actions/athesis-auth-actions';
import { UserEntity } from 'src/app/models';
import { logout } from '../actions/athesis-meta-actions';

function clearState() {
    return {
        auth: {user: null, isAuthenticated: false},
        faceNameAssociation: {fnaResults: null, imageSearchResults: null},
        contentMonitor: {domains: null},
        userManagement: null
    };
}

export function metaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        if (action.type === 'USER_LOGOUT') {
            state = clearState();
            sessionStorage.clear();
        }

        return reducer(state, action);
    };
  }