import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { AthesisFaceNameAssociationService } from '../../../services/athesis-face-name-association.service';
import { FaceNotAssociatedResponse } from '../../models/face-not-associated.model';
import { Page } from '../../../models/pagination/page.model';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FaceAssociationStatusesResponse } from '../../../models/face-association-statuses-response.model';
import { AbstractFaceAssociationComponent } from '../abstract-face-association/abstract-face-association.component';
import { TabsetComponent } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-athesis-name-face-association',
  templateUrl: './athesis-name-face-association.component.html',
  styleUrls: ['./athesis-name-face-association.component.scss']
})

export class AthesisNameFaceAssociationComponent extends AbstractFaceAssociationComponent implements OnInit, OnDestroy {

  private currentClusterPage = 0;
  private currentRegistryPage = 0;
  icons = icons;
  clusterList: Page<FaceNotAssociatedResponse>;
  registryList: Page<FaceNotAssociatedResponse>;
  showClusterList = true;
  loading = true;
  min = 2;
  max = null;
  protected disableProcessNewImage = true;
  scrollDisabled = false;

  private clusterListSubscription: Subscription;
  private registryListSubscription: Subscription;

  constructor(athesisFaceNameAssociationService: AthesisFaceNameAssociationService, router: Router, route: ActivatedRoute) {
    super(athesisFaceNameAssociationService, router, route);

    this.route.queryParams.subscribe((params: Params) => {
      if (params.activeTab && params.activeTab === 'cluster') {
        this.showClusterList = true;
      }
      if (params.activeTab && params.activeTab === 'registry') {
        this.showClusterList = false;
      }
    });

    this.initImageList();
  }

  onScrollClusters() {
    if (this.currentClusterPage  === this.clusterList.totalPages - 1) {
      return;
    }

    this.currentClusterPage += 1;
    this.athesisFaceNameAssociationService.getPagedClusters(this.currentClusterPage, this.min, this.max).subscribe(res => {
      this.clusterList.content.push(...res.content);

      this.athesisFaceNameAssociationService.clusterListSub.next({list: {...res, content: this.clusterList.content}, scrollY: window.scrollY});
    });
  }

  onScrollRegistry() {
    if (this.currentRegistryPage === this.registryList.totalPages - 1) {
      return;
    }

    this.currentRegistryPage += 1;
    this.athesisFaceNameAssociationService.getPagedRegistry(this.currentRegistryPage).subscribe(res => {
      this.registryList.content.push(...res.content);

      this.athesisFaceNameAssociationService.registryListSub.next({list: {...res, content: this.registryList.content}, scrollY: window.scrollY});
    });
  }

  viewClusterDetail(cluster) {
    this.athesisFaceNameAssociationService.clusterListSub.next({list: this.clusterList, scrollY: window.scrollY});
    this.router.navigate(['athesis-root', 'name-face-association', 'cluster', cluster.clusterId], { state: { cluster } });
  }

  viewRegistryDetail(registry) {
    if (registry.registryId) {
      this.athesisFaceNameAssociationService.registryListSub.next({list: this.registryList, scrollY: window.scrollY});
      this.router.navigate(['athesis-root', 'name-face-association', 'registry', registry.registryId], { state: { registry } });
    }
  }

  processNewImages() {
    this.sectionBlocked = true;
    this.athesisFaceNameAssociationService.createClusters().subscribe(res => {
      this.initImageList();
    });
  }

  verifyStatus(status: FaceAssociationStatusesResponse) {
    this.sectionBlocked = !status.associationEnabled;
    this.disableProcessNewImage = !status.batchEnabled;
  }

  private initImageList() {
    this.initClusterList();
    this.initRegistryList();
  }

  private initClusterList() {
    if (!this.athesisFaceNameAssociationService.clusterListSub.value) {
      this.searchClusters();
    } else {
      this.athesisFaceNameAssociationService.scrollBlockSub.next(true);
      this.clusterListSubscription = this.athesisFaceNameAssociationService.clusterListSub.subscribe(res => {
        this.currentClusterPage = res.list.number ? res.list.number : 1;
        this.clusterList = res.list;
        this.loading = false;
      });
    }
  }

  private initRegistryList() {
    if (!this.athesisFaceNameAssociationService.registryListSub.value) {
      this.athesisFaceNameAssociationService.getPagedRegistry(0).subscribe(res => {
        this.registryList = res;
        this.loading = false;
        this.athesisFaceNameAssociationService.registryListSub.next({list: res, scrollY: window.scrollY});
      });
    } else {
      this.athesisFaceNameAssociationService.scrollBlockSub.next(true);
      this.registryListSubscription = this.athesisFaceNameAssociationService.registryListSub.subscribe(res => {
        this.currentRegistryPage = res.list.number ? res.list.number : 1;
        this.registryList = res.list;
        this.loading = false;
      });
    }
  }

  filterSubmitted(event: any) {
    this.min = event.min;
    this.max = event.max;

    this.searchClusters();
  }

  onTabSelect(event, tabId) {
    this.showClusterList = tabId === 0;
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.clusterListSubscription) {
      this.clusterListSubscription.unsubscribe();
    }
    if (this.registryListSubscription) {
      this.registryListSubscription.unsubscribe();
    }
  }

  private searchClusters(pageNumber: number = 0) {
    this.loading = true;
    this.athesisFaceNameAssociationService.getPagedClusters(pageNumber, this.min, this.max).subscribe(res => {
      this.clusterList = res;
      this.athesisFaceNameAssociationService.clusterListSub.next({list: res, scrollY: window.scrollY});
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
}
