import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RegistrySimpleDto } from '../../models/registry-simple-dto.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-registry-form-modal',
  templateUrl: './registry-form-modal.component.html',
  styleUrls: ['./registry-form-modal.component.scss']
})
export class RegistryFormModalComponent implements OnInit, AfterViewInit {
  selectedCharacter: RegistrySimpleDto;
  asyncSelected: string;
  typeaheadLoading: boolean;
  item: any;
  modalConfig: any;
  onConfirm: Function;
  onCancel: Function;
  form: FormGroup;
  submitted = false;

  public Editor = ClassicEditor;
  icons = icons;
  ckeditorConfig = {
    removePlugins: ['image', 'mediaEmbed'],
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
      'indent', 'outdent', '|', 'blockQuote', 'insertTable', 'undo', 'redo']
  };


  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.buildForm(this.fb);
    if (this.item) {
      this.form.setValue(this.item);
    }
  }

  buildForm(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(null),
      name: fb.control(null, Validators.required),
      surname: fb.control(null, Validators.required),
      specification: fb.control(null),
      wiki: fb.control(null)
    });
  }

  ngAfterViewInit() {  }

  confirm() {
    this.onConfirm(this.form.value);
    this.modalRef.hide();
  }

  cancel() {
    this.onCancel();
    this.modalRef.hide();
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.confirm();
    }
  }
}
