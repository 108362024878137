import { Component, OnInit } from '@angular/core';
import { AthesisTopicService } from '../../../services/athesis-topic.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TopicEntity } from '../../../models';

import * as icons from '@fortawesome/free-solid-svg-icons';
import { AthesisArticleService } from '../../../services/article/athesis-article.service';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { ModalsService } from '../../../services/modals/modals.service';

export interface TopicUpdateRequest {
  title: string;
  topicDescription: string;
  articles: [];
}

@Component({
  selector: 'app-topic-form',
  templateUrl: './topic-form.component.html',
  styleUrls: ['../../athesis-topic-viewer/athesis-topic-viewer.component.scss']
})
export class TopicFormComponent implements OnInit {

  topicId: number;
  topic: TopicEntity;
  icons = icons;
  form: FormGroup;
  submitted = false;
  loading = true;

  constructor(
    private athesisTopicService: AthesisTopicService,
    private athesisArticleService: AthesisArticleService,
    private modalService: ModalsService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    const state = this.router.getCurrentNavigation().extras.state;

    route.params.subscribe(params => {

      if (params.topicId) {
        this.topicId = +params.topicId;
        this.getTopic();
      }
    });
  }

  ngOnInit() {
    this.form = this.buildForm(this.fb);

    if (this.topic) {
      this.form.setValue(this.mapTopic(this.topic));
    }
  }

  buildForm(fb: FormBuilder): FormGroup {
    return fb.group({
      title: fb.control(null, Validators.required),
      topicDescription: fb.control(null, Validators.required),
      articles: fb.control(null)
    });
  }

  saveTopic() {
    if (this.topicId) {
      this.updateTopic();
    } else {
      this.createTopic();
    }
  }
  private updateTopic() {
    this.loading = true;
    this.athesisTopicService.updateTopic(this.topicId, this.getTopicUpdateRequest()).subscribe(res => {
      this.loading = false;
    });
  }
  private createTopic() {
    // TODO: implements method when available
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.saveTopic();
    }
  }

  private mapTopic(topic: TopicEntity) {
    return {
      title: topic.title,
      topicDescription: topic.topicDescription,
      articles: topic.articles
    };
  }

  private getTopicUpdateRequest(): TopicUpdateRequest {
    return {
      title: this.form.value.title,
      topicDescription: this.form.value.topicDescription,
      articles: this.form.value.articles
    };
  }

  private getTopic() {
    this.athesisTopicService.getTopic(this.topicId).subscribe(res => {
      this.topic = res;
      this.form.setValue(this.mapTopic(this.topic));
      this.loading = false;
    });
  }

  removeArticle(articleId, index) {
    if (this.topic.articles.length === 1) {
      console.debug('TopicFormComponent.removeArticle', 'Warning, article list must contain at least one item');
      return;
    }

    const modalConfig = {
      title: 'ARTICLES.REMOVE_MODAL.TITLE',
      message: 'ARTICLES.REMOVE_MODAL.MESSAGE'
    };

    this.modalService.show(ConfirmModalComponent, null, null, 'modal-md', modalConfig).observable.subscribe(() => {
      this.athesisArticleService.deleteArticle(this.topic.id, articleId).subscribe(response => {
        console.debug('AthesisArticleService.deleteArticle', response);
        this.topic.articles.splice(index, 1);
      });
    });
  }
}
