import { Component } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {
  NewspaperEntity,
  GenericSearchResponseEntity,
  ConferringSystemEntity
} from 'src/app/models';

import { AthesisSemanticSearchServiceService, SearchByKeywordsRequest } from 'src/app/services/athesis-semantic-search-service.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { SemanticSearchResponseTypeEnum } from '../../models/SemanticSearchResponseType.enum';



export interface NewspaperEntityCheckbox extends NewspaperEntity {
  checked?: boolean;
}

export interface ConferringSystemEntityCheckbox extends ConferringSystemEntity {
  checked?: boolean;
}

@Component({
  selector: 'app-athesis-free-text-similar-search',
  templateUrl: './athesis-free-text-similar-search.component.html',
  styleUrls: ['./athesis-free-text-similar-search.component.scss']
})
export class AthesisFreeTextSimilarSearchComponent {
  searchResults: GenericSearchResponseEntity[] = null;
  loading: boolean = null;
  public newspapers: NewspaperEntityCheckbox[] = null;
  checkedByDefaultNewspapers: string[] = ['ARE'];
  icons = icons;
  selectedTopic = 'Seleziona Topic';
  semanticSearchTopic: any;
  conferringSystems: ConferringSystemEntityCheckbox[] ;
  keywords: any;
  activeTab = SemanticSearchResponseTypeEnum.TEXT;
  error = false;
  isContentOpen = true;

  constructor(private athesisSemanticSearchServiceService: AthesisSemanticSearchServiceService) {
    this.athesisSemanticSearchServiceService.getNewspapers().pipe(catchError(this.errorHandler)).subscribe(
      res => this.onSuccessfulGetNewspapers(res),
      err => this.onUnsuccessfulGetNewspapers()
    );

    athesisSemanticSearchServiceService.getAllSemanticSearchTopics().pipe(catchError(this.errorHandler)).subscribe(
      res => {
        this.semanticSearchTopic = res;
      }
    );
    athesisSemanticSearchServiceService.getConferringSystems().pipe(catchError(this.errorHandler)).subscribe(
      res => {
        this.conferringSystems = res.map(r => ({...r, checked: false}));
        this.conferringSystems[0].checked = true;
      }
    );

  }
  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || 'server error');
  }

  search(searchType) {
    const checkedNewspapers = this.newspapers.filter(element => element.checked);

    const searchForm: SearchByKeywordsRequest = {
      conferringSystemsCodes: this.conferringSystems.filter(check => check.checked).map(item => item.code),
      keywords: this.keywords.split(' '),
      newspaperCodes: this.newspapers.filter(element => element.checked).map(item => item.code),
      responseType: searchType,
      topicType: this.selectedTopic
    };

    this.activeTab = searchType;
    this.athesisSemanticSearchServiceService
      .getKeywordSearchResults(searchForm)
      .subscribe(
        res => this.onSuccessfulSearchResult(res),
        err => this.onUnsuccessfulSearchResult(),
      );
    this.loading = true;
    this.searchResults = [];
  }

  onUnsuccessfulSearchResult(): void {
    console.log('keyword search unsuccessful');
    this.error = true;
    this.searchResults = null;
    this.loading = false;
  }

  onSuccessfulSearchResult(res: GenericSearchResponseEntity[]): void {
    this.searchResults = res;
    this.loading = false;
  }

  onUnsuccessfulGetNewspapers(): void {
    console.log('getNewspapers unsuccessful');
  }

  onSuccessfulGetNewspapers(res: NewspaperEntity[]): void {
    this.newspapers = res;
    this.newspapers.forEach(element => {
      if (this.checkedByDefaultNewspapers.includes(element.code)) {
        element.checked = true;
      }
    });
  }


  onKey(event: KeyboardEvent) { // with type info
    this.keywords = (event.target as HTMLInputElement).value;
  }
  tabChanged(tabName) {
    if (tabName !== this.activeTab) {
      this.search(tabName);
    }
  }

}
