import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  API_FACE_NAME_ASSOCIATION_SEARCH_IMG_BY_TEXT,
  API_FACE_NAME_ASSOCIATION_SEARCH_IMG_BY_URL,
  API_FACE_NAME_ASSOCIATION_SEARCH_IMG_BY_UPLOAD,
  API_FACE_NAME_ASSOCIATION_IMG_LIST,
  API_FACE_NAME_ASSOCIATION_IMG_DETAIL,
  API_FACE_NAME_ASSOCIATION_IMG_RECOGNITION,
  API_FACE_NAME_ASSOCIATION_IMG_ALLEGED,
  API_FACE_NAME_ASSOCIATION_PEOPLE_AUTOCOMPLETE,
  API_BASE_URL
} from '../constants';
import { AthesisAuthService } from './athesis-auth.service';
import { ImageDto, FaceNameAssociationEntity, ImageInfoEntity } from '../models';
import { Store } from '@ngrx/store';
import { FaceNotAssociatedResponse } from '../name-face-association/models/face-not-associated.model';
import { Page } from '../models/pagination/page.model';
import { tap, switchMap } from 'rxjs/operators';
import { RegistrySimpleDto } from '../name-face-association/models/registry-simple-dto.model';
import { Observable, timer, BehaviorSubject } from 'rxjs';
import { FaceAssociationStatusesResponse } from '../models/face-association-statuses-response.model';

@Injectable({
  providedIn: 'root'
})
export class AthesisFaceNameAssociationService {

  private readonly apiVersion2 = 'api/v2';
  baseUrl = API_BASE_URL + this.apiVersion2;

  checkStatusInterval$: Observable<FaceAssociationStatusesResponse>;

  clusterListSub = new BehaviorSubject(null);
  registryListSub = new BehaviorSubject(null);
  scrollBlockSub = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private athesisAuthService: AthesisAuthService,
    private store: Store<{}>) {
      this.checkStatusInterval$ = timer(20, 30000)
      .pipe(switchMap(int => this.getApiStatus()));
    }

  searchImageByText(text: string) {
    return this.http.get<ImageDto[]>(API_FACE_NAME_ASSOCIATION_SEARCH_IMG_BY_TEXT(text), this.athesisAuthService.httpOptions);
  }

  searchImageByUrl(url: string) {
    return this.http.get<ImageDto[]>(API_FACE_NAME_ASSOCIATION_SEARCH_IMG_BY_URL(url), this.athesisAuthService.httpOptions);
  }

  searchImageByUpload(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<ImageDto[]>(API_FACE_NAME_ASSOCIATION_SEARCH_IMG_BY_UPLOAD, formData, this.athesisAuthService.httpOptionsUpload);
  }

  getImageInfo(reference: string) {
    return this.http.get<ImageInfoEntity>(API_FACE_NAME_ASSOCIATION_IMG_DETAIL(reference), this.athesisAuthService.httpOptions);
  }

  getNfaResults() {
    return this.http.get<FaceNameAssociationEntity[]>(API_FACE_NAME_ASSOCIATION_IMG_LIST, this.athesisAuthService.httpOptions);
  }

  bindImageToName(imageId: string, name: string, specification: string) {
    let url = API_FACE_NAME_ASSOCIATION_IMG_RECOGNITION(name, imageId);
    if (specification) {
      url += `&specification=${specification}`;
    }
    return this.http.put<FaceNameAssociationEntity[]>(url, {},
      this.athesisAuthService.httpOptions);
  }

  getAllegedImages(name: string) {
    return this.http.get<FaceNameAssociationEntity[]>(API_FACE_NAME_ASSOCIATION_IMG_ALLEGED(name, 0, 4),
      this.athesisAuthService.httpOptions);
  }

  getAutocompleteNames(partialName: string) {
    return this.http.get<string[]>(API_FACE_NAME_ASSOCIATION_PEOPLE_AUTOCOMPLETE(partialName),
      this.athesisAuthService.httpOptions);
  }

  getPagedClusters(pageNumber: number, imagesOccurrencesGreaterThanEq?: number, imagesOccurrencesLessThanEq?: number) {
    let params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('size', '10')
      .set('sort', ['_id,asc'].toString());
    if (imagesOccurrencesLessThanEq) {
      params = params.append('imagesOccurrencesLessThanEq', imagesOccurrencesLessThanEq.toString() );
    }
    if (imagesOccurrencesGreaterThanEq) {
      params = params.append('imagesOccurrencesGreaterThanEq', imagesOccurrencesGreaterThanEq.toString() );
    }

    return this.http.get<Page<FaceNotAssociatedResponse>>(`${this.baseUrl}/images/faces/not-associated/clusters`, { ...this.athesisAuthService.httpOptions, params }).pipe(
      tap(res => {
        res.content = res.content.map(cluster => Object.assign(new FaceNotAssociatedResponse(), cluster));
      }),
    );
  }

  getPagedRegistry(pageNumber?: number) {
    const params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('size', '10');

    const mock = '../assets/json/registry.json'; // `${this.baseUrl}/images/faces/not-associated/registry`

    return this.http.get<Page<FaceNotAssociatedResponse>>(`${this.baseUrl}/images/faces/not-associated/registry`, { ...this.athesisAuthService.httpOptions, params }).pipe(
      tap(res => res.content = res.content.map(registry => Object.assign(new FaceNotAssociatedResponse(), registry)))
    );
  }

  getPagedClusterImages(clusterId: number, pageNumber?: number) {
    const params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('size', '10');

    return this.http.get<Page<FaceNotAssociatedResponse>>(`${this.baseUrl}/images/faces/not-associated/clusters/${clusterId}`, { ...this.athesisAuthService.httpOptions, params }).pipe(
      tap(res => res.content = res.content.map(cluster => Object.assign(new FaceNotAssociatedResponse(), cluster)))
    );
  }

  getPagedRegistryImages(registryId: string, pageNumber?: number): Observable<Page<FaceNotAssociatedResponse>> {
    const params = new HttpParams()
      .set('page', pageNumber.toString())
      .set('size', '10');

    return this.http.get<Page<FaceNotAssociatedResponse>>(`${this.baseUrl}/images/faces/not-associated/registry/${registryId}`, { ...this.athesisAuthService.httpOptions, params }).pipe(
      tap(res => res.content = res.content.map(registry => Object.assign(new FaceNotAssociatedResponse(), registry)))
    );
  }

  getClusterDetail(clusterId: number) {
    return this.http.get<Page<FaceNotAssociatedResponse>>(`${this.baseUrl}/images/faces/not-associated/clusters/${clusterId}`, this.athesisAuthService.httpOptions);
  }

  ignoreFace(reference) {
    return this.http.put<FaceNotAssociatedResponse>(`${this.baseUrl}/images/faces/not-associated/${reference}/ignore`, {}, this.athesisAuthService.httpOptions);
  }

  isNotFace(reference) {
    return this.http.delete<FaceNotAssociatedResponse>(`${this.baseUrl}/images/faces/not-associated/${reference}`, this.athesisAuthService.httpOptions);
  }

  assignCharacter(reference, character: RegistrySimpleDto) {
    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/${reference}/assign`,
      character,
      this.athesisAuthService.httpOptions);
  }

  confirmName(reference) {
    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/${reference}/confirm`,
      {},
      this.athesisAuthService.httpOptions);
  }

  confirmRegistryList(registryId: string, references: string[]) {
    const params = new HttpParams()
    .set('registryId', registryId);

    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/confirm/many`,
      references,
      { ...this.athesisAuthService.httpOptions, params });
  }

  ignoreImageList(references: string[]) {

    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/ignore/many`,
      references,
      this.athesisAuthService.httpOptions);
  }

  confirmAllRegistry(registryId: string) {
    const params = new HttpParams()
    .set('registryId', registryId);

    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/confirm/all/by-registry`,
      {},
      { ...this.athesisAuthService.httpOptions, params });
  }

  ignoreAllRegistry(registryId: string) {
    const params = new HttpParams()
    .set('registryId', registryId);

    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/ignore/all/by-registry`,
      {},
      { ...this.athesisAuthService.httpOptions, params });
  }

  ignoreAllCluster(clusterId: number) {
    const params = new HttpParams()
    .set('clusterId', clusterId.toString());

    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/ignore/all/by-cluster`,
      {},
      { ...this.athesisAuthService.httpOptions, params });
  }

  assignAllCluster(clusterId: number, character: RegistrySimpleDto) {
    const params = new HttpParams()
    .set('clusterId', clusterId.toString());

    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/assign/all/by-cluster`,
      character,
      { ...this.athesisAuthService.httpOptions, params });
  }

  assignAllRegistry(registryId: number, character: RegistrySimpleDto) {
    const params = new HttpParams()
    .set('registryId', registryId.toString());

    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/assign/all/by-registry`,
      character,
      { ...this.athesisAuthService.httpOptions, params });
  }

  assignImageList(references: string[], character: RegistrySimpleDto) {

    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/assign/many`,
      { character, references },
      this.athesisAuthService.httpOptions);
  }

  deleteImageList(references: string[]) {
    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/reject/many`,
      references,
      this.athesisAuthService.httpOptions);
  }

  deleteAllCluster(clusterId: number) {
    const params = new HttpParams()
    .set('clusterId', clusterId.toString());

    return this.http.delete<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/all/by-cluster`,
      { ...this.athesisAuthService.httpOptions, params });
  }

  removeAllFaces(registryId: number) {
    const params = new HttpParams()
      .set('registryId', registryId.toString());

    return this.http.put<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/reject/all/by-registry`,
      null,
      { ...this.athesisAuthService.httpOptions, params });
  }

  deleteAllRegistry(registryId: string) {
    const params = new HttpParams()
    .set('registryId', registryId);

    return this.http.delete<FaceNotAssociatedResponse>(
      `${this.baseUrl}/images/faces/not-associated/all/by-registry`,
      { ...this.athesisAuthService.httpOptions, params });
  }

  createClusters() {
    return this.http.post<any>(`${this.baseUrl}/images/faces/not-associated`, {}, this.athesisAuthService.httpOptions);
  }

  /* Verify status */
  getApiStatus(): Observable<FaceAssociationStatusesResponse> {
    return this.http.get<FaceAssociationStatusesResponse>(`${this.baseUrl}/images/faces/not-associated/api-statuses`, this.athesisAuthService.httpOptions);
  }

  checkApiStatus() {
    return this.checkStatusInterval$;
  }
}
