import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {TreeItem, TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {faPlus, faMinus, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import {AthesisTreeService} from '../../services/athesis-tree.service';
import {GenericSearchResponseEntity} from '../../models';
import {AthesisImagesSearchResultsService} from '../../services/athesis-images-search-results.service';
import { ImageTreeHelper } from './image-tree.helper';


const PAGE_SIZE_DEFAULT = 10;

export interface ImageSearchParams {
  nodeId: string;
  dateFrom?: string;
  dateTo?: string;
}
@Component({
  selector: 'app-athesis-image-similar-search',
  templateUrl: './athesis-image-similar-search.component.html'
})
export class AthesisImageSimilarSearchComponent implements OnInit {
  icons = icons;
  items: TreeviewItem[] = null;
  values: number[];
  value: any;
  faPlus = faPlus;
  faMinus = faMinus;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  isContentOpen = true;
  currentPage = 0;
  config = TreeviewConfig.create({
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    hasAllCheckBox: false
  });
  loading = false;
  totalElements: number;
  searchResults: GenericSearchResponseEntity[] = null;
  selectedNode: TreeviewItem;
  isSearchOpen = true;
  @Output() scrollList: EventEmitter<any> = new EventEmitter();

  searchParams: ImageSearchParams;

  constructor(private athesisTreeService: AthesisTreeService, private athesisImagesSearchResultsService: AthesisImagesSearchResultsService) {
  }

  ngOnInit() {
    this.loadTree();
  }

  private loadTree(isUpdate?) {
    this.loading = true;
    this.athesisTreeService.getTree().subscribe(
      items => {
        this.items = ImageTreeHelper.createTree(items, (node) =>
          this.selectedNode && (node.nodeId === this.selectedNode.value.nodeId)
        );
        if (isUpdate) {
          this.selectedNode.value.imagesCount = this.selectedNode.value.imagesCount - 1;
          this.totalElements -= 1;
        }
        this.loading = isUpdate && this.selectedNode.value.imagesCount;
      }
    );
  }

  handleTreeClick(item) {
    this.searchResults = null;
    this.currentPage = 0;
    this.selectedNode = item;
    this.searchParams = {
      nodeId: this.selectedNode.value.nodeId
    };
    this.search();
  }

  onScroll() {
    this.currentPage += 1;
    if ((this.totalElements > (PAGE_SIZE_DEFAULT * this.currentPage)) && !this.isSearchOpen) {
      this.search(this.currentPage, PAGE_SIZE_DEFAULT);
    }
  }

  search(page = 0, size = PAGE_SIZE_DEFAULT): void {
    this.loading = true;
    this.athesisImagesSearchResultsService.searchImages(this.searchParams, page, size)
      .subscribe(items => {
        if (this.searchResults === null) {
          this.searchResults = [];
        }
        if (items.content.length === 0) {
          this.searchResults = [];
        } else {
          this.searchResults.push(...items.content);
        }

        this.totalElements = items.totalElements;
        this.loading = false;
        this.isSearchOpen = false;
      });
  }

  onSearchByDate(searchForm: {dateFrom: string, dateTo: string}) {
    this.searchParams = {
      ...this.searchParams,
      ...searchForm
    };
    this.searchResults = [];
    this.search();
  }

  handleElementUpdate(imageId: string) {
    this.searchResults = this.searchResults.filter(item => item.imageId !== imageId);
    this.loadTree(true);
  }
}
