import { Component, TemplateRef, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements AfterViewInit {

  item: any;
  modalConfig: any;
  template: TemplateRef<any>;
  onConfirm: () => void;
  onCancel: () => void;

  constructor(private modalRef: BsModalRef) {

  }

  ngAfterViewInit() {
  }

  confirm() {
    this.onConfirm();
    this.modalRef.hide();
  }

  cancel() {
    this.onCancel();
    this.modalRef.hide();
  }
}
