import { Component, ViewChild, Input, AfterViewInit, ElementRef } from '@angular/core';
import { RectCoords } from '../../../models/canvas/rect-coords.model';

@Component({
  selector: 'app-face-rec-canvas',
  templateUrl: './face-rec-canvas.component.html',
  styleUrls: ['./face-rec-canvas.component.scss']
})
export class FaceRecCanvasComponent implements AfterViewInit {

  @Input() imgUrl: string;
  @Input() coordsList: RectCoords[];
  @ViewChild('imageCanvas', { static: false }) canvasRef;
  @ViewChild('image', { read: ElementRef, static: false }) imageRef: ElementRef;
  @ViewChild('imageContainer', { static: false }) imageContainerRef;

  ngAfterViewInit() {

  }

  constructor() { }

  onImageLoad() {
    if (this.coordsList && this.coordsList.length > 0) {
      console.log();
      this.drawRectangle(this.coordsList);
    }
  }

  onResize() {
    if (this.coordsList && this.coordsList.length > 0) {
      this.drawRectangle(this.coordsList);
    }
  }

  drawRectangle(coords: RectCoords[]): void {
    const canvas = this.canvasRef.nativeElement;
    canvas.width = this.imageRef.nativeElement.width;
    canvas.height = this.imageRef.nativeElement.height;

    const context = canvas.getContext('2d');

    coords.forEach(coordToResize => {
      const coord = this.getResponsiveCoords(coordToResize);
      // context.fillStyle = 'rgba(225,255,255,0.2)';

      // context.fillRect(coord.left, coord.top, coord.width, coord.height);
      context.beginPath();
      context.setLineDash([6, 2]);
      context.rect(coord.left, coord.top, coord.width, coord.height);
      context.strokeStyle = '#d50000';
      context.lineWidth = 3;
      context.shadowOffsetX = 3;
      context.shadowOffsetY = 3;
      context.shadowColor = '#000000';
      context.shadowBlur = 3;
      context.stroke();
    });
  }

  private getResponsiveCoords(coord) {
    const realWidth = this.imageRef.nativeElement.width / this.imageRef.nativeElement.naturalWidth;
    const realHeight = this.imageRef.nativeElement.height / this.imageRef.nativeElement.naturalHeight;

    return {
      left: Math.round(coord.left * realWidth),
      top: Math.round(coord.top * realHeight),
      width: Math.round(coord.width * realWidth),
      height: Math.round(coord.height * realHeight),
    };
  }
}
