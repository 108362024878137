import { Component, Input, Output, EventEmitter, OnChanges, AfterViewInit, OnInit } from '@angular/core';
import { FaceNameAssociationEntity } from 'src/app/models';
import { AthesisFaceNameAssociationService } from 'src/app/services/athesis-face-name-association.service';
@Component({
  selector: 'app-athesis-modal-nfa-similar-images',
  templateUrl: './athesis-modal-nfa-similar-images.component.html',
  styleUrls: ['./athesis-modal-nfa-similar-images.component.scss']
})
export class AthesisModalNfaSimilarImagesComponent {
  @Output() confirmEvent: EventEmitter<any> = new EventEmitter();
  @Input() name: string = null;
  @Input() similarImages: FaceNameAssociationEntity[];

  chosenImages: FaceNameAssociationEntity[] = [];

  constructor() { }

  onImageClick(image: FaceNameAssociationEntity) {
    if (this.chosenImages.includes(image)) {
      this.chosenImages = this.chosenImages.filter(innerImage => innerImage.reference !== image.reference);
    } else {
      this.chosenImages.push(image);
    }
  }

  onConfirm() {
    this.confirmEvent.emit(this.chosenImages);
  }
}
