import {Component, Input, OnInit} from '@angular/core';
import {GenericSearchResponseEntity} from '../../../models';

@Component({
  selector: 'app-athesis-generic-search-tab-content',
  templateUrl: './athesis-generic-search-tab-content.component.html',
  styleUrls: ['./athesis-generic-search-tab-content.component.scss']
})
export class AthesisGenericSearchTabContentComponent implements OnInit {
  @Input() searchResults: GenericSearchResponseEntity[];
  constructor() { }
  ngOnInit() {
  }

}
