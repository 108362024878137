import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { GenericSearchResponseEntity } from '../../../models';
import { ModalsService } from '../../../services/modals/modals.service';
import { AthesisTreeService } from '../../../services/athesis-tree.service';
import { MessageService } from '../../../services/message.service';
import { FullWidthImageModalComponent } from '../full-width-image-modal/full-width-image-modal.component';
import { AthesisModalReplaceImageComponent } from '../../../components/athesis-image-similar-search/athesis-modal-replace-image/athesis-modal-replace-image.component';
import { AthesisModalConfirmRemoveComponent } from '../../../components/athesis-image-similar-search/athesis-modal-confirm-remove/athesis-modal-confirm-remove.component';
import { ImageClassificationDetailModalComponent } from '../../../components/athesis-image-similar-search/image-classification-detail-modal/image-classification-detail-modal.component';
import { TreeviewItem } from 'ngx-treeview';

@Component({
  selector: 'app-athesis-image-result-list',
  templateUrl: './athesis-image-result-list.component.html',
  styleUrls: ['./athesis-image-result-list.component.scss']
})
export class AthesisImageResultListComponent implements OnInit {
  @Input() searchResults: GenericSearchResponseEntity[];
  @Input() showActions;
  @Input() categoryTree;
  @Input() classificationTree: TreeviewItem[];
  @Output() update: EventEmitter<any> = new EventEmitter();

  icons = icons;

  constructor(
    private modalService: ModalsService,
    private athesisTreeService: AthesisTreeService,
    private messageService: MessageService) { }

  ngOnInit() {}

  imageFullWidth(image) {
    this.modalService.show(FullWidthImageModalComponent, null, image, 'image-detail').observable.subscribe();
  }
  updateImage(imageId) {
    this.modalService.show(AthesisModalReplaceImageComponent, null, {
      imageId,
      currentNode: this.categoryTree.value.nodeId,
      classificationTree: this.classificationTree,
      callback: this.athesisTreeService.updateImage.bind(this.athesisTreeService),
      treeItemLabel: 'IMAGE_SIMILAR_SEARCH.IMAGES'
    }, 'modal-xl').observable
      .subscribe(() => {
        this.update.emit(imageId);
      });
  }

  deleteImage(imageId) {
    const modalConfig = {
      title: 'Rimuovi classificazione',
      message: `Vuoi rimuovere la classificazione da questa immagine?`
    };
    this.modalService.show(AthesisModalConfirmRemoveComponent, null, null, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisTreeService.deleteImage(imageId, this.categoryTree.value.nodeId).subscribe(() => {
          this.update.emit(imageId);
          this.messageService.handleSuccess('IMAGE_SIMILAR_SEARCH.DELETE.SUCCESS');
        }, (error) => {
          if (error.status === 500) {
            this.messageService.handleError(error);
          } else if (error.status === 400) {
            for (const err of error.error.errors) {
              this.messageService.handleErrorWithParams(error, 'IMAGE_SIMILAR_SEARCH.DELETE.ERROR', {codes: err.codes.join(', ')});
            }
          }
        });
      });
  }

  showClassifications(classifications) {
    const modalConfig = {
      title: 'Classificazioni',
      message: `Elenco classificazioni associate all'immagine`,
      classifications
    };
    this.modalService.show(ImageClassificationDetailModalComponent, null, null, 'modal-md', modalConfig);
  }
}
