import { DocuwebRegistryResponse } from './models/docuweb-registry-response.model';

export interface ImageDto {
  associationPercentage: number;
  name: string;
  occurrences: number;
  rifImg: string;
  specification: string;
  imageUrl: string;
}

export interface TagEntity {
  deleted: true;
  id: number;
  tag: string;
}

export interface FileDto {
  domainId: number;
  file: FormData;
  name: string;
}

export interface FileEntity {
  deleted: boolean;
  descr: string;
  domain: object;
  extension: string;
  id: number;
  name: string;
  reference: string;
  uploadDate: string;
}

export interface ContentEntity {
  deleted: boolean;
  domain: object;
  id: number;
  name: string;
  type: LinkType;
  url: string;
}

export interface ContentDto {
  domainId: number;
  name: string;
  type: LinkType;
  url: string;
}

export enum LinkType {
  WEB_SITE,
  WEB_PAGE
}

export enum Status {
  NEW,
  APPROVED,
  REJECTED
}

export interface TopicEntity {
  articles: LinkDto[];
  dateCreateTopic: string;
  deleted: boolean;
  domain: DomainEntity;
  id: number;
  status: string;
  title: string;
  topicDescription?: string;
}

export interface RejectReasonsEntity {
  'id': number;
  'index': number;
  'code': string;
  'message': string;
  'deleted': boolean;
}

export interface SemanticSearchTopic {
  code: string;
  name: string;
}
export interface LinkDto {
  id: number;
  deleted: boolean;
  name: string;
  url: string;
}


export interface TopicDto {
  articles: string[];
  dateCreateTopic?: string;
  domainId: number;
  id?: number;
  title: string;
  topicDescription: string;
}

export interface ArticleDto {
  article: string;
  articles: string[];
  id?: number;
  idDomain: number;
  idTopic?: number;
  name: string;
}

export interface ArticleEntity {
  articleEdited: string;
  articleOriginal: string;
  articles: string[];
  date: string;
  deleted: boolean;
  domain: object;
  id: number;
  title: string;
  topic: TopicEntity;
}

export interface UserDomainEntity {
  canWrite: boolean;
  domain: object;
  id: number;
  owner: boolean;
  user: UserEntity;
}

export interface DomainDto {
  id: number;
  name: string;
  forget: number;
  threshold: number;
  tags: TagEntity[];
}
/* export interface DomainDto {
    id: number;
    name: string;
    tags: TagEntity[];
}
 */
export interface DomainEntity {
  articles: ArticleEntity[];
  contents: ContentEntity[];
  deleted: boolean;
  files: FileEntity[];
  id: number;
  name: string;
  tags: TagEntity[];
  topics: TopicEntity[];
  userDomainEntities: UserDomainEntity[];
}

export interface PermissionEntity {
  code: string;
  deleted: boolean;
  id: number;
}

export interface RoleEntity {
  code: string;
  deleted: boolean;
  id: number;
  permissions: PermissionEntity[];
}

export interface UserEntity {
  deleted: boolean;
  email: string;
  enabled: boolean;
  id: number;
  name: string;
  roles: RoleEntity[];
  surname: string;
}

export interface FormMapping {
  value: number;
  enumStringValue: string;
  label: string;
}

export interface KeywordSearchEntity {
  idFile: string;
  title: string;
  link: string;
  mainImage: string;
}
export interface ZipponiSearchEntity {
  idFile: string;
  title: string;
  link: string;
  mainImage: string;
}

export interface ImageInfoEntity {
  detailLink: string;
  detailNote: string;
}

export interface PersonEntity {
  name: string;
  specification: string;
  associationPercentage: number;
}

export interface GenericSearchResponse {
  totalElements: number;
  content: GenericSearchResponseEntity[];
}
export interface GenericSearchResponseEntity {
  idFile: string;
  imageId: string;
  title: string;
  model: string;
  link: string;
  mainImage: string;
  date: string;
  conferringSystem: ConferringSystemEntity;
  newspaper: NewspaperEntity;
  placeClassificationProbability: number;
  editable: boolean;
  articles?: DocuwebRegistryResponse[];
  classifications: string[];
}

export interface FaceNameAssociationEntity {
  reference: string;
  imageUrl: string;
  name?: string;
  specification?: string;
  imagesOccurrences: number;
  possibleNames: PersonEntity[];
}

export interface NewspaperEntity {
  id?: number;
  name: string;
  code: string;
  docuwebCode: string;
}

export interface ConferringSystemEntity {
  code: string;
  name: string;
}

export interface NotificationEntity {
  createDate: string;
  deleted: boolean;
  id: number;
  referenceId: string;
  referenceType: string;
  sender: UserEntity[];
  subject: string;
  text: string;
}

export interface ZippIssuePageEntity {
  page: number;
  idNodo: string;
}

export interface ZippArticleEntity {
  idFile: number;
  title: string;
  model: string;
}

export interface SelectableTag {
  id: number;
  display: string;
}

export interface GeneratedArticleEntity {
  articleEdited: string[];
  articleOriginal: string[];
  date: Date;
  deleted: boolean;
  domain: DomainEntity;
  id: number;
  sourceArticles: LinkDto[];
  sourceTexts: string[];
  title: string;
}
