import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { authReducer } from './redux/reducers/athesis-auth-reducer';
import { AthesisDashboardComponent } from './components/athesis-dashboard/athesis-dashboard.component';
import { AthesisImageSearchComponent } from './components//athesis-image-search/athesis-image-search.component';
import {
  AthesisPolopolySimilarSearchComponent
} from './components/athesis-polopoly-similar-search/athesis-polopoly-similar-search.component';
import { AthesisZipponeSimilarSearchComponent } from './components/athesis-zippone-similar-search/athesis-zippone-similar-search.component';
import {
  AthesisFreeTextSimilarSearchComponent
} from './components/athesis-free-text-similar-search/athesis-free-text-similar-search.component';
import { AthesisImageSimilarSearchComponent } from './components/athesis-image-similar-search/athesis-image-similar-search.component';
import { AthesisContentMonitorComponent } from './components/athesis-content-monitor/athesis-content-monitor.component';
import { AthesisTextGenerationComponent } from './components/athesis-text-generation/athesis-text-generation.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AthesisLoginComponent } from './components/athesis-login/athesis-login.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { faceNameAssociationReducer } from './redux/reducers/athesis-face-name-association-reducer';
import { contentMonitorReducer } from './redux/reducers/athesis-content-monitor-reducer';
import { AthesisRootComponent } from './components/athesis-root/athesis-root.component';
import { AthesisDomainsViewerComponent } from './components/athesis-domains-viewer/athesis-domains-viewer.component';
import { AthesisDomainCreatorComponent } from './components/athesis-domain-creator/athesis-domain-creator.component';
import { AthesisDomainViewerComponent } from './components/athesis-domain-viewer/athesis-domain-viewer.component';
import { AthesisLinkCreatorComponent } from './components/athesis-link-creator/athesis-link-creator.component';
import { AthesisFileCreatorComponent } from './components/athesis-file-creator/athesis-file-creator.component';
import { AthesisLinkEditorComponent } from './components/athesis-link-editor/athesis-link-editor.component';
import { AthesisTopicViewerComponent } from './components/athesis-topic-viewer/athesis-topic-viewer.component';
import { AthesisArticleViewerComponent } from './components/athesis-article-viewer/athesis-article-viewer.component';
import { AthesisFileViewerComponent } from './components/athesis-file-viewer/athesis-file-viewer.component';
import { AthesisArticleEditorComponent } from './components/athesis-article-editor/athesis-article-editor.component';
import { AthesisFileEditorComponent } from './components/athesis-file-editor/athesis-file-editor.component';
import { userReducer } from './redux/reducers/athesis-user-reducer';
import { AthesisBackofficeCreateUserComponent } from './components/athesis-backoffice-create-user/athesis-backoffice-create-user.component';
import { AthesisBackofficeErrorLogComponent } from './components/athesis-backoffice-error-log/athesis-backoffice-error-log.component';
import { AthesisConfirmRegistrationComponent } from './components/athesis-confirm-registration/athesis-confirm-registration.component';
import { AthesisPasswordRecoveryComponent } from './components/athesis-password-recovery/athesis-password-recovery.component';
import { AthesisPasswordChangeComponent } from './components/athesis-password-change/athesis-password-change.component';
import { metaReducer } from './redux/reducers/athesis-meta-reducer';
import { topicReducer } from './redux/reducers/athesis-topic-reducer';
import { SemanticSearchTopicReducer } from './redux/reducers/athesis-semantic-search-topic-reducer';
import { AthesisDiagnosticsComponent } from './components/athesis-diagnostics/athesis-diagnostics.component';
import { AthesisBadgeNotificationComponent } from './components/athesis-badge-notification/athesis-badge-notification.component';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { AthesisNotificationsPageComponent } from './components/athesis-notifications-page/athesis-notifications-page.component';
import { AthesisGenericSearchResponseComponent } from './components/athesis-generic-search-response/athesis-generic-search-response.component';
import { AthesisDomainViewerTableComponent } from './components/athesis-domain-viewer-table/athesis-domain-viewer-table.component';
import { AthesisTopicViewerTableComponent } from './components/athesis-topic-viewer-table/athesis-topic-viewer-table.component';
import { TagSearchComponent } from './components/tag-search/tag-search.component';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AthesisNotFoundPageComponent } from './components/athesis-not-found-page/athesis-not-found-page.component';

import { NameFaceAssociationModule } from './name-face-association/name-face-association.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { AthesisGenericSearchTabContentComponent } from './components/athesis-generic-search-response/athesis-generic-search-tab-content/athesis-generic-search-tab-content.component';
import { BsModalService } from 'ngx-bootstrap';
import { UserModule } from './users/user.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AthesisModalReplaceImageComponent } from './components/athesis-image-similar-search/athesis-modal-replace-image/athesis-modal-replace-image.component';
import { AthesisModalConfirmRemoveComponent } from './components/athesis-image-similar-search/athesis-modal-confirm-remove/athesis-modal-confirm-remove.component';
import { TopicListComponent } from './components/topics/topic-list/topic-list.component';
import { RejectTopicModalComponent } from './components/topics/reject-topic-modal/reject-topic-modal.component';
import { TopicFormComponent } from './components/topics/topic-form/topic-form.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { ImageClassificationDetailModalComponent } from './components/athesis-image-similar-search/image-classification-detail-modal/image-classification-detail-modal.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { SemanticSearchModule } from './semantic-search/semantic-search.module';

export const metaReducers: MetaReducer<any>[] = [metaReducer];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AthesisDashboardComponent,
    AthesisImageSearchComponent,
    AthesisPolopolySimilarSearchComponent,
    AthesisZipponeSimilarSearchComponent,
    AthesisFreeTextSimilarSearchComponent,
    AthesisImageSimilarSearchComponent,
    AthesisContentMonitorComponent,
    AthesisTextGenerationComponent,
    AthesisLoginComponent,
    AthesisRootComponent,
    AthesisDomainsViewerComponent,
    AthesisDomainCreatorComponent,
    AthesisDomainViewerComponent,
    AthesisLinkCreatorComponent,
    AthesisFileCreatorComponent,
    AthesisLinkEditorComponent,
    AthesisTopicViewerComponent,
    AthesisArticleViewerComponent,
    AthesisFileViewerComponent,
    AthesisArticleEditorComponent,
    AthesisFileEditorComponent,
    AthesisBackofficeCreateUserComponent,
    AthesisBackofficeErrorLogComponent,
    AthesisConfirmRegistrationComponent,
    AthesisPasswordRecoveryComponent,
    AthesisPasswordChangeComponent,
    AthesisDiagnosticsComponent,
    AthesisBadgeNotificationComponent,
    JwPaginationComponent,
    AthesisNotificationsPageComponent,
    AthesisGenericSearchResponseComponent,
    AthesisDomainViewerTableComponent,
    AthesisTopicViewerTableComponent,
    TagSearchComponent,
    AthesisNotFoundPageComponent,
    AthesisGenericSearchTabContentComponent,
    AthesisModalReplaceImageComponent,
    AthesisModalConfirmRemoveComponent,
    TopicListComponent,
    RejectTopicModalComponent,
    TopicFormComponent,
    ImageClassificationDetailModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    CKEditorModule,
    PaginationModule.forRoot(),
    CommonModule,
    AppRoutingModule,
    TagInputModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    SharedModule,
    StoreModule.forRoot({
      auth: authReducer,
      faceNameAssociation: faceNameAssociationReducer,
      contentMonitor: contentMonitorReducer,
      userManagement: userReducer,
      topicManagement: topicReducer,
      SemanticSearchTopicManagement: SemanticSearchTopicReducer,
    }, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    HttpClientModule,
    NameFaceAssociationModule.forRoot(),
    UserModule.forRoot(),
    BsDropdownModule.forRoot(),
    SemanticSearchModule
  ],
  entryComponents: [
    AthesisModalReplaceImageComponent,
    AthesisModalConfirmRemoveComponent,
    RejectTopicModalComponent,
    ImageClassificationDetailModalComponent
  ],
  providers: [
    BsModalService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
