import { Injectable } from '@angular/core';
import { AthesisAuthService } from './athesis-auth.service';
import { Store } from '@ngrx/store';
import { TopicEntity, RejectReasonsEntity, TopicDto } from '../models';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  API_ALL_TOPICS,
  API_TOPIC_APPROVE,
  API_TOPIC_REJECT,
  API_TOPIC_REJECT_REASONS,
  API_CONTENT_MONITOR_TOPICS_BY_DOMAIN,
  API_CONTENT_MONITOR_UPDATE_TOPIC,
  API_DELETE_TOPIC,
  API_BASE_URL,
  API_VERSION_URL
} from '../constants';
import { Page } from '../models/pagination/page.model';
import { tap, map, switchMap } from 'rxjs/operators';
import { AthesisArticleService } from './article/athesis-article.service';
import { TopicUpdateRequest } from '../components/topics/topic-form/topic-form.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from './message.service';

const API_GET_TOPIC_LIST_BY_DOMAIN = (domainId: number) =>
  API_BASE_URL + API_VERSION_URL + `/domains/${domainId}/topics`;

@Injectable({
  providedIn: 'root'
})
export class AthesisTopicService extends MessageService {

  constructor(
    private http: HttpClient,
    private athesisAuthService: AthesisAuthService,
    private athesisArticleService: AthesisArticleService,
    toastr: ToastrService,
    translateService: TranslateService,
    private store: Store<{}>) {
      super(toastr, translateService);
    }

  getAllTopics() {
    return this.http.get<TopicEntity[]>(API_ALL_TOPICS, this.athesisAuthService.httpOptions);
  }

  getPagedTopics(page: number) {
    return this.http.get<TopicEntity[]>(API_ALL_TOPICS + '?sort=id,desc&size=15&page=' + page, this.athesisAuthService.httpOptions);
  }

  approveTopic(topicId: number) {
    return this.http.put<TopicEntity[]>(API_TOPIC_APPROVE(topicId), {}, this.athesisAuthService.httpOptions);
  }

  rejectTopic(topicId: number, reason) {
    return this.http.put<TopicEntity[]>(API_TOPIC_REJECT(topicId), reason, this.athesisAuthService.httpOptions);
  }

  getRejectTopicReasons() {
    return this.http.get<RejectReasonsEntity[]>(API_TOPIC_REJECT_REASONS, this.athesisAuthService.httpOptions);
  }

  deleteTopic(topicId: number) {
    return this.http.delete<TopicEntity[]>(API_DELETE_TOPIC(topicId), this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('TOPICS.MESSAGES.DELETE.SUCCESS', 'TOPICS.MESSAGES.DELETE.ERROR'));
  }

  getTopicByDomain(domainId: number) {
    return this.http.get<TopicEntity[]>(API_CONTENT_MONITOR_TOPICS_BY_DOMAIN(domainId), this.athesisAuthService.httpOptions);
  }

  getTopics(params?: HttpParams) {
    return this.http.get<Page<TopicEntity>>(API_ALL_TOPICS, { ...this.athesisAuthService.httpOptions, params });
  }

  getTopicsWithArticles(domainId: number, params?: HttpParams) {
    return this.http.get<TopicEntity[]>(API_GET_TOPIC_LIST_BY_DOMAIN(domainId), { ...this.athesisAuthService.httpOptions, params });
  }

  updateTopic(topicId: number, topicUpdateRequest: TopicUpdateRequest) {
    return this.http.put<TopicEntity>(API_CONTENT_MONITOR_UPDATE_TOPIC(topicId), topicUpdateRequest, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('TOPICS.MESSAGES.UPDATE.SUCCESS', 'TOPICS.MESSAGES.UPDATE.ERROR'));
  }

  getTopic(topicId: number) {
    return this.http.get<TopicEntity>(`${API_BASE_URL}${API_VERSION_URL}/topics/${topicId}`, this.athesisAuthService.httpOptions);
  }
}
