import { Component } from '@angular/core';
// import { DomainEntity, LinkType, ContentEntity, ContentDto, FormMapping,
//          TopicEntity, ArticleEntity, FileEntity, Status, ArticleDto, UserEntity, UserDomainEntity } from 'src/app/models';
// import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
// import { Store } from '@ngrx/store';
// import { domains } from 'src/app/redux/actions/athesis-content-monitor-actions';
// import * as icons from '@fortawesome/free-solid-svg-icons';
// import { Output, EventEmitter } from '@angular/core';
// import { AthesisRootContents } from '../athesis-root/athesis-root.component';
// import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
// import { LinkTypeMapping } from '../athesis-link-creator/athesis-link-creator.component';
// import { Observable, Subscription } from 'rxjs';

// export enum ViewStates {
//   VIEWING_DOMAINS,
//   CREATING_DOMAIN,
//   DELETING_DOMAIN,
//   VIEWING_DOMAIN,
//   ADDING_LINK,
//   ADDING_FILE,
//   DELETING_LINK,
//   DELETING_ARTICLE,
//   DELETING_FILE,
//   VIEWING_TOPIC,
//   VIEWING_ARTICLE,
//   VIEWING_FILE,
//   EDITING_LINK,
//   EDITING_ARTICLE,
//   EDITING_FILE,
//   VIEWING_GUESTS,
//   VIEWING_GUEST
// }


@Component({
  selector: 'app-athesis-content-monitor',
  templateUrl: './athesis-content-monitor.component.html',
  styleUrls: ['./athesis-content-monitor.component.scss']
})
export class AthesisContentMonitorComponent {
  constructor() {}
}
