import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { RoleEntity, UserEntity } from '../../../models';
import { AthesisUserService } from '../../../services/athesis-user.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-user-form-modal',
  templateUrl: './user-form-modal.component.html',
  styleUrls: ['./user-form-modal.component.scss']
})
export class UserFormModalComponent implements OnInit {
  item: any;
  modalConfig: any;
  onConfirm: Function;
  onCancel: Function;
  form: FormGroup;
  submitted = false;
  roleList: RoleEntity[];
  selectedRoles: RoleEntity[] = [];
  selectedValue: RoleEntity = null;
  authSub: Subscription;
  isUserAdmin = false;
  currentUser: UserEntity;

  icons = icons;

  constructor(
    private store: Store<{}>,
    private modalRef: BsModalRef,
    private userService: AthesisUserService,
    private fb: FormBuilder) {

      this.currentUser = this.userService.currentUser;
      this.isUserAdmin = !!this.currentUser.roles.find(role => role.id === 1);
  }

  get isCurrentUser() {
    return !!this.currentUser && !!this.item && this.currentUser.id === this.item.id;
  }

  ngOnInit() {
    this.form = this.buildForm(this.fb);
    if (this.item) {
      this.form.setValue(this.mapUser(this.item));
    }

    this.userService.getAllRoles().subscribe(res => {
      this.roleList = res;

      if (this.item) {
        this.item.roles.forEach(element => {
          this.addRole(element);
        });
      }
    });
  }

  buildForm(fb: FormBuilder): FormGroup {
    return fb.group({
      id: fb.control(null),
      name: fb.control(null, Validators.required),
      surname: fb.control(null, Validators.required),
      email: fb.control(null, [Validators.required, Validators.email]),
      rolesId: fb.control(null)
    });
  }

  get f() {
    return this.form.controls;
  }

  confirm() {
    this.onConfirm(this.form.value);
    this.modalRef.hide();
  }

  addRole(role) {
    if (role) {
      this.roleList = this.roleList.filter(item => item.id !== role.id);
      this.selectedRoles.push(role);
      this.form.value.rolesId = this.selectedRoles.map(item => item.id);
      this.selectedValue = null;
    }
  }

  removeRole(role) {
    this.roleList.push(role);
    this.selectedRoles = this.selectedRoles.filter(item => item.id !== role.id);
    this.form.patchValue({
      rolesId: this.selectedRoles.map(item => item.id)
    });
  }

  cancel() {
    this.onCancel();
    this.modalRef.hide();
  }

  submit() {
    this.submitted = true;
    if (this.form.valid && (this.form.value.rolesId.length > 0)) {
      this.confirm();
    }
  }

  private mapUser(user) {
    return {
      id: user.id,
      name: user.name,
      surname: user.surname,
      email: user.email,
      rolesId: user.roles.map(item => item.id)
    };
  }
}
