import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { UserEntity } from 'src/app/models';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { logout } from 'src/app/redux/actions/athesis-meta-actions';
import { Router } from '@angular/router';
import { AthesisNotificationService } from 'src/app/services/athesis-notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-athesis-root',
  templateUrl: './athesis-root.component.html',
  styleUrls: ['./athesis-root.component.scss']
})
export class AthesisRootComponent {
  /* faCaretDown = faCaretDown; */
  userLabel: string = null;
  icons = icons;
  isUserAdmin = false;
  notification;
  iconToChange;
  isMobile = window.innerWidth < 768;

  @ViewChild('navBarToggler', {static: false}) navBarToggler: ElementRef;
  @ViewChild('navBarCollapse', {static: false}) navBarCollapse: ElementRef;

  constructor(
    private store: Store<{ user: UserEntity }>,
    private router: Router,
    private notificationService: AthesisNotificationService,
    private translateService: TranslateService) {
    store.select('auth').subscribe((auth) => {
      if (auth && auth.user) {
        this.userLabel = `${auth.user.name} ${auth.user.surname}`;
        this.isUserAdmin = auth.user.roles.filter(role => role.id === 1).length > 0;
      }
    });
  }

  logout() {
    this.router.navigate(['login']);
    this.store.dispatch(logout());
  }

  userNotification(userId: number) {
    this.notificationService.notificationCounter(userId).subscribe(response => {
      this.notification = response;
      console.log(response);
      console.log(userId);
    });
  }

  rotateIcon() {
    if (this.iconToChange.change < 0) {
      this.iconToChange.icon = 'icons.faCaretDown';
    } else {
      this.iconToChange.icon = 'icons.faCaretRight';
    }
  }

  viewProfile() {
    this.router.navigate(['athesis-root', 'users', 'profile']);
  }

  closeNavBar() {
    if (this.isMobile) {
      this.navBarToggler.nativeElement.classList.add('collapsed');
      this.navBarCollapse.nativeElement.classList.remove('show');
    }
  }
}
