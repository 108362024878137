import { createReducer, on } from '@ngrx/store';
import { SemanticSearchTopic } from 'src/app/models';
import { users } from '../actions/athesis-user-actions';
import { semanticsearchtopics } from '../actions/athesis-semantic-search-topic';

export interface State {
  semanticsearchtopics: SemanticSearchTopic[];
}

export const initialState: State = {
  semanticsearchtopics: null
};

function mergeState(state, newState) {
  return {
    ...state, ...newState
  };
}

export const SemanticSearchTopicReducer = createReducer(initialState,
  on(semanticsearchtopics, (state, { payload }) => mergeState(state, { semanticsearchtopics: payload })),
);
