import { Component, OnInit } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { FaceNotAssociatedResponse } from '../../models/face-not-associated.model';
import { Page } from '../../../models/pagination/page.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalsService } from '../../../services/modals/modals.service';
import { AthesisFaceNameAssociationService } from '../../../services/athesis-face-name-association.service';
import { CharacterAssociationModalComponent } from '../character-association/character-association-modal/character-association-modal.component';
import { AthesisModalNfaConfirmComponent } from '../athesis-modal-nfa-confirm/athesis-modal-nfa-confirm.component';
import { RegistrySimpleDto } from '../../models/registry-simple-dto.model';
import { FullWidthImageModalComponent } from '../../../shared/components/full-width-image-modal/full-width-image-modal.component';
import { AbstractFaceAssociationComponent } from '../abstract-face-association/abstract-face-association.component';
import { ImagesService } from '../../../services/images.service';

export class RegistryShortInfo {
  registryId: string;
  fullName: string;
  specification: string;
  imagesOccurrences: number;
}

@Component({
  selector: 'app-registry-detail',
  templateUrl: './registry-detail.component.html',
  styleUrls: ['../cluster-detail/cluster-detail.component.scss']
})
export class RegistryDetailComponent extends AbstractFaceAssociationComponent {

  private registryId;
  registry: RegistryShortInfo;
  imageList: Page<FaceNotAssociatedResponse>;
  icons = icons;
  currentPage = 0;
  // loading = true;
  selectAll = false;

  get anySelected() {
    return this.imageList && this.imageList.content.some(image => image.selected);
  }

  get loading() {
    return !this.imageList || !this.imageList.content.every(image => image.articles != null);
  }

  constructor(
    router: Router,
    route: ActivatedRoute,
    athesisFaceNameAssociationService: AthesisFaceNameAssociationService,
    private imagesService: ImagesService,
    private modalService: ModalsService) {
      super(athesisFaceNameAssociationService, router, route);

      this.loadRegistryInfo();
  }

  private loadRegistryInfo() {
    const state = this.router.getCurrentNavigation().extras.state;

    this.route.paramMap.subscribe(response => {
      this.registryId = response.get('registryId');

      this.athesisFaceNameAssociationService.getPagedRegistryImages(this.registryId, this.currentPage).subscribe(res => {
        this.imageList = res;

        this.imageList.content.map(image => {
          this.imagesService.getImageReferences(image.imageId).subscribe(ref => {
            image.articles = ref;
          });
        });

        this.initRegistry(res);
      });
    });
  }

  confirmName(image, index) {
    const modalConfig = {
      title: 'Conferma nome',
      message: `Confermare ${this.registry.fullName} nell\'immagine selezionata?`
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, image, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.confirmName(image.reference).subscribe(res => {
          this.imageList.content.splice(index, 1);
          this.registry.imagesOccurrences -= 1;
        });
      });
  }

  assignName(image, index) {
    this.modalService.show(CharacterAssociationModalComponent, null, image, 'modal-xl').observable
      .subscribe((character) => {
        this.athesisFaceNameAssociationService.assignCharacter(image.reference, character).subscribe(res => {
          this.imageList.content.splice(index, 1);
          this.registry.imagesOccurrences -= 1;
        });
      });
  }

  ignoreFace(image, index) {
    const modalConfig = {
      title: 'Ignora volto',
      message: 'Confermare operazione Ignora volto per l\'immagine selezionata?'
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, image, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.ignoreFace(image.reference).subscribe(res => {
          this.imageList.content.splice(index, 1);
          this.registry.imagesOccurrences -= 1;
        });
      });
  }

  isNotFace(image, index) {
    const modalConfig = {
      title: 'Rimuovi volto',
      message: 'Confermare operazione Rimuovi volto per l\'immagine selezionata?'
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, image, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.isNotFace(image.reference).subscribe(res => {
          this.imageList.content.splice(index, 1);
          this.registry.imagesOccurrences -= 1;
        });
      });
  }

  confirmSelectedFaces() {
    if (this.selectAll) {
      return this.confirmAllFaces();
    }

    const selectedImages = this.imageList.content.filter(image => image.selected).map(image => image.reference);

    const modalConfig = {
      title: 'Conferma nome',
      message: `Confermare ${this.registry.fullName} per le ${selectedImages.length} immagini selezionate?`
    };

    if (selectedImages.length === 1) {
      modalConfig.message = `Confermare ${this.registry.fullName} per l\'immagine selezionata?`;
    }

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.confirmRegistryList(this.registryId, selectedImages).subscribe(res => {
          this.imageList.content = this.imageList.content.filter(image => !image.selected);
          this.registry.imagesOccurrences -= selectedImages.length;
        });
      });
  }

  private confirmAllFaces() {
    const modalConfig = {
      title: 'Conferma nome',
      message: `Confermare ${this.registry.fullName} per tutte le ${this.registry.imagesOccurrences} immagini?`
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.confirmAllRegistry(this.registryId).subscribe(res => {
          this.imageList.content = [];
          this.registry.imagesOccurrences = 0;
        });
      });
  }

  ignoreSelectedFaces() {

    if (this.selectAll) {
      return this.ignoreAllFaces();
    }

    const selectedImages = this.imageList.content.filter(image => image.selected).map(image => image.reference);

    const modalConfig = {
      title: 'Ignora volto',
      message: `Confermare operazione Ignora volto per le ${selectedImages.length} immagini selezionate?`
    };

    if (selectedImages.length === 1) {
      modalConfig.message = `Confermare operazione Ignora volto per l\'immagine selezionata?`;
    }

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
    .subscribe(() => {
      this.athesisFaceNameAssociationService.ignoreImageList(selectedImages).subscribe(res => {
        this.imageList.content = this.imageList.content.filter(image => !image.selected);
        this.registry.imagesOccurrences -= selectedImages.length;
      });
    });
  }

  private ignoreAllFaces() {
    const modalConfig = {
      title: 'Ignora volto',
      message: `Confermare operazione Ignora volto per tutte le ${this.registry.imagesOccurrences} immagini?`
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.ignoreAllRegistry(this.registryId).subscribe(res => {
          this.imageList.content = [];
          this.registry.imagesOccurrences = 0;
        });
      });
  }

  deleteSelectedFaces() {
    if (this.selectAll) {
      return this.deleteAllFaces();
    }

    const selectedImages = this.imageList.content.filter(image => image.selected).map(image => image.reference);

    const modalConfig = {
      title: 'Ignora volto',
      message: `Confermare operazione Cancella volto per le ${selectedImages.length} immagini selezionate?`
    };

    if (selectedImages.length === 1) {
      modalConfig.message = `Confermare operazione Cancella volto per l\'immagine selezionata?`;
    }

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
      .subscribe((character) => {
        this.athesisFaceNameAssociationService.deleteImageList(selectedImages).subscribe(res => {
          this.imageList.content = this.imageList.content.filter(image => !image.selected);
          this.registry.imagesOccurrences -= selectedImages.length;
        });
      });
  }

  private deleteAllFaces() {
    const modalConfig = {
      title: 'Cancella volto',
      message: `Confermare operazione Cancella volto per tutte le ${this.registry.imagesOccurrences} immagini?`
    };

    this.modalService.show(AthesisModalNfaConfirmComponent, null, null, 'modal-md', modalConfig).observable
      .subscribe(() => {
        this.athesisFaceNameAssociationService.removeAllFaces(this.registryId).subscribe(res => {
          this.imageList.content = [];
          this.registry.imagesOccurrences = 0;
        });
      });
  }

  assignSelectedFaces() {

    if (this.selectAll) {
      return this.assignAllFaces();
    }

    const selectedImages = this.imageList.content.filter(image => image.selected).map(image => image.reference);

    this.modalService.show(CharacterAssociationModalComponent, null, null, 'modal-xl').observable
      .subscribe((character) => {
        this.athesisFaceNameAssociationService.assignImageList(selectedImages, character).subscribe(res => {
          this.imageList.content = this.imageList.content.filter(image => !image.selected);
          this.registry.imagesOccurrences -= selectedImages.length;
        });
      });
  }

  private assignAllFaces() {
    this.modalService.show(CharacterAssociationModalComponent, null, null, 'modal-xl').observable
      .subscribe((character) => {
        this.athesisFaceNameAssociationService.assignAllRegistry(this.registryId, character).subscribe(res => {
          this.imageList.content = [];
          this.registry.imagesOccurrences = 0;
        });
      });
  }

  onScroll() {

    if (this.currentPage  === this.imageList.totalPages - 1) {
      return;
    }

    this.currentPage += 1;
    this.athesisFaceNameAssociationService.getPagedRegistryImages(this.registryId, this.currentPage).subscribe(res => {
      res.content.map(image => {
        this.imagesService.getImageReferences(image.imageId).subscribe(ref => {
          image.selected = this.selectAll;
          image.articles = ref;
          this.imageList.content.push(image);
        });
      });
    });
  }

  toggleSelectOne(index: number) {
    if (this.selectAll && this.imageList.content[index].selected) {
      this.selectAll = false;
    }
  }


  toggleSelectAll() {
    this.imageList.content.map(image => image.selected = this.selectAll);
  }

  private initRegistry(source: Page<FaceNotAssociatedResponse>) {
    const possibleName =
    source.content.filter((name, index) => index === 0)
    .map(name => name.possibleNames)
    .reduce((item, acc) => acc, [])
    .filter((name, index) => index === 0)
    .reduce((item, acc) => Object.assign(new RegistrySimpleDto(), acc), new RegistrySimpleDto());

    this.registry = {
      registryId: this.registryId,
      fullName: possibleName.fullName,
      specification: possibleName.specification,
      imagesOccurrences: source.totalElements };
    }

    imageFullWidth(image) {
      this.modalService.show(FullWidthImageModalComponent, null, image, 'image-detail modal-xl').observable
        .subscribe(() => { });
    }

    goToCharacterDetail() {
      this.router.navigate(['./detail'], { relativeTo: this.route, state: { image: this.imageList.content[0] } });
    }

    backToList() {
      this.router.navigate(['../../'], {relativeTo: this.route, queryParams: { activeTab: 'registry'}});
    }
}
