import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DiagnosticService } from 'src/app/services/diagnostic.service';

@Component({
  selector: 'app-athesis-diagnostics',
  templateUrl: './athesis-diagnostics.component.html',
  styleUrls: ['./athesis-diagnostics.component.scss']
})
export class AthesisDiagnosticsComponent implements OnInit {
  subTables;
  configHeader;
  counter;
  loading: boolean = null;
  loadingCounter: boolean = null;
  archivedCount;
  errorsCount;
  notArchivedCount;
  recordedCount;
  title;

  constructor(private diagnosticService: DiagnosticService) {
    this.getDiagnosticCounterData();
    this.getArchivedData();
  }
  getDiagnosticCounterData() {
    this.diagnosticService.getDiagnosticCounter()
      .subscribe(response => {
        this.counter = response['counter'];
        this.archivedCount = response['archivedCount'];
        this.errorsCount = response['errorsCount'];
        this.notArchivedCount = response['notArchivedCount'];
        this.recordedCount = response['recordedCount'];
        console.log(response);
        this.loadingCounter = false;
      });
    this.loadingCounter = true;
  }

  getArchivedData() {
    this.diagnosticService.getArchivedDiagnosticData()
      .subscribe(response => {
        this.subTables = response['subTables'];
        this.configHeader = response['configHeader'];
        this.loading = false;
        this.title = 'Archiviati';
      });
    this.loading = true;
    this.subTables = null;
  }
  getErrorsData() {
    this.diagnosticService.getErrorsDiagnosticData()
      .subscribe(response => {
        this.subTables = response['subTables'];
        this.configHeader = response['configHeader'];
        this.loading = false;
        this.title = 'Errori';
      });
    this.loading = true;
    this.subTables = null;
  }
  getNotArchivedData() {
    this.diagnosticService.getNotArchivedDiagnosticData()
      .subscribe(response => {
        this.subTables = response['subTables'];
        this.configHeader = response['configHeader'];
        this.loading = false;
        this.title = 'Non archiviati';
      });
    this.loading = true;
    this.subTables = null;
  }
  getRecordedData() {
    this.diagnosticService.getRecordedDiagnosticData()
      .subscribe(response => {
        this.subTables = response['subTables'];
        this.configHeader = response['configHeader'];
        this.loading = false;
        this.title = 'Registrati';
      });
    this.loading = true;
    this.subTables = null;
  }
  ngOnInit() {
  }

}
