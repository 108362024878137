import { Component, OnInit, OnDestroy } from '@angular/core';
import { AthesisFaceNameAssociationService } from '../../../services/athesis-face-name-association.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, tap, takeUntil } from 'rxjs/operators';
import { FaceAssociationStatusesResponse } from '../../../models/face-association-statuses-response.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-abstract-face-association',
  templateUrl: './abstract-face-association.component.html'
})
export class AbstractFaceAssociationComponent implements OnInit, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject<void>();
  sectionBlocked = false;

  constructor(
    public athesisFaceNameAssociationService: AthesisFaceNameAssociationService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.athesisFaceNameAssociationService.checkApiStatus()
    .pipe(
      distinctUntilChanged(),
      tap(status => this.verifyStatus(status)),
      takeUntil(this.unsubscribe))
    .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  verifyStatus(status: FaceAssociationStatusesResponse) {
    if (!status.associationEnabled) {
      this.router.navigate(['athesis-root', 'name-face-association']);
    }
  }
}
