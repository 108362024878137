import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AthesisAuthService } from '../athesis-auth.service';
import { ContentEntity } from '../../models';
import { API_BASE_URL, API_VERSION_URL } from '../../constants';

export const API_CONTENT_DOMAIN = (domainId) =>
  `${API_BASE_URL}${API_VERSION_URL}/domains/${domainId}/contents`;

@Injectable({
  providedIn: 'root'
})
export class AthesisContentService {

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) { }

  getContentsByDomain(domainId: number | string) {
    return this.http.get<ContentEntity[]>(API_CONTENT_DOMAIN(domainId), this.athesisAuthService.httpOptions);
  }
}
