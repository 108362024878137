import { Component, OnInit } from '@angular/core';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-athesis-file-creator',
  templateUrl: './athesis-file-creator.component.html',
  styleUrls: ['./athesis-file-creator.component.scss']
})
export class AthesisFileCreatorComponent implements OnInit {
  domainId: number = null;
  uploadedFile: File = null;
  form: FormGroup;
  submitted = false;

  constructor(private athesisContentMonitorService: AthesisContentMonitorService,
              private athesisContentMonitorHelperService: AthesisContentMonitorHelperService,
              private route: ActivatedRoute,
              private router: Router) {
    this.domainId = Number(this.route.snapshot.params.id);
  }

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      fileValid: new FormControl(false, Validators.requiredTrue)
    });
  }

  get isAllowedExt() {
    if (!this.uploadedFile) {
      return true;
    }
    const extIndex = this.uploadedFile.name.lastIndexOf('.');
    return ['.pdf', '.txt'].includes(this.uploadedFile.name.substr(extIndex, this.uploadedFile.name.length));
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.athesisContentMonitorService.addFile(this.domainId, this.uploadedFile, this.f.name.value, this.f.description.value).subscribe(
        res => this.onElementEditSuccessful(),
        err => this.onElementEditUnsuccessful()
      );
    }
  }


  onUploadFileChange(file: File) {
    this.uploadedFile = file;

    console.log(this.isAllowedExt);

    this.form.patchValue({
      fileValid: !!file
    });
  }

  confirmAddFile(fileName: string, fileDescription: string) {
    this.athesisContentMonitorService.addFile(this.domainId, this.uploadedFile, fileName, fileDescription).subscribe(
      res => this.onElementEditSuccessful(),
      err => this.onElementEditUnsuccessful()
    );
  }

  onElementEditUnsuccessful(): void {
    console.log('file add unsuccessful');
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  onElementEditSuccessful(): void {
    this.athesisContentMonitorHelperService.getAllDomains();
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  returnToDomainView() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }
}
