import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContentDto, FormMapping, LinkType } from 'src/app/models';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';

export const LinkTypeMapping: FormMapping[] = [
  {value: LinkType.WEB_SITE, label: 'Sito Web', enumStringValue: 'WEB_SITE'},
  {value: LinkType.WEB_PAGE, label: 'Pagina Web', enumStringValue: 'WEB_PAGE'}
];

@Component({
  selector: 'app-athesis-link-creator',
  templateUrl: './athesis-link-creator.component.html',
  styleUrls: ['./athesis-link-creator.component.scss']
})
export class AthesisLinkCreatorComponent {
  domainId: number = null;
  viewedLink: ContentDto = null;
  linkTypes = LinkTypeMapping;
  selectedLinkType: FormMapping;


  constructor(private route: ActivatedRoute, private store: Store<{}>, private athesisContentMonitorService: AthesisContentMonitorService,
              private router: Router, private athesisContentMonitorHelperService: AthesisContentMonitorHelperService) {
    this.domainId = Number(route.snapshot.params.id);
  }

  confirmAddLink(linkName: string, linkUrl: string) {
    const linkToAdd: ContentDto = {domainId: this.domainId, name: linkName, url: linkUrl, type: this.selectedLinkType.value };
    this.athesisContentMonitorService.addLink(linkToAdd).subscribe(
      res => this.onElementEditSuccessful(),
      err => this.onElementEditUnsuccessful()
    );
  }
  onElementEditUnsuccessful(): void {
    console.log('link add unsuccessful');
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }
  onElementEditSuccessful(): void {
    this.athesisContentMonitorHelperService.getAllDomains();
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  returnToDomainView() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }
}
