import { Component, TemplateRef, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-athesis-modal-nfa-confirm',
  templateUrl: './athesis-modal-nfa-confirm.component.html'
})
export class AthesisModalNfaConfirmComponent implements AfterViewInit {

  item: any;
  modalConfig: any;
  template: TemplateRef<any>;
  onConfirm: Function;
  onCancel: Function;

  constructor(private modalRef: BsModalRef) {

  }

  ngAfterViewInit() {
  }

  confirm() {
    this.onConfirm();
    this.modalRef.hide();
  }

  cancel() {
    this.onCancel();
    this.modalRef.hide();
  }
}
