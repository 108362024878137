import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { login, userData } from './redux/actions/athesis-auth-actions';
import {
  SESSION_STORAGE_ACCESS_TOKEN_KEY,
  HEADER_AUTHORIZATION_VALUE,
  HTTP_HEADERS_BASE,
  HTTP_HEADERS_BASE_UPLOAD,
  SESSION_STORAGE_USER_DATA_KEY
} from './constants';
import { AthesisAuthService } from './services/athesis-auth.service';
import { HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { domains } from './redux/actions/athesis-content-monitor-actions';
import { fnaResults } from './redux/actions/athesis-face-name-association-actions';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'athesis-applicativo-interno-front-end';

  constructor(
    translate: TranslateService,
    private store: Store<{ isAuthenticated: boolean }>,
    private athesisAuthService: AthesisAuthService,
    private router: Router) {

    translate.addLangs(['it']);
    translate.setDefaultLang('it');
    translate.use('it');

    this.store.select('auth').subscribe((auth) => {
      if (auth && auth.isAuthenticated) {
        this.appendAuthorizationTokenToHeaders();
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.url.includes('content-monitor') && !event.url.includes('dashboard')) {
          // Clear domains when we leave a "content-monitor" route
          this.store.dispatch(domains(null));
        }
        if (!event.url.includes('name-face-association')) {
          this.store.dispatch(fnaResults(null));
        }
      }
    });

    this.handleSessionStorageData();
  }

  private handleSessionStorageData() {
    if (this.getSessionStorageAccessToken()) {
      this.appendAuthorizationTokenToHeaders();
      this.store.dispatch(login());
    }
    if (this.getSessionStorageUserData()) {
      this.store.dispatch(userData(JSON.parse(this.getSessionStorageUserData())));
    }
  }

  private appendAuthorizationTokenToHeaders(): void {
    this.athesisAuthService.httpOptions.headers = new HttpHeaders({
      ...HTTP_HEADERS_BASE,
      Authorization: HEADER_AUTHORIZATION_VALUE(this.getSessionStorageAccessToken())
    });
    this.athesisAuthService.httpOptionsUpload.headers = new HttpHeaders({
      ...HTTP_HEADERS_BASE_UPLOAD,
      Authorization: HEADER_AUTHORIZATION_VALUE(this.getSessionStorageAccessToken())
    });
    this.athesisAuthService.httpOptionsDownload.headers = new HttpHeaders({
      ...HTTP_HEADERS_BASE_UPLOAD,
      Authorization: HEADER_AUTHORIZATION_VALUE(this.getSessionStorageAccessToken())
    });
  }

  private getSessionStorageAccessToken(): string {
    return sessionStorage.getItem(SESSION_STORAGE_ACCESS_TOKEN_KEY);
  }

  private getSessionStorageUserData(): string {
    return sessionStorage.getItem(SESSION_STORAGE_USER_DATA_KEY);
  }
}
