import { Component, OnInit } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { AthesisUserService } from '../../../services/athesis-user.service';
import { UserEntity, RoleEntity } from '../../../models';
import { UserFormModalComponent } from '../user-form-modal/user-form-modal.component';
import { ModalsService } from '../../../services/modals/modals.service';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { Page } from '../../../models/pagination/page.model';
import { PageChangedEvent } from 'ngx-bootstrap';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AuthUser } from '../../auth-user.model';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  userList: Page<UserEntity>;
  icons = icons;
  loading = true;
  userChangeModal: UserEntity = null;
  currentPage = 1;
  currentUser: AuthUser;

  constructor(
    private modalService: ModalsService,
    private userService: AthesisUserService
  ) {
    this.currentUser = this.userService.currentUser;
    this.getUserList();
  }

  ngOnInit() { }

  addUser() {
    this.modalService.show(UserFormModalComponent, null, null, 'modal-xl').observable.subscribe((newUser) => {
      this.userService.addUser(newUser).subscribe(res => {
        this.reloadPage();
      });
    });
  }

  editUser(user, index) {
    this.modalService.show(UserFormModalComponent, null, user, 'modal-xl').observable.subscribe((updatedUser) => {
      this.userService.editUser(updatedUser).subscribe(res => {
        this.userList.content[index] = res;
      });
    });
  }

  resetPassword(user) {
    const modalConfig = {
      title: 'USERS.RESET_PASSWORD_MODAL.TITLE',
      message: 'USERS.RESET_PASSWORD_MODAL.MESSAGE'
    };

    this.modalService.show(ConfirmModalComponent, null, null, 'modal-md', modalConfig).observable.subscribe(() => {
      this.userService.forgotPassword(user.email).subscribe();
    });
  }

  deleteUser(user, index) {

    const modalConfig = {
      title: 'Cancella utente',
      message: 'Confermare cancellazione dell\'utente selezionato?'
    };

    this.modalService.show(ConfirmModalComponent, null, null, 'modal-md', modalConfig).observable.subscribe(() => {
      this.userService.deleteUser(user.id).subscribe(res => {
        this.reloadPage();
      });
    });
  }

  userStateUpdate(user, index) {
    const modalConfig = {
      title: 'Modifica stato utente',
      message: `Confermare ${user.enabled ? 'disabilitazione' : 'abilitazione'} dell\'utente selezionato?`
    };

    this.modalService.show(ConfirmModalComponent, null, null, 'modal-md', modalConfig).observable.subscribe(() => {
      // TODO: riattivare quando API disponibile
      // this.userService.changeEnabled(user.id, !user.enabled).subscribe(res => {
        this.userList[index].enabled = !user.enabled;
      // });
    });
  }

  changeEnabledModal(user) {
    this.userChangeModal = user;
  }

  onPageChanged(event: PageChangedEvent) {
    this.getUserList(event);
  }

  private getUserList(paginationInfo?) {
    let page = 0;

    if (paginationInfo) {
      page = paginationInfo.page - 1;
    }

    const params = new HttpParams()
      .set('page', page.toString())
      .set('sort', 'id,DESC')
      .set('deleted', 'false')
      .set('size', '10');

    this.userService.getPagedUsers(params).subscribe(response => {
      if (!this.userList) {
        this.userList = response;
      }
      this.userList.content = response.content;
      this.loading = false;
    });
  }

  private reloadPage() {
    this.getUserList({page: this.userList.number - 1});
  }
}
