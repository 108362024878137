import { Component, OnInit } from '@angular/core';
import { AthesisUserService } from 'src/app/services/athesis-user.service';
import { RoleEntity } from 'src/app/models';
import { Router } from '@angular/router';
import { AppRoutingModule } from 'src/app/app-routing.module';

@Component({
  selector: 'app-athesis-backoffice-create-user',
  templateUrl: './athesis-backoffice-create-user.component.html',
  styleUrls: ['./athesis-backoffice-create-user.component.scss']
})
export class AthesisBackofficeCreateUserComponent {
  public allRoles: RoleEntity[] = null;
  public selectedRole: RoleEntity = null;

  constructor(private athesisUserService: AthesisUserService, private router: Router) {
    athesisUserService.getAllRoles().subscribe(
      res => this.onGetAllRolesSuccessful(res),
      err => this.onGetAllRolesUnsuccessful()
    );
  }

  onGetAllRolesUnsuccessful(): void {
    console.log('getAllRoles unsuccessful');
  }

  onGetAllRolesSuccessful(res: RoleEntity[]): void {
    this.allRoles = res;
  }

  createUser(name: string, surname: string, email: string) {
    this.athesisUserService.createUser(name, surname, email, this.selectedRole.id).subscribe(
      res => this.onSuccessfulUserCreation(),
      err => this.onUnsuccessfulUserCreation()
    );
  }

  returnToBackofficeRoot() {
    this.router.navigate(['athesis-root', 'backoffice', 'root']);
  }

  onUnsuccessfulUserCreation(): void {
    console.log('user creation unsuccessful');
    this.returnToBackofficeRoot();
  }

  onSuccessfulUserCreation(): void {
    this.returnToBackofficeRoot();
  }

}
