import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImageInfoEntity, PersonEntity } from '../../../models';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { AthesisFaceNameAssociationService } from '../../../services/athesis-face-name-association.service';
import { RegistryDto } from '../../models/registry-dto.model';
import { FaceForGalleryDto } from '../../models/face-for-gallery-dto.model';
import { ModalsService } from '../../../services/modals/modals.service';
import { RegistryFormModalComponent } from '../registry-form-modal/registry-form-modal.component';
import { RegistryService } from '../../../services/registry.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-character-detail',
  templateUrl: './character-detail.component.html',
  styleUrls: ['./character-detail.component.scss']
})
export class CharacterDetailComponent {
  faceNameAssociationSub: Subscription = null;
  registryId: string;
  gallery: FaceForGalleryDto[];
  selectedImageInfo: ImageInfoEntity = null;
  selectedPerson: PersonEntity = null;

  character: RegistryDto;
  loading = true;
  public Editor = ClassicEditor;
  icons = icons;
  ckeditorConfig = {
    removePlugins: ['image', 'mediaEmbed'],
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'blockQuote', 'insertTable', 'undo', 'redo']
  };

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private modalService: ModalsService,
    private registryService: RegistryService) {

    this.registryId = route.snapshot.params.registryId;

    this.registryService.getRegistry(this.registryId).subscribe(registry => {
      this.character = registry;
      this.loading = false;
    });

    this.registryService.getRegistryGallery(this.registryId).subscribe(res => {
      this.gallery = res;
      this.loading = false;
    });
  }

  editWiki() {  }
  editRegistry() {
    this.modalService.show(RegistryFormModalComponent, null, this.character, 'modal-xl').observable
      .subscribe((registry) => {
        this.registryService.updateRegistry(registry).subscribe(res => {
          this.character = res;
        });
      });
  }

  goBack() {
    this.location.back();
  }
}
