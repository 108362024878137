import { Component, OnInit, OnDestroy } from '@angular/core';
import { FileEntity } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { AthesisFileService } from '../../services/file/athesis-file.service';

@Component({
  selector: 'app-athesis-file-editor',
  templateUrl: './athesis-file-editor.component.html',
  styleUrls: ['./athesis-file-editor.component.scss']
})
export class AthesisFileEditorComponent implements OnDestroy {
  contentMonitorSub: Subscription = null;
  fileId: number = null;
  domainId: number = null;
  selectedFile: FileEntity = null;
  uploadedFile: File = null;

  constructor(
    private route: ActivatedRoute, private store: Store<{}>,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private router: Router,
    private athesisContentMonitorHelperService: AthesisContentMonitorHelperService,
    private athesisFileService: AthesisFileService) {
    this.fileId = Number(route.snapshot.params.fileId);
    this.domainId = Number(route.snapshot.params.domainId);

    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.file) {
      this.selectedFile = state.file;
    } else {
      this.getFileDetail();
    }
  }

  getFileDetail() {
    const params = new HttpParams()
      .set('fileId', this.fileId.toString());

    this.athesisFileService.getFiles(params).subscribe(
      response => {
        this.selectedFile = response[0];
      }, error => {
        console.error('Error retriving file info', error);
      });
  }

  confirmEditFile(fileName: string, fileDescription: string) {
    if (this.uploadedFile) {
      this.athesisContentMonitorService.editFile(fileName, fileDescription, this.uploadedFile, this.selectedFile.reference).subscribe(
        res => this.onElementEditSuccessful(),
        err => this.onElementEditUnsuccessful()
      );
    } else {
      this.athesisContentMonitorService.editFileDetails(fileName, fileDescription, this.selectedFile.reference).subscribe(
        res => this.onElementEditSuccessful(),
        err => this.onElementEditUnsuccessful()
      );
    }
  }

  onUploadFileChange(file: File) {
    this.uploadedFile = file;
  }

  onElementEditUnsuccessful(): void {
    console.log('file edit unsuccessful');
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  onElementEditSuccessful(): void {
    this.athesisContentMonitorHelperService.getAllDomains();
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  returnToDomainView() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  ngOnDestroy() {
    if (this.contentMonitorSub) {
      this.contentMonitorSub.unsubscribe();
    }
  }
}
