import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AthesisUserService } from 'src/app/services/athesis-user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-athesis-confirm-registration',
  templateUrl: './athesis-confirm-registration.component.html',
  styleUrls: ['./athesis-confirm-registration.component.scss']
})
export class AthesisConfirmRegistrationComponent implements OnInit {
  registrationToken: string = null;
  form: FormGroup;
  submitted = false;
  icons = icons;

  constructor(private route: ActivatedRoute, private athesisUserService: AthesisUserService, private router: Router, private fb: FormBuilder) {
    this.registrationToken = this.route.snapshot.params.regToken;
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.buildForm(this.fb);
  }

  buildForm(fb: FormBuilder): FormGroup {
    return fb.group({
      password: fb.control(null, [Validators.required, Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[\S]{8,}/gm)]),
      passwordConfirm: fb.control(null, [Validators.required, this.confirmPasswordValidator.bind(this)]),
    });
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.athesisUserService.confirmRegistation(this.registrationToken, this.f.password.value).subscribe(
        res => this.onRegistrationConfirmSuccessful(),
        err => this.onRegistrationConfirmUnsuccessful()
      );
    }
  }

  onRegistrationConfirmUnsuccessful(): void {
    console.log('confirmRegistration error');
    this.router.navigate(['login']);
  }
  onRegistrationConfirmSuccessful(): void {
    this.router.navigate(['login']);
  }

  confirmPasswordValidator(control: FormControl): {[s: string]: boolean} {
    if (!!this.form && (control.value !== this.form.value.password)) {
      return {confirmPasswordIsInvalid: true};
    }
    return null;
  }
}
