import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { VideoSimilarSearchComponent } from './components/video-similar-search/video-similar-search.component';
import { VideoResultListComponent } from './components/video-result-list/video-result-list.component';

@NgModule({
    declarations: [
        VideoSimilarSearchComponent,
        VideoResultListComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        VideoSimilarSearchComponent,
        VideoResultListComponent
    ]
})
export class SemanticSearchModule {}
