import { Component, OnInit } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-athesis-not-found-page',
  templateUrl: './athesis-not-found-page.component.html',
  styleUrls: ['./athesis-not-found-page.component.scss']
})
export class AthesisNotFoundPageComponent implements OnInit {

  icons = icons;
  constructor() { }

  ngOnInit() {
  }

}
