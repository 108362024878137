import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDomainEntity, UserEntity } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { Subscription } from 'rxjs';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { ModalsService } from '../../../services/modals/modals.service';
import { AddDomainUserModalComponent } from '../add-domain-user-modal/add-domain-user-modal.component';

@Component({
  selector: 'app-athesis-users-viewer',
  templateUrl: './athesis-users-viewer.component.html',
  styleUrls: ['./athesis-users-viewer.component.scss']
})
export class AthesisUsersViewerComponent implements OnDestroy {
  contentMonitorSub: Subscription = null;
  authSub: Subscription = null;
  domainId: number = null;
  selectedUsers: UserDomainEntity[] = null;
  loggedInUserId: number = null;
  icons = icons;

  constructor(
    private modalService: ModalsService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{}>,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private athesisContentMonitorHelperService: AthesisContentMonitorHelperService) {

    this.domainId = Number(this.route.snapshot.params.domainId);

    this.authSub = this.store.select('auth').subscribe((auth) => {
      if (auth && auth.user) {
        this.loggedInUserId = auth.user.id;
      }
    });

    this.contentMonitorSub = this.store.select('contentMonitor').subscribe(contentMonitor => {
      if (contentMonitor.domains) {
        this.selectedUsers = contentMonitor.domains.filter(domain => domain.id === this.domainId)[0]
          .userDomainEntities;
      } else {
        athesisContentMonitorHelperService.getAllDomains();
      }
    });
  }

  onUserSelect(user: UserDomainEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'user', user.id]);
  }

  addDomainUser() {
    const userList = this.selectedUsers.map(item => item.user);
    this.modalService.show(AddDomainUserModalComponent, null, { userList, domainId: this.domainId}, 'modal-md').observable.subscribe((newUser) => {
      this.athesisContentMonitorService.getUserDomain(this.domainId).subscribe(response => {
        this.selectedUsers = response.userDomainEntities;
      });
    });
  }

  gotoAddUser() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'users', 'add']);
  }

  ngOnDestroy() {
    if (this.contentMonitorSub) {
      this.contentMonitorSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  onUserRemove(user: UserDomainEntity) {
    this.athesisContentMonitorService.deleteUserFromDomain(user.user.id, false, this.domainId).subscribe(
      res => this.onSuccessfulUserRemove(),
      err => this.onUnsuccessfulUserRemove()
    );
  }

  onUnsuccessfulUserRemove(): void {
    console.log('unsuccessful user remove');
  }

  onSuccessfulUserRemove(): void {
    this.athesisContentMonitorHelperService.getAllDomains();
  }

  editUserWritePermission(userDomainEntity: UserDomainEntity) {
    this.athesisContentMonitorService.editDomainUserWritePermission(
      this.domainId,
      userDomainEntity.user.id,
      userDomainEntity.id, userDomainEntity.canWrite).subscribe(
        res => this.onElementEditSuccessful(),
        err => this.onElementEditUnsuccessful()
      );
  }

  onElementEditUnsuccessful(): void {
    console.log('user write permission edit unsuccessful');
  }
  onElementEditSuccessful(): void {
    this.athesisContentMonitorHelperService.getAllDomains();
  }

  modalConfirmUserDeletion(user: UserDomainEntity) {
    this.onUserRemove(user);
  }
}
