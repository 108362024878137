import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AthesisAuthService } from './athesis-auth.service';
import {
  DomainEntity, DomainDto, ContentEntity, ContentDto, TopicDto,
  ArticleEntity, ArticleDto, TopicEntity, FileEntity, KeywordSearchEntity
} from '../models';
import {
  API_CONTENT_MONITOR_DOMAINS, API_CONTENT_MONITOR_CREATE_DOMAIN, API_CONTENT_MONITOR_DELETE_DOMAIN,
  API_CONTENT_MONITOR_CONTENTS, API_CONTENT_MONITOR_TOPICS,
  API_CONTENT_MONITOR_DELETE_TOPIC, API_CONTENT_MONITOR_ARTICLES,
  API_CONTENT_MONITOR_FILES, API_CONTENT_MONITOR_FILE_BY_REFERENCE,
  API_CONTENT_MONITOR_DOMAIN_BY_ID,
  API_CONTENT_MONITOR_TOPICS_BY_DOMAIN,
  API_CONTENT_MONITOR_CONTENT_BY_ID,
  API_CONTENT_MONITOR_ARTICLE_BY_ID,
  API_CONTENT_MONITOR_FILE_BY_REFERENCE_DETAILS,
  API_CONTENT_MONITOR_DOMAIN_BY_ID_USERS,
  API_SEMANTIC_SEARCH_KEYWORDS,
  API_CONTENT_MONITOR_DOMAIN_BY_ID_MANAGE_USERS,
  API_CONTENT_MONITOR_TOPICS_BY_ID,
  API_CONTENT_MONITOR_UPDATE_DOMAIN
} from '../constants';
import { DomainRequest } from '../models/domain/domain-request.model';
import { Domain } from '../models/domain/domain.model';
import { MessageService } from './message.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AthesisContentMonitorService extends MessageService {

  constructor(
    private http: HttpClient,
    private athesisAuthService: AthesisAuthService,
    toastr: ToastrService,
    translateService: TranslateService
  ) {
    super(toastr, translateService);
  }

  getUserDomain(domainId: number) {
    return this.http.get<DomainEntity>(API_CONTENT_MONITOR_DOMAIN_BY_ID(domainId), this.athesisAuthService.httpOptions);
  }

  getAllUserDomains() {
    return this.http.get<DomainEntity[]>(API_CONTENT_MONITOR_DOMAINS, this.athesisAuthService.httpOptions);
  }

  createDomain(domain: DomainRequest) {
    return this.http.post<DomainEntity>(API_CONTENT_MONITOR_CREATE_DOMAIN, domain, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('DOMAINS.MESSAGES.CREATE.SUCCESS', 'DOMAINS.MESSAGES.CREATE.ERROR'));
  }

  updateDomain(domainId: number, domain: DomainRequest) {
    return this.http.put<Domain>(API_CONTENT_MONITOR_UPDATE_DOMAIN(domainId), domain, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('DOMAINS.MESSAGES.UPDATE.SUCCESS', 'DOMAINS.MESSAGES.UPDATE.ERROR'));
  }

  /* createDomain(domainName: string) {
    const domain = {} as DomainDto;
    domain.name = domainName;
    return this.http.post<DomainEntity>(API_CONTENT_MONITOR_CREATE_DOMAIN, domain, this.athesisAuthService.httpOptions);
  } */

  deleteDomain(domainId: number) {
    return this.http.delete(API_CONTENT_MONITOR_DELETE_DOMAIN(domainId), this.athesisAuthService.httpOptions)
    .pipe(this.handleMessages('DOMAINS.MESSAGES.DELETE.SUCCESS', 'DOMAINS.MESSAGES.DELETE.ERROR'));
  }

  addLink(link: ContentDto) {
    return this.http.post<ContentEntity>(API_CONTENT_MONITOR_CONTENTS, link, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('LINKS.MESSAGES.CREATE.SUCCESS', 'LINKS.MESSAGES.CREATE.ERROR'));
  }

  deleteLink(linkId: number) {
    return this.http.delete(API_CONTENT_MONITOR_CONTENT_BY_ID(linkId), this.athesisAuthService.httpOptions)
    .pipe(this.handleMessages('LINKS.MESSAGES.DELETE.SUCCESS', 'LINKS.MESSAGES.DELETE.ERROR'));
  }

  editLink(link: ContentDto, linkId: number) {
    return this.http.put<ContentEntity>(API_CONTENT_MONITOR_CONTENT_BY_ID(linkId), link, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('LINKS.MESSAGES.UPDATE.SUCCESS', 'LINKS.MESSAGES.UPDATE.ERROR'));
  }

  getTopicByDomain(domainId: number) {
    return this.http.get<TopicEntity[]>(API_CONTENT_MONITOR_TOPICS_BY_DOMAIN(domainId), this.athesisAuthService.httpOptions);
  }

  getTopicById(topicId: number) {
    return this.http.get<TopicEntity>(API_CONTENT_MONITOR_TOPICS_BY_ID(topicId), this.athesisAuthService.httpOptions);
  }

  addTopic(topic: TopicDto) {
    return this.http.post<TopicEntity>(API_CONTENT_MONITOR_TOPICS, topic, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('TOPICS.MESSAGES.CREATE.SUCCESS', 'TOPICS.MESSAGES.CREATE.ERROR'));
  }

  deleteTopic(topicId: number) {
    return this.http.delete(API_CONTENT_MONITOR_DELETE_TOPIC(topicId), this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('TOPICS.MESSAGES.DELETE.SUCCESS', 'TOPICS.MESSAGES.DELETE.ERROR'));
  }

  addArticle(article: ArticleDto) {
    return this.http.post<ArticleEntity>(API_CONTENT_MONITOR_ARTICLES, article, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('ARTICLES.MESSAGES.CREATE.SUCCESS', 'ARTICLES.MESSAGES.CREATE.ERROR'));
  }

  deleteArticle(articleId: number) {
    return this.http.delete(API_CONTENT_MONITOR_ARTICLE_BY_ID(articleId), this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('ARTICLES.MESSAGES.DELETE.SUCCESS', 'ARTICLES.MESSAGES.DELETE.ERROR'));
  }

  editArticle(article: ArticleDto, articleId: number) {
    return this.http.put<ArticleEntity>(API_CONTENT_MONITOR_ARTICLE_BY_ID(articleId), article, this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('ARTICLES.MESSAGES.UPDATE.SUCCESS', 'ARTICLES.MESSAGES.UPDATE.ERROR'));
  }

  addFile(fileDomainId: number, file: File, fileName: string, fileDescription: string) {
    const formData: FormData = new FormData();
    formData.append('domainId', fileDomainId.toString());
    formData.append('file', file);
    formData.append('name', fileName);
    formData.append('description', fileDescription);
    return this.http.post<ArticleEntity>(API_CONTENT_MONITOR_FILES, formData, this.athesisAuthService.httpOptionsUpload)
      .pipe(this.handleMessages('FILES.MESSAGES.CREATE.SUCCESS', 'FILES.MESSAGES.CREATE.ERROR'));
  }

  deleteFile(reference: string) {
    return this.http.delete(API_CONTENT_MONITOR_FILE_BY_REFERENCE(reference), this.athesisAuthService.httpOptions)
      .pipe(this.handleMessages('FILES.MESSAGES.DELETE.SUCCESS', 'FILES.MESSAGES.DELETE.ERROR'));
  }

  downloadFile(reference: string) {
    return this.http.get(API_CONTENT_MONITOR_FILE_BY_REFERENCE(reference), this.athesisAuthService.httpOptionsDownload);
  }

  editFile(fileName: string, description: string, file: File, reference: string) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('name', fileName);
    formData.append('description', description);
    return this.http.put<FileEntity>(API_CONTENT_MONITOR_FILE_BY_REFERENCE(reference), formData, this.athesisAuthService.httpOptionsUpload)
      .pipe(this.handleMessages('FILES.MESSAGES.UPDATE.SUCCESS', 'FILES.MESSAGES.UPDATE.ERROR'));
  }

  editFileDetails(fileName: string, description: string, reference: string) {
    return this.http.put<FileEntity>(
      API_CONTENT_MONITOR_FILE_BY_REFERENCE_DETAILS(reference, fileName, description),
      {},
      this.athesisAuthService.httpOptionsUpload);
  }

  editDomainUserWritePermission(domainId: number, userId: number, userDomainId: number, newCanWrite: boolean) {
    return this.http.put<DomainEntity>(
      API_CONTENT_MONITOR_DOMAIN_BY_ID_USERS(domainId, userId),
      { canWrite: newCanWrite, id: userDomainId },
      this.athesisAuthService.httpOptions);
  }

  addUserToDomain(userId: number, canUserWrite: boolean, domainId: number) {
    return this.http.post(API_CONTENT_MONITOR_DOMAIN_BY_ID_MANAGE_USERS(domainId),
      { canWrite: canUserWrite, id: userId }, this.athesisAuthService.httpOptions);
  }

  deleteUserFromDomain(userId: number, canUserWrite: boolean, domainId: number) {
    return this.http.delete(API_CONTENT_MONITOR_DOMAIN_BY_ID_USERS(domainId, userId), this.athesisAuthService.httpOptions);
  }

  getLinkById(linkId: number) {
    return this.http.get<ContentEntity>(API_CONTENT_MONITOR_CONTENT_BY_ID(linkId), this.athesisAuthService.httpOptions);
  }
}
