import { Component } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { NewspaperEntity, ZippIssuePageEntity, ZippArticleEntity, KeywordSearchEntity, ZipponiSearchEntity, ConferringSystemEntity } from 'src/app/models';
import { AthesisSemanticSearchServiceService, SearchSimilarZipponiRequest } from 'src/app/services/athesis-semantic-search-service.service';
import { SemanticSearchResponseTypeEnum } from '../../models/SemanticSearchResponseType.enum';

export interface NewspaperEntityCheckbox extends NewspaperEntity {
  checked?: boolean;
}
export interface ConferringSystemEntityCheckbox extends ConferringSystemEntity {
  checked?: boolean;
}

@Component({
  selector: 'app-athesis-zippone-similar-search',
  templateUrl: './athesis-zippone-similar-search.component.html',
  styleUrls: ['./athesis-zippone-similar-search.component.scss']
})
export class AthesisZipponeSimilarSearchComponent {
  icons = icons;
  newspapers: NewspaperEntityCheckbox[] = null;
  checkedByDefaultNewspapers: string[] = ['ARE'];
  selectedInitialNewspaper: string = null;
  autocompleteOptions: string[];
  minIssuesAutocompleteLength = 6;
  maxIssuesAutocompleteLength = 8;
  newspaperSelected = false;
  issueSelected = false;
  pageSelected = false;
  issueInputText: string = null;
  pages: ZippIssuePageEntity[] = null;
  selectedPage: string = null;
  articles: ZippArticleEntity[] = null;
  selectedArticle: string = null;
  articleSelected = false;
  articleContentSelected = false;
  articleContents: ZippArticleEntity[] = null;
  selectedArticleContent: string = null;
  conferringSystems: ConferringSystemEntityCheckbox[];
  activeTab = SemanticSearchResponseTypeEnum.TEXT;
  error = false;
  isContentOpen = true;

  /* ============= modifiche 16-01-2020 ============== */
  searchResults: KeywordSearchEntity[] = null;
  loading: boolean = null;
  /* ================================================= */

  constructor(private athesisSemanticSearchServiceService: AthesisSemanticSearchServiceService) {
    this.athesisSemanticSearchServiceService.getNewspapers().subscribe(
      res => this.onSuccessfulGetNewspapers(res),
      err => this.onUnsuccessfulGetNewspapers(),
    );

    this.loading = true;
    athesisSemanticSearchServiceService.getConferringSystems().subscribe(res => {
        this.conferringSystems = res.map(r => ({...r, checked: false}));
        this.conferringSystems[0].checked = true;
      },
      err => {
      }
    );
  }

  get formattedIssueInputText() {
    const issueDate = new Date(this.issueInputText);
    const year = issueDate.getFullYear();
    const month = (issueDate.getUTCMonth() + 1) < 10 ? '0' + (issueDate.getUTCMonth() + 1) : issueDate.getUTCMonth() + 1;
    const day = issueDate.getDate();
    return `${year}${month}${day}`;
  }

  onSuccessfulGetNewspapers(res: NewspaperEntity[]): void {
    this.newspapers = res;
    this.newspapers.forEach(element => {
      if (this.checkedByDefaultNewspapers.includes(element.code)) {
        element.checked = true;
      }
    });
    this.loading = false;
  }

  onUnsuccessfulGetNewspapers() {
    console.log('getNewspapers unsuccessful');
    this.error = true;
    this.loading = false;
  }

  onUnsuccessfulAutocomplete(): void {
    console.log('unsuccessful getAutocompleteIssues');
    this.loading = false;
  }

  onSuccessfulAutocomplete(res: string[]): void {
    this.issueSelected = false;
    this.autocompleteOptions = res;

    if (this.formattedIssueInputText.length === this.maxIssuesAutocompleteLength && this.autocompleteOptions.includes(this.formattedIssueInputText)) {
      this.loading = true;
      this.athesisSemanticSearchServiceService.getZippIssuePages(this.formattedIssueInputText, this.selectedInitialNewspaper).subscribe(
        innerRes => this.onSuccessfulGetZippIssuePages(innerRes),
        err => this.onUnsuccessfulGetZippIssuePages()
      );
    } else {
      this.issueSelected = false;
    }
  }

  onUnsuccessfulGetZippIssuePages(): void {
    console.log('unsuccessful getZippIssuePages');
    this.error = true;
    this.loading = false;
  }

  onSuccessfulGetZippIssuePages(res: ZippIssuePageEntity[]): void {
    this.pages = res;
    this.issueSelected = true;
    this.loading = false;
  }


  onSelectedNewspaperChange() {
    this.pages = null;
    this.autocompleteOptions = null;

    this.resetFormField(true, true, true, true);

    this.articles = null;
    if (this.selectedInitialNewspaper) {
      this.newspaperSelected = true;
    } else {
      this.newspaperSelected = false;
    }
  }

  onSelectedIssueChange(userString: string) {

    this.resetFormField(false, true, true, true);
    this.issueSelected = false;
    this.articles = null;

    this.athesisSemanticSearchServiceService.getZippIssuePages(userString.replace(/-/g, ''), this.selectedInitialNewspaper).subscribe(
      innerRes => this.onSuccessfulGetZippIssuePages(innerRes),
      err => this.onUnsuccessfulGetZippIssuePages()
    );
    this.loading = true;
  }

  onSelectedPageChange() {
    this.resetFormField(false, false, true, true);
    this.pageSelected = false;
    this.articles = null;
    if (this.selectedPage) {
      this.loading = true;
      this.athesisSemanticSearchServiceService.getZippArticles(this.selectedPage).subscribe(
        res => this.onSuccessfulGetArticles(res),
        err => this.onUnsuccessfulGetArticles()
      );
    } else {
      this.pageSelected = false;
    }
  }

  onSelectedArticleChange() {
    this.resetFormField(false, false, false, true);
    this.articleSelected = false;
    if (this.selectedArticle) {
      this.loading = true;
      this.athesisSemanticSearchServiceService.getZippArticleContents(this.selectedArticle).subscribe(
        res => this.onSuccessfulGetArticleContents(res),
        err => this.onUnsuccessfulGetArticleContents()
      );
    } else {
      this.articleSelected = false;
    }
    this.loading = false;
  }

  onUnsuccessfulGetArticles(): void {
    console.log('getArticles unsuccessful');
    this.error = true;
    this.loading = false;
  }

  resetFormField(
    resetIssue: boolean,
    resetPage: boolean,
    resetArticle: boolean,
    resetArticleContent: boolean
  ) {
    this.issueInputText = resetIssue ? null : this.issueInputText;
    this.selectedPage = resetPage ? null : this.selectedPage;
    this.selectedArticle = resetArticle ? null : this.selectedArticle;
    this.selectedArticleContent = resetArticleContent ? null : this.selectedArticleContent;

    this.issueSelected = !resetIssue;
    this.pageSelected = !resetPage;
    this.articleSelected = !resetArticle;
    this.articleContentSelected = !resetArticleContent;
  }

  onSuccessfulGetArticles(res: ZippArticleEntity[]): void {
    this.articles = res;
    this.pageSelected = true;
    this.loading = false;
  }


  onUnsuccessfulGetArticleContents(): void {
    console.log('getArticleContents unsuccessful');
    this.error = true;
    this.loading = false;
  }
  onSuccessfulGetArticleContents(res: ZippArticleEntity[]): void {
    this.articleContents = res;
    this.articleSelected = true;
    this.loading = false;
  }

  onUnsuccessfulSearchResult(): void {
    console.log('zipp search unsuccessful');
    this.error = true;
    this.searchResults = null;
    this.loading = false;
  }
  onSuccessfulSearchResult(res: ZipponiSearchEntity[]): void {
    this.searchResults = res;
    this.loading = false;
  }

  search(searchType) {
    const newspapers = this.newspapers.filter(element => element.checked).map(item => item.code);

    this.activeTab = searchType;

    const searchParams: SearchSimilarZipponiRequest = {
      newspaperCodes: newspapers,
      conferringSystemsCodes: this.conferringSystems.filter(check => check.checked).map(item => item.code),
      idFile: this.selectedArticleContent,
      responseType: searchType
    };

    this.athesisSemanticSearchServiceService
      .getResultsZipp(searchParams)
      .subscribe(
        res => this.onSuccessfulSearchResult(res),
        err => this.onUnsuccessfulSearchResult(),
      );
    this.loading = true;
    this.searchResults = [];
  }

  valueChanged(input) {
    console.log('changed', input);
  }

  tabChanged(tabName) {
    if (tabName !== this.activeTab) {
      this.search(tabName);
    }
  }


}
