import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AthesisAuthService} from './athesis-auth.service';
import { API_BASE_URL } from '../constants';
import { tap, catchError } from 'rxjs/operators';
import { MessageService } from './message.service';
import { throwError, Observable } from 'rxjs';
import { TreeviewItem } from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})

export class AthesisTreeService {
  private readonly apiVersion2 = 'api/v2';
  baseUrl = API_BASE_URL + this.apiVersion2;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private athesisAuthService: AthesisAuthService) { }

  getTree(): Observable<TreeviewItem[]> {
    const tree = this.baseUrl + '/images/tree';
    return this.http.get<TreeviewItem[]>(tree, { ...this.athesisAuthService.httpOptions });
  }
  getTreeModal() {
    const tree = this.baseUrl + '/images/tree?show-empty=true';
    return this.http.get(tree, { ...this.athesisAuthService.httpOptions });
  }

  updateImage(imageId, targetNodeId, actualNodeId) {
    const updateUrl = this.baseUrl + '/images/tree/classification';
    return this.http.put(updateUrl, {
        actualNodeId,
        targetNodeId,
        imageId
      }, this.athesisAuthService.httpOptions
    ).pipe(
      tap(res => {
        this.messageService.handleSuccess('IMAGE_SIMILAR_SEARCH.UPDATE.SUCCESS');
      }),
      catchError(error => {
        if (error.status === 500) {
          this.messageService.handleError(error);
        }
        if (error.status === 400) {
          error.error.errors.array.forEach(err => {
            this.messageService.handleErrorWithParams(error, 'IMAGE_SIMILAR_SEARCH.UPDATE.ERROR', {codes: err.codes.join(', ')});
          });
        }

        this.messageService.handleError(error, 'GENERAL.GENERIC_ERROR');

        return throwError(error);
      })
    );
  }

  deleteImage(imageId, actualNodeId) {
    const deleteUrl = this.baseUrl + '/images/tree/classification';
    return this.http.put(deleteUrl, {
        actualNodeId,
        targetNodeId: null,
        imageId
      }, this.athesisAuthService.httpOptions
    );
  }
}
