import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DomainEntity } from 'src/app/models';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationEnd } from '@angular/router';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { domains } from 'src/app/redux/actions/athesis-content-monitor-actions';
import { Subscription } from 'rxjs';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { HttpParams } from '@angular/common/http';
import { AthesisDomainService } from '../../services/domain/athesis-domain.service';
import { AuthUser } from '../../users/auth-user.model';
import { AthesisUserService } from '../../services/athesis-user.service';
import { ModalsService } from '../../services/modals/modals.service';
import { ConfirmModalComponent } from '../../shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-athesis-domains-viewer',
  templateUrl: './athesis-domains-viewer.component.html',
  styleUrls: ['./athesis-domains-viewer.component.scss']
})
export class AthesisDomainsViewerComponent implements OnDestroy {
  authSub: Subscription = null;
  contentMonitorSub: Subscription = null;
  routerSub: Subscription = null;
  userId: number = null;
  ownedDomains: DomainEntity[] = [];
  guestDomains: DomainEntity[] = [];
  icons = icons;
  currentUser: AuthUser;


  constructor(
    private router: Router,
    private store: Store<{}>,
    private userService: AthesisUserService,
    private modalService: ModalsService,
    private athesisContentMonitorHelperService: AthesisContentMonitorHelperService,
    private athesisDomainService: AthesisDomainService,
    private athesisContentMonitorService: AthesisContentMonitorService) {

    /*
    this.authSub = store.select('auth').subscribe((auth) => {
      if (auth && auth.user) {
        this.userId = auth.user.id;
      }
    });
    */
    this.currentUser = this.userService.currentUser;
    this.userId = this.currentUser.id;
    this.getDomainList();
  }

  filterDomains(unfilteredDomains: DomainEntity[]) {
    this.ownedDomains = unfilteredDomains.filter(
      domain => domain.userDomainEntities.filter(
        userDomainEntity => userDomainEntity.owner && userDomainEntity.user.id === this.userId).length > 0);
    this.guestDomains = unfilteredDomains.filter(domain => !this.ownedDomains.includes(domain));
  }

  editDomain(domain: DomainEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain-creation', domain.id], { state: { domain } });
  }
  viewDomain(domain: DomainEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', domain.id], { state: { domain } });
  }

  userHasDomainWritePermissions(domain: DomainEntity): boolean {
    return this.currentUser.isAdmin() ||
      !!domain.userDomainEntities.find(userDomainEntity =>
        userDomainEntity.canWrite && userDomainEntity.user.id === this.currentUser.id);
  }

  beginDomainCreation() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain-creation']);
  }

  modalConfirmDomainDeletion(domain, index) {
    this.athesisContentMonitorService.deleteDomain(domain.id).subscribe(
      res => {
        this.onDomainDeletionSuccessful(index);
      },
      err => {
        this.onDomainDeletionUnsuccessful();
      }
    );
  }
  onDomainDeletionUnsuccessful() {
    console.log('onDomainDeletionUnsuccessful');
  }
  onDomainDeletionSuccessful(index) {
    this.ownedDomains.splice(index, 1);
    this.athesisContentMonitorHelperService.getAllDomains();
  }

  ngOnDestroy(): void {
    // Clean store from previous domains
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.contentMonitorSub) {
      this.contentMonitorSub.unsubscribe();
    }
  }

  private getDomainList() {
    this.athesisDomainService.getDomains().subscribe(response => {
      this.filterDomains(response.content);
    });
  }

  deleteDomain(domain: DomainEntity, index: number, isOwner = true) {
    const modalConfig = {
      title: 'DOMAINS.REMOVE_MODAL.TITLE',
      message: 'DOMAINS.REMOVE_MODAL.MESSAGE'
    };

    this.modalService.show(ConfirmModalComponent, null, null, 'modal-md', modalConfig).observable.subscribe(() => {
      this.athesisContentMonitorService.deleteDomain(domain.id).subscribe(res => {
        if (isOwner) {
          this.ownedDomains.splice(index, 1);
        } else {
          this.guestDomains.splice(index, 1);
        }
      });
    });
  }
}
