import { Component, OnInit, Input } from '@angular/core';
import { TopicEntity, Status, RejectReasonsEntity, DomainDto, DomainEntity } from 'src/app/models';
import { Router, ActivatedRoute } from '@angular/router';
import { Page } from '../../../models/pagination/page.model';

import * as icons from '@fortawesome/free-solid-svg-icons';
import { HttpParams } from '@angular/common/http';
import { AthesisTopicService } from '../../../services/athesis-topic.service';
import { PageChangedEvent } from 'ngx-bootstrap';
import { ModalsService } from '../../../services/modals/modals.service';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { RejectTopicModalComponent } from '../reject-topic-modal/reject-topic-modal.component';
import { TopicStatus } from '../../../models/topic/TopicStatus';
import { AuthUser } from '../../../users/auth-user.model';
import { AthesisUserService } from '../../../services/athesis-user.service';


@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['../../athesis-domain-viewer/athesis-domain-viewer.component.scss']
})
export class TopicListComponent implements OnInit {

  domainId: number;
  icons = icons;
  topicList: Page<TopicEntity>;
  selectedTopic: TopicEntity;
  status = Status;
  rejectReasons: RejectReasonsEntity[];
  selectedReason = { id: 1, deleted: false, message: 'Non pertinente', code: 'IRRELEVANT', index: 1 };
  currentUser: AuthUser;
  @Input() selectedDomain: DomainEntity;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: AthesisUserService,
    private athesisTopicService: AthesisTopicService,
    private modalService: ModalsService
  ) {
    this.currentUser = this.userService.currentUser;
  }

  ngOnInit() {
    this.domainId = +this.route.snapshot.params.id;
    this.getTopicList();
  }

  rejectTopic(topicId: number, index: number) {
    this.modalService.show(RejectTopicModalComponent, null, null, 'modal-md').observable.subscribe((selectedReason) => {
      this.athesisTopicService.rejectTopic(topicId, selectedReason).subscribe(response => {
        this.topicList.content[index].status = TopicStatus.REJECTED;
      });
    });
  }

  approveTopic(topicId: number, index: number) {
    const modalConfig = {
      title: 'TOPICS.APPROVE_MODAL.TITLE',
      message: 'TOPICS.APPROVE_MODAL.MESSAGE'
    };

    this.modalService.show(ConfirmModalComponent, null, null, 'modal-md', modalConfig).observable.subscribe(() => {
      this.athesisTopicService.approveTopic(topicId).subscribe(response => {
        this.topicList.content[index].status = TopicStatus.APPROVED;
      });
    });
  }

  removeTopic(topicId: number, index: number) {
    const modalConfig = {
      title: 'TOPICS.REMOVE_MODAL.TITLE',
      message: 'TOPICS.REMOVE_MODAL.MESSAGE'
    };

    this.modalService.show(ConfirmModalComponent, null, null, 'modal-md', modalConfig).observable.subscribe(() => {
      this.athesisTopicService.deleteTopic(topicId).subscribe(response => {
        this.topicList.content.splice(index, 1);
      });
    });
  }

  private getTopicList(paginationInfo?) {
    let page = 0;

    if (paginationInfo) {
      page = paginationInfo.page - 1;
    }

    const params = new HttpParams()
      .set('domainId', this.domainId.toString())
      .set('page', page.toString())
      .set('sort', 'id,DESC')
      .set('size', '10');

    this.athesisTopicService.getTopics(params).subscribe(response => {
      if (!this.topicList) {
        this.topicList = response;
      }
      this.topicList.content = response.content;
    });
  }

  beginViewTopic(topic: TopicEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'topic-viewer', topic.id], { state: { topic, domain: this.selectedDomain } });
  }

  editTopic(topic: TopicEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'topic-editor', topic.id], { state: { topic } });
  }

  onPageChanged(event: PageChangedEvent) {
    this.getTopicList(event);
  }

  userHasDomainWritePermissions() {
    return this.currentUser.isAdmin() ||
    !!this.selectedDomain.userDomainEntities.find(userDomainEntity =>
      userDomainEntity.canWrite && userDomainEntity.user.id === this.currentUser.id);
  }
}
