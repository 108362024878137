import { Component, OnInit, OnDestroy } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { FaceNameAssociationEntity, PersonEntity } from 'src/app/models';
// import { getFaceNameAssociationMocks } from '../../services/athesis-name-face-association-mocks';
import { AthesisFaceNameAssociationService } from 'src/app/services/athesis-face-name-association.service';
import { Store } from '@ngrx/store';
import { fnaResults } from 'src/app/redux/actions/athesis-face-name-association-actions';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-athesis-nfa-images-viewer',
  templateUrl: './athesis-nfa-images-viewer.component.html',
  styleUrls: ['./athesis-nfa-images-viewer.component.scss']
})
export class AthesisNfaImagesViewerComponent implements OnDestroy {
  faceNameAssociationSub: Subscription = null;
  faceAssociationResults: FaceNameAssociationEntity[];
  similarImages: FaceNameAssociationEntity[] = null;
  lastBoundName: string = null;
  icons = icons;

  constructor(private store: Store<{}>, private fnaService: AthesisFaceNameAssociationService, private router: Router) {
    this.faceNameAssociationSub = store.select('faceNameAssociation').subscribe(faceNameAssociation => {
      if (faceNameAssociation.fnaResults) {
        this.faceAssociationResults = faceNameAssociation.fnaResults;
      } else {
        this.getAllNfaResults();
      }
    });
  }

  viewImageDetails(cardResult: FaceNameAssociationEntity) {
    this.router.navigate(['athesis-root', 'name-face-association', 'image', cardResult.reference]);
  }

  onBindConfirm(cardResult: FaceNameAssociationEntity, name: string, spec: string) {
    this.fnaService.bindImageToName(cardResult.reference, name, spec).subscribe(
      res => this.onSuccessfulImageBind(name),
      err => this.onUnsuccessfulImageBind()
    );
  }

  onBindSimilarImages(similarImages: FaceNameAssociationEntity[]) {
    similarImages.forEach((image, index) => {
      if (index + 1 === similarImages.length) {
        this.fnaService.bindImageToName(image.reference, this.lastBoundName, null).subscribe(
          res => this.onSuccessfulImageBind(),
          err => this.onUnsuccessfulImageBind()
        );
      } else {
        this.fnaService.bindImageToName(image.reference, name, null);
      }
    });
  }

  onUnsuccessfulImageBind(): void {
    console.log('name-image bind unsuccessful');
    this.lastBoundName = null;
  }

  onSuccessfulImageBind(name?: string): void {
    if (name) {
      this.lastBoundName = name;
    }
    this.getAllNfaResults(true);
  }

  onSuccessfulNfaResults(res: FaceNameAssociationEntity[], callSimilarImages?: boolean): void {
    this.store.dispatch(fnaResults(res));
    if (callSimilarImages) {
      this.getAllSimilarImages();
    }
  }

  onUnsuccessfulNfaResults(): void {
    console.log('nfaResults unsuccessful');
  }

  getAllNfaResults(callSimilarImages?: boolean) {
    this.fnaService.getNfaResults().subscribe(
      res => this.onSuccessfulNfaResults(res, callSimilarImages),
      err => this.onUnsuccessfulNfaResults()
    );
  }

  onCustomNameConfirm(event: { name: string, spec: string }, cardResult: FaceNameAssociationEntity) {
    this.onBindConfirm(cardResult, event.name, event.spec);
  }

  ngOnDestroy() {
    if (this.faceNameAssociationSub) {
      this.faceNameAssociationSub.unsubscribe();
    }
  }

  getAllSimilarImages() {
    this.fnaService.getAllegedImages(this.lastBoundName).subscribe(
      res => this.onAllegedImagesSuccessful(res),
      err => this.OnAllegedImagesUnsuccessful()
    );
  }

  OnAllegedImagesUnsuccessful(): void {
    console.log('alleged images unsuccessful');
    this.similarImages = null;
  }

  onAllegedImagesSuccessful(res: FaceNameAssociationEntity[]): void {
    if (res && res.length > 0) {
      this.similarImages = res;
    } else {
      this.similarImages = null;
    }
  }
}
