import { RoleEntity } from '../models';

export class AuthUser {
    deleted: boolean;
    email: string;
    enabled: boolean;
    id: number;
    name: string;
    roles: RoleEntity[];
    surname: string;

    hasPermission(permission: string) {
        return !!this.roles && !!this.roles.map(role => role.permissions)
            .reduce((acc, item) => acc.concat(item), [])
            .find(item => item.code === permission);
    }

    hasRole(role: string) {
        return !!this.roles && !!this.roles.find(item => item.code === role);
    }

    isAdmin() {
        return !!this.roles && !!this.roles.find(item => item.code === 'ADMIN');
    }

    get permissions() {
        return !!this.roles && this.roles.map(role => role.permissions)
        .reduce((acc, item) => acc.concat(item), [])
        .map(item => item.code);
    }
}
