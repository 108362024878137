import { Component, OnInit } from '@angular/core';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { DomainEntity } from 'src/app/models';

@Component({
  selector: 'app-athesis-domain-viewer-table',
  templateUrl: './athesis-domain-viewer-table.component.html',
  styleUrls: ['./athesis-domain-viewer-table.component.scss']
})
export class AthesisDomainViewerTableComponent implements OnInit {
  domains: DomainEntity[];
  constructor(
    private athesisContentMonitorService: AthesisContentMonitorService) {
    this.athesisContentMonitorService.getAllUserDomains().subscribe(
      res => this.onSuccessfulGetAllDomains(res),
      err => this.onUnsuccessfulGetAllDomains()
    );
  }

  ngOnInit() {
  }

  onSuccessfulGetAllDomains(res): void {
    this.domains = res;
  }

  onUnsuccessfulGetAllDomains(): void {
    console.log('unsuccessful get domains');
  }

}
