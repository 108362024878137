import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { GenerateArticleRequest } from '../../models/GenerateArticleRequest';
import { DomainSimple } from '../../models/DomainSimple';
import { AthesisDomainService } from '../../services/domain/athesis-domain.service';
import { AthesisTopicService } from '../../services/athesis-topic.service';
import { TopicEntity } from '../../models';
import { AthesisArticleService } from '../../services/article/athesis-article.service';
import { GenerateArticleResponse } from '../../models/GenerateArticleResponse';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { Page } from '../../models/pagination/page.model';

@Component({
  selector: 'app-athesis-text-generation',
  templateUrl: './athesis-text-generation.component.html',
  styleUrls: ['./athesis-text-generation.component.scss']
})
export class AthesisTextGenerationComponent implements OnInit, AfterViewChecked {

  generateArticleRequest = new GenerateArticleRequest();
  domainList: DomainSimple[];
  topicList: TopicEntity[];
  articleList: { id: string, display: string }[];
  generateArticleResponse: GenerateArticleResponse;
  customArticles: { title: string, display: string }[];
  showTopicSpinner: boolean;
  showArticleSpinner: boolean;
  showSubmitSpinner: boolean;
  page: Page<TopicEntity>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private athesisDomainService: AthesisDomainService,
    private athesisTopicService: AthesisTopicService,
    private athesisArticleService: AthesisArticleService) {

    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.domainId) {
      this.generateArticleRequest.domainId = state.domainId;
      this.onDomainChange();
    }

    this.athesisDomainService.getAllUserDomains().subscribe((response) => {
      this.domainList = response.map(item => {
        return {
          id: item.id,
          name: item.name
        };
      });
    });
  }

  ngOnInit() { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onDomainChange() {
    this.showTopicSpinner = true;
    const params = new HttpParams()
      .set('domainId', this.generateArticleRequest.domainId.toString())
      .set('status', 'APPROVED');

    this.athesisTopicService.getTopicsWithArticles(this.generateArticleRequest.domainId, params).subscribe(response => {
      this.topicList = response;
      this.showTopicSpinner = false;
    });

    this.articleList = [];

    /*
    this.athesisTopicService.getTopics(params).subscribe(response => {
      this.topicList = response.content;
      this.showTopicSpinner = false;
    });
    */
  }

  onSelectedTopic(selectedElementList: TopicEntity[]) {
    this.showArticleSpinner = true;

    this.articleList = selectedElementList.reduce((acc, item) => {
      acc = acc.concat(item.articles.map(article => {
        return {
          id: article.url,
          title: article.name
        };
      }));
      return acc;
    }, []);
    if (this.articleList.length === 0) {
      this.generateArticleRequest.sourceArticles = [];
    }
    this.showArticleSpinner = false;
  }

  onSelectedArticle(selectedArticleList) {
    this.generateArticleRequest.sourceArticles = selectedArticleList.map(item => item.id);
  }
  onSelectedCustomArticle() {
    this.generateArticleRequest.sourceArticles = this.articleList.map(item => item.id).concat(this.customArticles.map(item => item.title));
  }

  generateArticle() {
    this.showSubmitSpinner = true;
    this.athesisArticleService.createArticle(this.generateArticleRequest).subscribe(response => {
      console.debug('AthesisArticleService.createArticle', response);
      this.generateArticleResponse = response;
      this.showSubmitSpinner = false;
    });
  }

  get isRequestValid() {
    return !!this.generateArticleRequest.domainId &&
      (this.generateArticleRequest.sourceArticles && this.generateArticleRequest.sourceArticles.length > 0);
  }
}
