import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TopicEntity } from 'src/app/models';
import { AthesisTopicService } from 'src/app/services/athesis-topic.service';
import { AthesisSemanticSearchServiceService } from 'src/app/services/athesis-semantic-search-service.service';

@Component({
  selector: 'app-athesis-topic-viewer-table',
  templateUrl: './athesis-topic-viewer-table.component.html',
  styleUrls: ['./athesis-topic-viewer-table.component.scss']
})
export class AthesisTopicViewerTableComponent implements OnInit {
  topics: TopicEntity[] = [];
  page = 0;
  constructor(
    private router: Router,
    private store: Store<{}>,
    private semantcSearchService: AthesisSemanticSearchServiceService,
    private athesisTopicService: AthesisTopicService) {

    this.getTopics();
  }

  onSuccessfulGetAllTopics(res): void {
    this.topics = res['content'];
  }

  onUnsuccessfulGetAllTopics(): void {
    console.log('getAllTopics unsuccessful');
  }

  onScroll() {
    this.getTopics();
  }

  getTopics() {
    this.athesisTopicService.getPagedTopics(this.page).subscribe(
      res => { this.topics.push(...res['content']); },
      err => { }
    );
    this.page++;
  }

  ngOnInit() {
  }


}
