import { Component, OnInit } from '@angular/core';
import { AthesisUserService } from '../../../services/athesis-user.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserEntity } from '../../../models';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { ModalsService } from '../../../services/modals/modals.service';
import { UserFormModalComponent } from '../user-form-modal/user-form-modal.component';
import { logout } from 'src/app/redux/actions/athesis-meta-actions';
import { Router } from '@angular/router';
import { PasswordFormModalComponent } from '../password-form-modal/password-form-modal.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent {

  authSub: Subscription;
  user: UserEntity;
  icons = icons;
  isUserAdmin = false;

  constructor(
    private router: Router,
    private store: Store<{}>,
    private userService: AthesisUserService,
    private modalService: ModalsService
  ) {
    this.authSub = this.store.select('auth').subscribe((auth) => {
      if (auth && auth.user) {
        this.user = auth.user;
        this.isUserAdmin = !!auth.user.roles.find(role => role.id === 1);
      }
    });
  }

  editUser() {
    this.modalService.show(UserFormModalComponent, null, this.user, 'modal-xl').observable.subscribe((updatedUser) => {
      this.userService.editUser(updatedUser).subscribe(res => {
        if (this.user.email !== res.email && !this.isUserAdmin) {
          this.router.navigate(['login']);
          this.store.dispatch(logout());
        } else {
          this.user = res;
        }
      });
    });
  }

  editPassword() {
    this.modalService.show(PasswordFormModalComponent, null, this.user, 'modal-md').observable.subscribe((updatedUser) => {
      this.userService.editUser(updatedUser).subscribe();
    });
  }
}
