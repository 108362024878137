import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FaceNameAssociationEntity, PersonEntity } from 'src/app/models';
import { AthesisFaceNameAssociationService } from 'src/app/services/athesis-face-name-association.service';

@Component({
  selector: 'app-athesis-modal-nfa-custom-name',
  templateUrl: './athesis-modal-nfa-custom-name.component.html',
  styleUrls: ['./athesis-modal-nfa-custom-name.component.scss']
})
export class AthesisModalNfaCustomNameComponent {
  @Output() confirmEvent: EventEmitter<{ name: string, spec: string }> = new EventEmitter();
  @Input() imageReference: string;
  autocompleteOptions: string[] = null;

  constructor(private nfaService: AthesisFaceNameAssociationService) { }

  onConfirm(nameToAdd: string, specToAdd: string) {
    this.confirmEvent.emit({ name: nameToAdd, spec: specToAdd });
  }

  onChange(userString: string) {
    if (userString) {
      this.nfaService.getAutocompleteNames(userString).subscribe(
        res => this.onSuccessfulAutocomplete(res),
        err => this.onUnsuccessfulAutocomplete()
      );
    } else {
      this.autocompleteOptions = null;
    }
  }

  onUnsuccessfulAutocomplete(): void {
    console.log('autocomplete unsuccessful');
  }

  onSuccessfulAutocomplete(res: string[]): void {
    this.autocompleteOptions = res;
  }
}
