import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AthesisAuthService } from '../athesis-auth.service';
import { API_BASE_URL, API_VERSION_URL, API_CONTENT_MONITOR_FILES } from '../../constants';
import { FileEntity } from '../../models';

const API_FILES_BY_DOMAIN = (domainId) =>
  `${API_BASE_URL}${API_VERSION_URL}/domains/${domainId}/files`;

@Injectable({
  providedIn: 'root'
})
export class AthesisFileService {

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) { }

  getFilesByDomain(domainId: number, params?: HttpParams) {
    return this.http.get<FileEntity[]>(API_FILES_BY_DOMAIN(domainId), { ...this.athesisAuthService.httpOptions, params });
  }

  getFiles(params?: HttpParams) {
    return this.http.get<FileEntity[]>(API_CONTENT_MONITOR_FILES, { ...this.athesisAuthService.httpOptions, params });
  }
}
