import { Component, OnInit, AfterViewInit, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { isArray } from 'util';

@Component({
  selector: 'app-full-width-image-modal',
  templateUrl: './full-width-image-modal.component.html',
  styleUrls: ['./full-width-image-modal.component.scss']
})
export class FullWidthImageModalComponent implements AfterViewInit {

  item: any;
  modalConfig: any;
  template: TemplateRef<any>;
  onConfirm: Function;
  onCancel: Function;

  get coords() {
    if (this.item.coords && !isArray(this.item.coords)) {
      return [this.item.coords];
    }

    return this.item.coords;
  }

  constructor(private modalRef: BsModalRef) {  }

  ngAfterViewInit() {
  }

  confirm() {
    this.onConfirm();
    this.modalRef.hide();
  }

  cancel() {
    this.onCancel();
    this.modalRef.hide();
  }
}
