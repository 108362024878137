import { createReducer, on } from '@ngrx/store';
import { UserEntity } from 'src/app/models';
import { users } from '../actions/athesis-user-actions';

export interface State {
    users: UserEntity[];
}

export const initialState: State = {
    users: null
};

function mergeState(state, newState) {
    return {
        ...state, ...newState
    };
}

export const userReducer = createReducer(initialState,
    on(users, (state, {payload}) => mergeState(state, {users: payload})),
);
