import { Component, OnInit } from '@angular/core';
import { AthesisUserService } from 'src/app/services/athesis-user.service';

const PAGE_SIZE_DEFAULT = 10;
@Component({
  selector: 'app-athesis-backoffice-error-log',
  templateUrl: './athesis-backoffice-error-log.component.html',
  styleUrls: ['./athesis-backoffice-error-log.component.scss']
})
export class AthesisBackofficeErrorLogComponent {
  adminErrorLog = [];
  currentPage = 0;

  constructor(private athesisUserService: AthesisUserService) {
    this.getErrorLog();
  }

  onUnsuccessfulGetAdminErrorLog(): void {
    console.log('getAdminErrorLog unsuccessful');
  }
  onSuccessfulGetAdminErrorLog(res): void {
    this.adminErrorLog.push(...res.content);
  }
  onScroll() {
    this.currentPage += 1;
    this.getErrorLog();
  }
  getErrorLog() {
    this.athesisUserService.getAdminErrorLog( this.currentPage, PAGE_SIZE_DEFAULT).subscribe(
      res => this.onSuccessfulGetAdminErrorLog(res),
      err => this.onUnsuccessfulGetAdminErrorLog()
    );
  }
}
