import { Injectable } from '@angular/core';

import { API_CONTENT_MONITOR_DOMAINS, API_CONTENT_MONITOR_DOMAIN_BY_ID, API_CONTENT_MONITOR_DOMAINS_SEARCH, API_CONTENT_MONITOR_UPDATE_DOMAIN } from '../../constants';
import { AthesisAuthService } from '../athesis-auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomainEntity } from '../../models';
import { DomainSimple } from '../../models/DomainSimple';
import { Page } from '../../models/pagination/page.model';
import { Domain } from '../../models/domain/domain.model';
import { DomainRequest } from '../../models/domain/domain-request.model';

@Injectable({
  providedIn: 'root'
})
export class AthesisDomainService {

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) { }

  getAllUserDomains() {
    return this.http.get<DomainEntity[]>(API_CONTENT_MONITOR_DOMAINS, this.athesisAuthService.httpOptions);
  }
  getDomain(domainId: number) {
    return this.http.get<Domain>(API_CONTENT_MONITOR_DOMAIN_BY_ID(domainId), this.athesisAuthService.httpOptions);
  }
  getDomains(params?: HttpParams) {
    return this.http.get<Page<DomainEntity>>(API_CONTENT_MONITOR_DOMAINS_SEARCH, { ...this.athesisAuthService.httpOptions, params });
  }
}
