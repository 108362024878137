import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { GenericSearchResponseEntity } from '../../../models';
import { ImageSearchParams } from '../../../components/athesis-image-similar-search/athesis-image-similar-search.component';
import { ImageTreeHelper } from '../../../components/athesis-image-similar-search/image-tree.helper';
import { VideosService } from '../../../services/videos.service';

const PAGE_SIZE_DEFAULT = 10;

@Component({
    selector: 'app-video-similar-search',
    templateUrl: './video-similar-search.component.html'
})
export class VideoSimilarSearchComponent implements OnInit {

    icons = icons;
    items: TreeviewItem[] = null;
    values: number[];
    value: any;
    faPlus = icons.faPlus;
    faMinus = icons.faMinus;
    faCaretDown = icons.faCaretDown;
    faCaretUp = icons.faCaretUp;
    isContentOpen = true;
    currentPage = 0;
    config = TreeviewConfig.create({
        hasFilter: true,
        hasCollapseExpand: true,
        decoupleChildFromParent: false,
        hasAllCheckBox: false
    });
    loading = false;
    totalElements: number;
    searchResults: GenericSearchResponseEntity[] = null;
    selectedNode: TreeviewItem;
    isSearchOpen = true;
    searchParams: ImageSearchParams;

    @Output() scrollList: EventEmitter<any> = new EventEmitter();

    constructor(
        private videoService: VideosService,
    ) {}

    ngOnInit() {
        this.loadTree();
    }

    private loadTree(isUpdate?) {
        this.loading = true;
        this.videoService.getVideoTreeFull().subscribe(
            items => {
                this.items = ImageTreeHelper.createTree(items, (node) =>
                    this.selectedNode && (node.nodeId === this.selectedNode.value.nodeId)
                );
                if (isUpdate) {
                    this.selectedNode.value.imagesCount = this.selectedNode.value.imagesCount - 1;
                    this.totalElements -= 1;
                }
                this.loading = false;
            }
        );
    }

    handleTreeClick(item) {
        this.searchResults = null;
        this.currentPage = 0;
        this.selectedNode = item;
        this.searchParams = {
            nodeId: this.selectedNode.value.nodeId
        };
        this.search();
    }

    onScroll() {
        this.currentPage += 1;
        if ((this.totalElements > (PAGE_SIZE_DEFAULT * this.currentPage)) && !this.isSearchOpen) {
            this.search(this.currentPage, PAGE_SIZE_DEFAULT);
        }
    }

    search(page = 0, size = PAGE_SIZE_DEFAULT): void {
        this.loading = true;
        this.videoService.getVideos({...this.searchParams, page, size})
            .subscribe(items => {
                if (this.searchResults === null) {
                    this.searchResults = [];
                }
                if (items.content.length === 0) {
                    this.searchResults = [];
                } else {
                    this.searchResults.push(...items.content);
                }
                this.totalElements = items.totalElements;

                this.loading = false;
                this.isSearchOpen = false;
            });
    }

    onSearchByDate(searchForm: {dateFrom: string, dateTo: string}) {
        this.searchParams = {
            ...this.searchParams,
            ...searchForm
        };
        this.searchResults = [];
        this.search();
    }

    handleElementUpdate(imageId: string) {
        this.searchResults = this.searchResults.filter(item => item.imageId !== imageId);
        this.loadTree(true);
    }
}
