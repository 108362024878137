import {AfterViewInit, Component, EventEmitter, OnInit, Output, TemplateRef} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {faMinus} from '@fortawesome/free-solid-svg-icons';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons';
import {GenericSearchResponseEntity} from '../../../models';
import {AthesisTreeService} from '../../../services/athesis-tree.service';
import { ImageTreeHelper } from '../image-tree.helper';

@Component({
  selector: 'app-athesis-modal-replace-image',
  templateUrl: './athesis-modal-replace-image.component.html',
  styleUrls: ['./athesis-modal-replace-image.component.scss']
})
export class AthesisModalReplaceImageComponent {
  icons = icons;
  values: number[];
  value: any;

  faPlus = faPlus;
  faMinus = faMinus;
  faCaretDown = faCaretDown;

  config = TreeviewConfig.create({
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    hasAllCheckBox: false
  });
  loading = false;
  totalElements: number;
  searchResults: GenericSearchResponseEntity[] = null;
  selectedNode: TreeviewItem;
  isSearchOpen = true;
  item: any;
  modalConfig: any;
  template: TemplateRef<any>;
  onConfirm: Function;
  onCancel: Function;

  constructor(private modalRef: BsModalRef, private athesisTreeService: AthesisTreeService) { }

  get items() {
    return this.item.classificationTree;
  }

  get treeItemLabel() {
    return this.item.treeItemLabel;
  }

  handleTreeClick(item) {
    this.selectedNode = item;
  }

  confirm() {
    this.loading = true;
    this.item.callback(this.item.imageId, this.selectedNode.value.nodeId, this.item.currentNode).subscribe(() => {
      this.loading = false;
      this.onConfirm();
      this.modalRef.hide();
    }, error => {
      this.loading = false;
    });
  }
  cancel() {
    this.onCancel();
    this.modalRef.hide();
  }

}
