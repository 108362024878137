import { Component, OnInit } from '@angular/core';
import { AthesisNotificationService } from 'src/app/services/athesis-notification.service';
import { Store } from '@ngrx/store';
import { UserEntity, NotificationEntity } from 'src/app/models';

@Component({
  selector: 'app-athesis-notifications-page',
  templateUrl: './athesis-notifications-page.component.html',
  styleUrls: ['./athesis-notifications-page.component.scss']
})
export class AthesisNotificationsPageComponent implements OnInit {
  badgeCount;
  content;
  read;
  user: UserEntity;
  displayModal: boolean;
  notificationId: number;
  page = 0;

  constructor(private notificationService: AthesisNotificationService, private store: Store<{ user: UserEntity, notification: NotificationEntity }>) {
    store.select('auth').subscribe((auth) => {
      if (auth && auth.user) {
        this.user = auth.user;
        this.getAllNotification();
      }
    });
  }
  ngOnInit() {

  }


  /* chiamate api ------------------------------------------------------ */
  readNotification(notificationId: number) {
    this.notificationService.readNotification(notificationId, this.user.id).subscribe(readNotificationResponse => {
      this.read = readNotificationResponse;
    });
  }

  getAllNotification() {
    this.notificationService.allNotification(this.user.id).subscribe(notificationResponse => {
      this.content = notificationResponse['content'];
      /* this.notificationList = notificationResponse['content'].slice(0); */
    });
  }

  onScroll() {
    this.page++;
    this.notificationService.allNotification(this.user.id, this.page).subscribe(notificationResponse => {
      this.content.push(...notificationResponse['content']);

    });
  }

  getUnreadNotification() {
    this.notificationService.notReadNotification(this.user.id).subscribe(notificationResponse => {
      this.content = notificationResponse['content'].slice(0, 6);
    });
  }
}
