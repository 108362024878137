import { Component, OnInit, OnDestroy } from '@angular/core';
import { ArticleEntity, ArticleDto } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-athesis-article-editor',
  templateUrl: './athesis-article-editor.component.html',
  styleUrls: ['./athesis-article-editor.component.scss']
})
export class AthesisArticleEditorComponent implements OnDestroy {
  contentMonitorSub: Subscription = null;
  articleId: number = null;
  domainId: number = null;
  selectedArticle: ArticleEntity = null;

  constructor(private route: ActivatedRoute, private store: Store<{}>, private athesisContentMonitorService: AthesisContentMonitorService,
    private router: Router, private athesisContentMonitorHelperService: AthesisContentMonitorHelperService) {
    this.articleId = Number(route.snapshot.params.articleId);
    this.domainId = Number(route.snapshot.params.domainId);

    this.contentMonitorSub = store.select('contentMonitor').subscribe(contentMonitor => {
      if (contentMonitor.domains) {
        this.selectedArticle = contentMonitor.domains.filter(domain => domain.id === this.domainId)[0]
          .articles.filter(article => article.id === this.articleId)[0];
      } else {
        athesisContentMonitorHelperService.getAllDomains();
      }
    });
  }

  confirmEditArticle(articleTitle: string, articleContent: string) {
    const article: ArticleDto = {
      article: articleContent,
      name: articleTitle,
      articles: this.selectedArticle.articles,
      idDomain: this.domainId
    };
    this.athesisContentMonitorService.editArticle(article, this.selectedArticle.id).subscribe(
      res => this.onElementEditSuccessful(),
      err => this.onElementEditUnsuccessful()
    );
  }

  onElementEditUnsuccessful(): void {
    console.log('file add unsuccessful');
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  onElementEditSuccessful(): void {
    this.athesisContentMonitorHelperService.getAllDomains();
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  returnToDomainView() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  ngOnDestroy(): void {
    // Clean store from previous domains
    if (this.contentMonitorSub) {
      this.contentMonitorSub.unsubscribe();
    }
  }
}
