import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { API_AUTH_LOGIN, HTTP_HEADERS_BASE, HTTP_HEADERS_BASE_UPLOAD } from '../constants';
import { Observable } from 'rxjs';
import { UserEntity } from '../models';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  tokenType: string;
  user: UserEntity;
}

@Injectable({
  providedIn: 'root'
})

export class AthesisAuthService {
  isLoggedIn$: Observable<boolean>;
  public httpOptions = { headers: new HttpHeaders({...HTTP_HEADERS_BASE})};
  public httpOptionsUpload = { headers: new HttpHeaders({...HTTP_HEADERS_BASE_UPLOAD})};
  public httpOptionsDownload = { headers: new HttpHeaders({...HTTP_HEADERS_BASE_UPLOAD}), responseType: 'blob' as 'json'};

  constructor(private http: HttpClient, private store: Store<{ isAuthenticated: boolean }>) {
    this.isLoggedIn$ = store.pipe(select('isAuthenticated'));
  }

  attemptLogin(loginRequest: LoginRequest) {
    return this.http.post<LoginResponse>(API_AUTH_LOGIN, loginRequest, this.httpOptions);
  }
}
