import { Component, OnInit } from '@angular/core';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { AthesisDomainService } from '../../services/domain/athesis-domain.service';
import { DomainRequest } from '../../models/domain/domain-request.model';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';



@Component({
  selector: 'app-athesis-domain-creator',
  templateUrl: './athesis-domain-creator.component.html',
  styleUrls: ['./athesis-domain-creator.component.scss']
})
export class AthesisDomainCreatorComponent implements OnInit {

  domainId: string;
  domain: DomainRequest;
  icons = icons;
  form: FormGroup;
  submitted = false;


  constructor(
    private athesisDomainService: AthesisDomainService,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private router: Router,
    private route: ActivatedRoute,
    private athesisContentMonitorHelperService: AthesisContentMonitorHelperService,
    private fb: FormBuilder) {

    const state = this.router.getCurrentNavigation().extras.state;

    route.paramMap.subscribe(response => {
      this.domainId = response.get('domainId');

      if (state && state.domain) {
        this.domain = state.domain;
      }

      if (!state && this.domainId) {
        this.getDomainById();
      }
    });
  }

  ngOnInit() {
    this.form = this.buildForm(this.fb);

    if (this.domain) {
      this.form.setValue(this.mapDomain(this.domain));
    }
  }

  buildForm(fb: FormBuilder): FormGroup {
    return fb.group({
      name: fb.control(null, Validators.required),
      forget: fb.control(null, Validators.required),
      threshold: fb.control(null, Validators.required)
    });
  }

  saveDomain() {
    if (this.domainId) {
      this.updateDomain();
    } else {
      this.createDomain();
    }
  }
  private updateDomain() {
    this.athesisContentMonitorService.updateDomain(Number(this.domainId), this.getDomainRequest()).subscribe(
      res => {
        this.onDomainCreationSuccessful();
      },
      err => {
        this.onDomainCreationUnsuccessful();
      }
    );
  }
  private createDomain() {
    this.athesisContentMonitorService.createDomain(this.getDomainRequest()).subscribe(
      res => {
        this.onDomainCreationSuccessful();
      },
      err => {
        this.onDomainCreationUnsuccessful();
      }
    );
  }
  onDomainCreationUnsuccessful() {
    console.log('onDomainCreationUnsuccessful');
    this.router.navigate(['athesis-root', 'content-monitor', 'domains']);
  }
  onDomainCreationSuccessful() {
    this.athesisContentMonitorHelperService.getAllDomains();
    this.router.navigate(['athesis-root', 'content-monitor', 'domains']);
  }

  onAllDomainsRetrievalUnsuccessful() {
    console.log('domain retrieval unsuccessful');
  }

  private getDomainById() {
    this.athesisDomainService.getDomain(Number(this.domainId)).subscribe(response => {
      this.domain = new DomainRequest(response);
      this.form.setValue(this.mapDomain(this.domain));
    });
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.saveDomain();
    }
  }

  private mapDomain(domain) {
    return {
      name: domain.name,
      forget: domain.forget,
      threshold: domain.threshold
    };
  }

  private getDomainRequest() {
    let domainRequest = new DomainRequest();
    if (this.domain) {
      domainRequest = this.domain;
    }
    domainRequest.name = this.form.value.name;
    domainRequest.forget = this.form.value.forget;
    domainRequest.threshold = this.form.value.threshold;

    return domainRequest;
  }
}
