import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDomainEntity } from 'src/app/models';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-athesis-user-viewer',
  templateUrl: './athesis-user-viewer.component.html'
})
export class AthesisUserViewerComponent implements OnDestroy {
  authSub: Subscription = null;
  contentMonitorSub: Subscription = null;
  domainId: number = null;
  userId: number = null;
  loggedInUserId: number = null;
  selectedUser: UserDomainEntity = null;

  constructor(
    private route: ActivatedRoute,
    private store: Store<{}>,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private router: Router,
    private athesisContentMonitorHelperService: AthesisContentMonitorHelperService) {

    this.domainId = Number(route.snapshot.params.domainId);
    this.userId = Number(route.snapshot.params.userId);

    store.select('auth').subscribe((auth) => {
      if (auth && auth.user) {
        this.loggedInUserId = auth.user.id;
      }
    });

    this.athesisContentMonitorService
      .getUserDomain(this.domainId)
      .subscribe(
        res => {
          this.selectedUser = res.userDomainEntities.filter(user => user.id === this.userId)[0];;
        },
        err => { }
      );
    /* store.select('contentMonitor').subscribe(contentMonitor => {
      if (contentMonitor.domains) {
        this.selectedUser = contentMonitor.domains.filter(domain => domain.id === this.domainId)[0]
          .userDomainEntities.filter(user => user.id === this.userId)[0];
      } else {
        athesisContentMonitorHelperService.getAllDomains();
      }
    }); */
  }

  returnToUsersView() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'users']);
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.contentMonitorSub) {
      this.contentMonitorSub.unsubscribe();
    }
  }

  editUserWritePermission(userDomainEntity: UserDomainEntity, canWrite: boolean) {
    this.athesisContentMonitorService.editDomainUserWritePermission(
      this.domainId,
      userDomainEntity.user.id,
      userDomainEntity.id, canWrite).subscribe(
        res => this.onElementEditSuccessful(),
        err => this.onElementEditUnsuccessful()
      );
  }

  onElementEditUnsuccessful(): void {
    console.log('user write permission edit unsuccessful');
    this.returnToUsersView();
  }
  onElementEditSuccessful(): void {
    this.athesisContentMonitorHelperService.getAllDomains();
    this.returnToUsersView();
  }
}
