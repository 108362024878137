import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AdminAppGuardService implements CanActivate {
  isAdmin = false;

  constructor(public router: Router, private store: Store<{ isAuthenticated: boolean }>) {
      this.store.select('auth').subscribe((auth) => {
        if (auth && auth.user) {
          this.isAdmin = auth.user.roles.filter(role => role.id === 1).length > 0;
        }
      });
  }

  canActivate(): boolean {
      if (!this.isAdmin) {
          this.router.navigate(['athesis-root', 'dashboard']);
          return false;
      }
      return true;
  }
}
