import { ConferringSystem } from './conferring-system.model';
import { Newspaper } from './newspaper.model';
import { FaceCoordinates } from './face-coordinates.model';
import { RectCoords } from '../../models/canvas/rect-coords.model';

export class FaceForGalleryDto {
    conferringSystem: ConferringSystem;
    confirmedReference:	string;
    faceCoordinates: FaceCoordinates;
    imageId:	string;
    imageUrl:	string;
    newspaper:	Newspaper;
    registryId:	string;

    get coords(): RectCoords {
        return {
            left: this.faceCoordinates.x,
            top: this.faceCoordinates.y,
            width: this.faceCoordinates.width,
            height: this.faceCoordinates.height
        };
    }
}
