import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  showSuccess(message, title) {
      this.toastr.success(message, title);
  }

  showError(message, title) {
      this.toastr.error(message, title);
  }

  showInfo(message, title) {
      this.toastr.info(message, title);
  }

  showWarning(message, title) {
      this.toastr.warning(message, title);
  }

  handleError(error: any, message?: string) {
    this.translateService.get(['GENERAL.ERROR', message]).subscribe(res => {
      this.showError(res[message] || error.message, res['GENERAL.ERROR']);
    });
  }

  handleErrorWithParams(error: any, message?: string, parameter?: any) {
    this.translateService.get(['GENERAL.ERROR', message], parameter).subscribe(res => {
      this.showError(res[message] || error.message + ' ' + parameter, res['GENERAL.ERROR']);
    });
  }

  handleSuccess(message: string) {
    this.translateService.get(['GENERAL.SUCCESS', message]).subscribe(res => {
      this.showSuccess(res[message], res['GENERAL.SUCCESS']);
    });
  }

  handleMessages(successMessage: string, errorMessage?: string) {
    return tap(
      () => this.handleSuccess(successMessage),
      (error) => this.handleError(error, errorMessage)
    );
  }
}
