import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-athesis-modal-confirm',
  templateUrl: './athesis-modal-confirm.component.html',
  styleUrls: ['./athesis-modal-confirm.component.scss']
})
export class AthesisModalConfirmComponent implements OnInit {

  @Output() confirmEvent: EventEmitter<any> = new EventEmitter();
  @Input() modalLabel: string;
  @Input() modalText: string;

  constructor() { }

  emitConfirmEvent() {
    this.confirmEvent.emit();
  }


  ngOnInit() {
  }

}
