import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_BASE_URL, API_VERSION_URL } from '../constants';
import { AthesisAuthService } from './athesis-auth.service';
import { TreeviewItem } from 'ngx-treeview';
import { GenericSearchResponse } from '../models';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MessageService } from './message.service';


export interface VideosSearchParams {
    dateFrom?: string;
    dateTo?: string;
    nodeId?: string;
    page?: number;
    size?: number;
    sort?: string[];
}

@Injectable({providedIn: 'root'})
export class VideosService {

    baseUrl = API_BASE_URL + API_VERSION_URL + '/videos';

    constructor(
        private http: HttpClient,
        private athesisAuthService: AthesisAuthService,
        private messageService: MessageService
    ) {}

    getVideos(searchParams?: VideosSearchParams) {

        let params = new HttpParams();

        if (searchParams) {
            Object.keys(searchParams).filter(key => !!searchParams[key]).forEach(item => {
                params = params.append(item, searchParams[item]);
            });
        }

        return this.http.get<GenericSearchResponse>(
            `${this.baseUrl}`,
            {
                ...this.athesisAuthService.httpOptions,
                params
            }
        );
    }
    getVideoTreeFull(showEmpty = false) {
        const params = new HttpParams()
            .set('show-empty', '' + !!showEmpty);

        return this.http.get<TreeviewItem[]>(
            `${this.baseUrl}/tree`,
            {
                ...this.athesisAuthService.httpOptions,
                params
            }
        );
    }

    getVideosTreeRoot() {
        return this.http.get<TreeviewItem[]>(
            `${this.baseUrl}/tree/root`,
            this.athesisAuthService.httpOptions
        );
    }

    getVideoTreeByNode(noodeId: string, showEmpty = false) {
        const params = new HttpParams()
            .set('show-empty', '' + !!showEmpty);

        return this.http.get<TreeviewItem[]>(
            `${this.baseUrl}/tree/${noodeId}/children`,
            {
                ...this.athesisAuthService.httpOptions,
                params
            }
        );
    }

    updateVideo(imageId, targetNodeId, actualNodeId) {

        return this.updateClassification(imageId, actualNodeId, targetNodeId).pipe(
            tap(res => {
                this.messageService.handleSuccess('VIDEO_SIMILAR_SEARCH.UPDATE.SUCCESS');
            }),
            catchError(error => this.handleError(error, 'UPDATE'))
        );
    }

    deleteVideo(imageId, actualNodeId) {

        return this.updateClassification(imageId, actualNodeId, null).pipe(
            tap(res => {
                this.messageService.handleSuccess('VIDEO_SIMILAR_SEARCH.DELETE.SUCCESS');
            }),
            catchError(error => this.handleError(error, 'DELETE'))
        );
    }

    updateClassification(imageId, actualNodeId, targetNodeId) {
        const url = `${this.baseUrl}/tree/classification`;

        return this.http.put(url, {
            actualNodeId,
            targetNodeId,
            imageId
        }, this.athesisAuthService.httpOptions);
    }

    private handleError(error, action) {
        if (error.status === 500) {
            this.messageService.handleError(error);
        }
        if (error.status === 400) {
            error.error.errors.array.forEach(err => {
              this.messageService.handleErrorWithParams(error, `VIDEO_SIMILAR_SEARCH.${action}.ERROR`, {codes: err.codes.join(', ')});
            });
        }

        this.messageService.handleError(error, 'GENERAL.GENERIC_ERROR');

        return throwError(error);
    }
}
