import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDomainEntity, UserEntity } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { AthesisContentMonitorHelperService } from 'src/app/services/athesis-content-monitor-helper.service';
import { Subscription } from 'rxjs';
import { AthesisUserService } from 'src/app/services/athesis-user.service';
import * as icons from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-athesis-user-adder',
  templateUrl: './athesis-user-adder.component.html'
})
export class AthesisUserAdderComponent implements OnDestroy {
  userManagementSub: Subscription = null;
  contentMonitorSub: Subscription = null;
  domainId: number = null;
  allUsers: UserEntity[] = null;
  selectedUsers: UserDomainEntity[] = null;
  unselectedUsers: UserEntity[] = null;
  icons = icons;

  constructor(
    private route: ActivatedRoute,
    private store: Store<{}>,
    private athesisUserService: AthesisUserService,
    private athesisContentMonitorHelperService: AthesisContentMonitorHelperService,
    private athesisContentMonitorService: AthesisContentMonitorService,
    private router: Router) {

    this.domainId = Number(route.snapshot.params.domainId);

    this.getAllUsers();
  }

  getUserDomain() {
    this.athesisContentMonitorService.getUserDomain(this.domainId).subscribe(response => {
      this.selectedUsers = response.userDomainEntities;
      this.attemptFilterUsers();
    }, error => {
      this.athesisContentMonitorHelperService.getAllDomains();
    });
  }

  getAllUsers() {
    this.athesisUserService.getAllActiveUsers().subscribe(res => this.onGetAllUsersSuccessful(res), err => this.onGetAllUersUnsuccessful());
  }

  onGetAllUersUnsuccessful(): void {
    console.log('getAllUsers unsuccessful');
  }

  onGetAllUsersSuccessful(res: UserEntity[]): void {
    this.allUsers = res;
    this.getUserDomain();
  }

  attemptFilterUsers() {
    if (this.allUsers && this.selectedUsers) {
      this.unselectedUsers = this.allUsers
        .filter(user => !this.selectedUsers.map(userDomainEntity => userDomainEntity.user.id).includes(user.id));
    }
  }

  onUserSelect(user: UserDomainEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'user', user.id]);
  }

  gotoAddUser() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'add-user']);
  }

  onUserAdd(user: UserDomainEntity) {
    this.athesisContentMonitorService.addUserToDomain(user.id, false, this.domainId).subscribe(
      res => this.onSuccessfulUserAdd(),
      err => this.onUnsuccessfulUserAdd()
    );
  }

  onUnsuccessfulUserAdd(): void {
    console.log('user add unsuccessful');
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'users']);
  }

  onSuccessfulUserAdd(): void {
    this.athesisContentMonitorHelperService.getAllDomains();
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'users']);
  }

  ngOnDestroy() {
    if (this.userManagementSub) {
      this.userManagementSub.unsubscribe();
    }
  }
}
