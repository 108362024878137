import { createReducer, on } from '@ngrx/store';
import { imageSearch } from '../actions/athesis-face-name-association-actions';
import { DomainEntity } from '../../models';
import { domains } from '../actions/athesis-content-monitor-actions';

export interface State {
    domains: DomainEntity[];
}

export const initialState: State = {
    domains: null
};

function mergeState(state, newState) {
    return {
        ...state, ...newState
    };
}

export const contentMonitorReducer = createReducer(initialState,
    on(domains, (state, {payload}) => mergeState(state, {domains: payload})),
);

