import {Component, Input} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {NewspaperEntity, ConferringSystemEntity, GenericSearchResponseEntity} from 'src/app/models';
import { AthesisSemanticSearchServiceService, SearchSimilarPolopolyRequest } from 'src/app/services/athesis-semantic-search-service.service';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

export interface NewspaperEntityCheckbox extends NewspaperEntity {
  checked?: boolean;
}

export interface ConferringSystemEntityCheckbox extends ConferringSystemEntity {
  checked?: boolean;
}

@Component({
  selector: 'app-athesis-polopoly-similar-search',
  templateUrl: './athesis-polopoly-similar-search.component.html',
  styleUrls: ['./athesis-polopoly-similar-search.component.scss']
})

export class AthesisPolopolySimilarSearchComponent {
  icons = icons;
  searchResults;
  newspapers: NewspaperEntityCheckbox[];
  conferringSystems: ConferringSystemEntityCheckbox[];
  polopolyId: string;
  loading: boolean = null;
  activeTab = 'TEXT';
  error = false;
  isContentOpen = true;

  constructor(private athesisSemanticSearchServiceService: AthesisSemanticSearchServiceService) {
    this.athesisSemanticSearchServiceService.getNewspapers().pipe(catchError(this.errorHandler)).subscribe(
      res => {
        this.newspapers = res.map(r => ({...r, checked: false}));
        this.newspapers[0].checked = true;
      },
      err => {
      }
    );
    this.loading = false;
    athesisSemanticSearchServiceService.getConferringSystems().pipe(catchError(this.errorHandler)).subscribe(
      res => {
        this.conferringSystems = res.map(r => ({...r, checked: false}));
        this.conferringSystems[0].checked = true;
      },
      err => {
      }
    );
  }
  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || 'server error');
  }

  search(searchType): void {
    this.activeTab = searchType;
    const selectedConferringSystems = this.conferringSystems.filter(cs => cs.checked).map(cs => cs.code);
    const selectedNewspaper = this.newspapers.filter(cs => cs.checked).map(cs => cs.code);

    const searchForm: SearchSimilarPolopolyRequest = {
      conferringSystemsCodes: selectedConferringSystems,
      idPolopoly: this.polopolyId,
      newspaperCodes: selectedNewspaper,
      responseType: searchType
    };

    this.athesisSemanticSearchServiceService.getResultsSimilarPolopoly(searchForm)
      .subscribe(
        res => this.onSuccessfulSearchResult(res),
        err => this.onUnsuccessfulSearchResult(),
      );
    this.loading = true;
    this.searchResults = [];
  }
  onUnsuccessfulSearchResult(): void {
    console.log('keyword search unsuccessful');
    this.error = true;
    this.searchResults = null;
    this.loading = false;
  }

  onSuccessfulSearchResult(res: GenericSearchResponseEntity[]): void {
    this.searchResults = res;
    this.loading = false;
  }

  tabChanged(tabName) {
    if (tabName !== this.activeTab) {
      this.search(tabName);
    }
  }
}
