import { ConferringSystem } from './conferring-system.model';
import { Newspaper } from './newspaper.model';
import { RegistrySimpleDto } from './registry-simple-dto.model';
import { FaceCoordinates } from './face-coordinates.model';
import { RectCoords } from '../../models/canvas/rect-coords.model';
import { DocuwebRegistryResponse } from '../../models/docuweb-registry-response.model';

export class FaceNotAssociatedResponse {
    clusterId: number;
    conferringSystem: ConferringSystem;
    faceCoordinates: FaceCoordinates;
    imageId: string;
    imageUrl: string;
    imagesOccurrences: number;
    newspaper: Newspaper;
    possibleNames: RegistrySimpleDto[];
    reference: string;
    selected?: boolean;
    articles?: DocuwebRegistryResponse[];

    get registryId() {
        return this.possibleNames
            .filter((item, index) => index === 0)
            .map(item => item.id)
            .reduce((acc, item) => item, null);
    }

    get coords(): RectCoords {
        return {
            left: this.faceCoordinates.x,
            top: this.faceCoordinates.y,
            width: this.faceCoordinates.width,
            height: this.faceCoordinates.height
        };
    }
}
