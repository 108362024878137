import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageService } from './message.service';
import { Store } from '@ngrx/store';
import { UserEntity } from '../models';
import { logout } from '../redux/actions/athesis-meta-actions';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    isAuthenticated = false;
    constructor(
        private messageService: MessageService,
        private store: Store<{ isAuthenticated: boolean }>) {
            this.store.select('auth').subscribe((auth) => {
                this.isAuthenticated = auth ? auth.isAuthenticated : false;
            });
        }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 && this.isAuthenticated) {
                console.debug('intercept 401', err);
                this.store.dispatch(logout());
                this.messageService.handleError(err, 'ERRORS.SESSION_EXPIRED');
            }

            if (err.status === 403) {
                console.debug('intercept 403', err);
                this.messageService.handleError(err, 'ERRORS.UNAUTHORIZED');
            }

            if (err.status === 500) {
                this.messageService.handleError(err, 'ERRORS.GENERIC_ERROR');
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
