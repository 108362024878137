import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';
import { GenericSearchResponseEntity } from 'src/app/models';

@Component({
  selector: 'app-athesis-generic-search-response',
  templateUrl: './athesis-generic-search-response.component.html',
  styleUrls: ['./athesis-generic-search-response.component.scss']
})
export class AthesisGenericSearchResponseComponent {
  @Input() activeTab;
  @Input() loading = false;
  @Input() searchResults: GenericSearchResponseEntity[];
  @Output() tabChanged = new EventEmitter();

  tabChangedInternal(value: string) {
    this.tabChanged.emit(value);
  }
}
