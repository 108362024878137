import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AthesisAuthService } from './athesis-auth.service';
import { API_ARCHIVED_DIAGNOSTIC_DATA, API_ERRORS_DIAGNOSTIC_DATA, API_NOT_ARCHIVED_DIAGNOSTIC_DATA, API_GENERAL_DIAGNOSTIC_COUNTER, API_RECORDED_DIAGNOSTIC_DATA } from '../constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticService {

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) { }

  getArchivedDiagnosticData() {
    return this.http.get(API_ARCHIVED_DIAGNOSTIC_DATA, this.athesisAuthService.httpOptions);
  }
  getErrorsDiagnosticData() {
    return this.http.get(API_ERRORS_DIAGNOSTIC_DATA, this.athesisAuthService.httpOptions);
  }
  getNotArchivedDiagnosticData() {
    return this.http.get(API_NOT_ARCHIVED_DIAGNOSTIC_DATA, this.athesisAuthService.httpOptions);
  }
  getRecordedDiagnosticData() {
    return this.http.get(API_RECORDED_DIAGNOSTIC_DATA, this.athesisAuthService.httpOptions);
  }
  getDiagnosticCounter() {
    return this.http.get(API_GENERAL_DIAGNOSTIC_COUNTER, this.athesisAuthService.httpOptions);
  }
}
