import { Injectable } from '@angular/core';
import { AthesisAuthService } from '../athesis-auth.service';
import { HttpClient } from '@angular/common/http';
import { GenerateArticleRequest } from '../../models/GenerateArticleRequest';
import { API_BASE_URL, API_VERSION_URL } from '../../constants';
import { GenerateArticleResponse } from '../../models/GenerateArticleResponse';
import { LinkDto } from '../../models';

const apiVersion2 = 'api/v2';
const baseUrlV2 = API_BASE_URL + apiVersion2;
const API_ARTICLE_GENERATION = `${baseUrlV2}/articles`;
const baseUrlV1 = API_BASE_URL + API_VERSION_URL;
const API_ARTICLE_DELETE = (topicId, articleId) => `${baseUrlV1}/topics/${topicId}/articles/${articleId}`;
const API_GET__TOPIC_ARTICLES = (topicId) => `${baseUrlV1}/topics/${topicId}/articles/`;

@Injectable({
  providedIn: 'root'
})
export class AthesisArticleService {

  constructor(private http: HttpClient, private athesisAuthService: AthesisAuthService) { }

  createArticle(generateArticleRequest: GenerateArticleRequest) {
    return this.http.post<GenerateArticleResponse>(API_ARTICLE_GENERATION, generateArticleRequest, this.athesisAuthService.httpOptions);
  }

  deleteArticle(topicId, articleId) {
    return this.http.delete<GenerateArticleResponse>(API_ARTICLE_DELETE(topicId, articleId), this.athesisAuthService.httpOptions);
  }

  getByTopicId(topicId) {
    return this.http.get<LinkDto[]>(API_GET__TOPIC_ARTICLES(topicId), this.athesisAuthService.httpOptions);
  }
}
