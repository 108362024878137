import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DomainEntity, ContentEntity, TopicEntity, ArticleEntity, FileEntity, Status, RejectReasonsEntity } from 'src/app/models';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { AthesisContentMonitorService } from 'src/app/services/athesis-content-monitor.service';
import { Subscription } from 'rxjs';
import { AthesisTopicService } from 'src/app/services/athesis-topic.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { HttpParams } from '@angular/common/http';
import { Page } from '../../models/pagination/page.model';
import { AthesisFileService } from '../../services/file/athesis-file.service';
import { AthesisContentService } from '../../services/contents/athesis-content.service';
import { TopicStatus } from '../../models/topic/TopicStatus';
import { AuthUser } from '../../users/auth-user.model';
import { AthesisUserService } from '../../services/athesis-user.service';


@Component({
  selector: 'app-athesis-domain-viewer',
  templateUrl: './athesis-domain-viewer.component.html',
  styleUrls: ['./athesis-domain-viewer.component.scss']
})
export class AthesisDomainViewerComponent implements OnDestroy {
  authSub: Subscription = null;
  contentMonitorSub: Subscription = null;
  selectedDomain: DomainEntity = null;
  selectedDomainWritable = false;
  domainId: number = null;
  userId: number = null;
  icons = icons;
  status = Status;
  modalData: {
    action: string,
    selectedTopicId: number,
    title: string,
    body: string,
    buttonMessage: string
  };
  rejectReasons: RejectReasonsEntity[];
  selectedReason = { id: 1, deleted: false, message: 'Non pertinente', code: 'IRRELEVANT', index: 1 };
  topicList: Page<TopicEntity>;
  selectedTopic: TopicEntity;
  currentUser: AuthUser;

  constructor(
    private route: ActivatedRoute,
    private store: Store<{}>,
    private router: Router,
    private userService: AthesisUserService,
    private athesisFileService: AthesisFileService,
    private athesisTopicService: AthesisTopicService,
    private athesisContentService: AthesisContentService,
    private athesisContentMonitorService: AthesisContentMonitorService) {

    this.modalData = {
      action: '',
      selectedTopicId: 0,
      title: '',
      body: '',
      buttonMessage: ''
    };

    this.domainId = Number(this.route.snapshot.params.id);
    this.currentUser = this.userService.currentUser;
    this.rejectReasons = JSON.parse(sessionStorage.getItem('config')).rejectedTopicReasons;

    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.domain) {
      this.setSelectedDomain(state.domain);
    } else {
      this.getUserDomain();
    }
  }

  userHasDomainWritePermissions() {
      return this.currentUser.isAdmin() ||
      !!this.selectedDomain.userDomainEntities.find(userDomainEntity =>
        userDomainEntity.canWrite && userDomainEntity.user.id === this.currentUser.id);
  }

  private setSelectedDomain(response) {
    this.selectedDomain = response;
    this.selectedDomainWritable = this.userHasDomainWritePermissions();

    this.getTopicList();
    this.getFileList();
    this.getArticleList();
  }

  getUserDomain() {
    this.athesisContentMonitorService.getUserDomain(this.domainId).subscribe(response => {
      this.setSelectedDomain(response);
    },
      err => {
        console.error('AthesisContentMonitorService.getUserDomain', err);
        this.router.navigate(['athesis-root', 'notFound']);
      });
  }

  setModalData(topic: TopicEntity, action: any) {
    const body = {
      approve: 'Sei sicuro di voler approvare questo topic?',
      reject: 'Per quale motivo vuoi rifiutare questo topic?',
    };

    const title = {
      approve: 'Approva topic',
      reject: 'Rifiuta topic',
    };

    const button = {
      approve: 'Approva',
      reject: 'Rifiuta',
    };

    this.modalData.action = action;
    this.modalData.selectedTopicId = topic.id;
    this.modalData.body = body[action];
    this.modalData.title = title[action];
    this.modalData.buttonMessage = button[action];
    this.selectedReason = { id: 1, deleted: false, message: 'Non pertinente', code: 'IRRELEVANT', index: 1 };
  }

  approvesOrReject() {
    if (this.modalData.action === 'approve') {
      this.athesisTopicService.approveTopic(this.modalData.selectedTopicId).subscribe(response => {

        this.topicList.content
          .filter(topic => topic.id === this.modalData.selectedTopicId)
          .map(filteredTopic => {
            return filteredTopic.status = TopicStatus.APPROVED;
          });

      });
    } else {
      const selectedReason = {
        reasonOptionId: this.selectedReason.id !== 0 ? this.selectedReason.id : null,
        reasonText: this.selectedReason.message
      };
      this.athesisTopicService.rejectTopic(this.modalData.selectedTopicId, selectedReason).subscribe(response => {
        this.topicList.content
          .filter(topic => topic.id === this.modalData.selectedTopicId)
          .map(filteredTopic => {
            return filteredTopic.status = TopicStatus.REJECTED;
          });
      });
    }
  }

  beginAddLink() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'link-creator']);
  }

  beginAddArticle() {
    this.router.navigate(['athesis-root', 'text-generation'], { state: { domainId: this.domainId } });
  }

  beginAddFile() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'file-creator']);
  }

  beginEditLink(link: ContentEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'link-editor', link.id], { state: { link } });
  }

  beginViewTopic(topic: TopicEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'topic-viewer', topic.id], { state: { topic } });
  }

  beginViewArticle(article: ArticleEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'article-viewer', article.id]);
  }

  beginViewFile(file: FileEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'file-viewer', file.id], { state: { file } });
  }

  beginEditArticle(article: ArticleEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'article-editor', article.id]);
  }

  beginEditFile(file: FileEntity) {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'file-editor', file.id], { state: { file } });
  }

  beginViewUsers() {
    this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId, 'users']);
  }

  modalConfirmLinkDeletion(content: ContentEntity) {
    this.athesisContentMonitorService.deleteLink(content.id).subscribe(
      res => this.onElementDeleteSuccessful(),
      err => this.onElementDeleteUnsuccessful()
    );
  }

  modalConfirmFileDeletion(file: FileEntity) {
    this.athesisContentMonitorService.deleteFile(file.reference).subscribe(
      res => this.onElementDeleteSuccessful(),
      err => this.onElementDeleteUnsuccessful()
    );
  }

  modalConfirmArticleDeletion(article: ArticleEntity) {
    this.athesisContentMonitorService.deleteArticle(article.id).subscribe(
      res => this.onElementDeleteSuccessful(),
      err => this.onElementDeleteUnsuccessful()
    );
  }

  onElementDeleteUnsuccessful(): void {
    console.log('element delete unsuccessful');
  }
  onElementDeleteSuccessful(): void {
    this.getUserDomain();

    // this.athesisContentMonitorHelperService.getAllDomains();
    // this.router.navigate(['athesis-root', 'content-monitor', 'domain', this.domainId]);
  }

  ngOnDestroy() {
    if (this.contentMonitorSub) {
      this.contentMonitorSub.unsubscribe();
    }
  }

  onPageChanged(event: PageChangedEvent) {
    this.getTopicList(event);
  }

  getArticleList() {
    this.athesisContentService.getContentsByDomain(this.selectedDomain.id).subscribe(response => {
      this.selectedDomain.contents = response;
    });
  }

  getFileList() {
    this.athesisFileService.getFilesByDomain(this.selectedDomain.id).subscribe(response => {
      this.selectedDomain.files = response;
    });
  }

  private getTopicList(paginationInfo?) {
    let page = 0;

    if (paginationInfo) {
      page = paginationInfo.page - 1;
    }

    const params = new HttpParams()
      .set('domainId', this.selectedDomain.id.toString())
      .set('page', page.toString())
      .set('sort', 'id,DESC')
      .set('size', '10');

    this.athesisTopicService.getTopics(params).subscribe(response => {
      if (!this.topicList) {
        this.topicList = response;
      }
      this.topicList.content = response.content;
    });
  }

  removeTopic(topicId: number) {
    this.athesisTopicService.deleteTopic(topicId).subscribe(response => {
      console.debug('AthesisDomainViewerComponent.removeTopic', response);
      this.topicList.content = this.topicList.content.filter(topic => topic.id !== topicId);
    });
  }
}
