import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthUser } from '../users/auth-user.model';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {
    private isAuthenticated = false;
    private currentUser: AuthUser;

    constructor(
        public router: Router,
        private store: Store<{ isAuthenticated: boolean }>) {
        this.store.select('auth').subscribe((auth) => {
            this.isAuthenticated = auth ? auth.isAuthenticated : false;
            this.currentUser = Object.assign(new AuthUser(), auth.user);
        });
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean>
         | Promise<boolean> | boolean {
        if (!this.isAuthenticated) {
            this.router.navigate(['login']);
            return false;
        }
        let hasPermission = true;
        if (route.data && route.data.requiredPermissions) {
            hasPermission = route.data.requiredPermissions.every(perm => this.currentUser.hasPermission(perm));
        }
        return hasPermission || this.currentUser.isAdmin();
    }
}
