import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FaceRecCanvasComponent } from './components/face-rec-canvas/face-rec-canvas.component';
import { AthesisModalConfirmComponent } from './components/athesis-modal-confirm/athesis-modal-confirm.component';
import { AthesisModalDeleteComponent } from './components/athesis-modal-delete/athesis-modal-delete.component';
import { AthesisSpinnerComponent } from './components/athesis-spinner/athesis-spinner.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxMasonryModule } from 'ngx-masonry';
import { FullWidthImageModalComponent } from './components/full-width-image-modal/full-width-image-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ImageArticleListComponent } from './components/image-article-list/image-article-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PermissionCheckDirective } from './directives/permission-check.directive';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TagListComponent } from './components/tag-list/tag-list.component';
import { TreeviewModule } from 'ngx-treeview';
import { DateRangeSearchFormComponent } from './components/date-range-search-form/date-range-search-form.component';
import { AthesisImageResultListComponent } from './components/athesis-image-result-list/athesis-image-result-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatexPipe } from './pipes/date.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';


@NgModule({
    imports: [
        CommonModule,
        TabsModule.forRoot(),
        AlertModule.forRoot(),
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        ButtonsModule.forRoot(),
        TooltipModule.forRoot(),
        CarouselModule.forRoot(),
        AccordionModule.forRoot(),
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxMasonryModule,
        TranslateModule,
        FontAwesomeModule,
        TreeviewModule.forRoot(),
        InfiniteScrollModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        AthesisSpinnerComponent,
        AthesisModalConfirmComponent,
        AthesisModalDeleteComponent,
        FaceRecCanvasComponent,
        FullWidthImageModalComponent,
        ConfirmModalComponent,
        ImageArticleListComponent,
        PermissionCheckDirective,
        TagListComponent,
        DateRangeSearchFormComponent,
        AthesisImageResultListComponent,
        DatexPipe,
        TruncateStringPipe
    ],
    exports: [
        CommonModule,
        TabsModule,
        AlertModule,
        ModalModule,
        TypeaheadModule,
        ButtonsModule,
        TooltipModule,
        CarouselModule,
        AccordionModule,
        CollapseModule,
        NgxMasonryModule,
        FontAwesomeModule,
        AthesisSpinnerComponent,
        AthesisModalConfirmComponent,
        AthesisModalDeleteComponent,
        FaceRecCanvasComponent,
        FullWidthImageModalComponent,
        ConfirmModalComponent,
        TranslateModule,
        ImageArticleListComponent,
        PermissionCheckDirective,
        TagListComponent,
        TreeviewModule,
        DateRangeSearchFormComponent,
        AthesisImageResultListComponent,
        InfiniteScrollModule,
        FormsModule,
        ReactiveFormsModule,
        DatexPipe,
        TruncateStringPipe
    ],
    entryComponents: []
})

export class SharedModule { }
