import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { RejectReasonsEntity } from '../../../models';
import { BsModalRef } from 'ngx-bootstrap';
import { NgForm } from '@angular/forms';

export interface RejectTopicRequest {
  reasonOptionId: number;
  reasonText: string;
}

@Component({
  selector: 'app-reject-topic-modal',
  templateUrl: './reject-topic-modal.component.html'
})
export class RejectTopicModalComponent {

  item: any;
  modalConfig: any;
  template: TemplateRef<any>;
  onConfirm: (item?) => void;
  onCancel: () => void;

  rejectReasons: RejectReasonsEntity[];

  @ViewChild('rejectForm', {static: false}) rejectForm: NgForm;
  selectedIndex = 1;

  constructor(private modalRef: BsModalRef) {
    const config = sessionStorage.getItem('config');
    if (config) {
      this.rejectReasons = JSON.parse(config).rejectedTopicReasons;
      this.rejectReasons.push({ id: null, deleted: false, message: 'Altro', code: 'OTHER-REASON', index: 0 });
    }
  }

  cancel() {
    this.onCancel();
    this.modalRef.hide();
  }

  onSubmit() {
    const selectedIndex = this.rejectForm.value.rejectReason;
    this.onConfirm(this.rejectReasons.find(reason => reason.index === selectedIndex));
    this.modalRef.hide();
  }

}
